import React, { Component } from 'react'
import moment from 'moment'
import './alert.css'

class Alert extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            expCss: { width: '0', padding: '0', border: 'none' },
            card: false,
            alerts: 3
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.open && this.state.open) {
            this.setState({
                expCss: { width: '370px', padding: '5px', border: '1px solid #fff' }
            })
            setTimeout(() => {
                this.setState({ card: true })
            }, 500)
        }
        if (prevState.expCss.width !== this.state.expCss.width && this.state.expCss.width === '0') {
            setTimeout(() => {
                this.setState({
                    open: false
                })
            }, 500)
        }
    }

    handleClearAlert(id) {
        this.props.onClearAlert(id)
    }

    // handleNotify(alert) {
    //     let newAlert = JSON.parse(JSON.stringify(alert))
    //     delete newAlert.ts
    //     delete newAlert.patientId
    //     API.post('gwpp', '/alerts', { body: newAlert })
    //     this.props.onClearAlert(alert.id)
    // }
    // <div className="Alerts-Notify" onClick={() => this.handleNotify(alert)}>
    //     <i className="fas fa-paper-plane" style={{ marginRight: '10px' }}></i>Notify PCP
    // </div>

    toggleOpen() {
        if (!this.state.open) {
            this.setState({
                open: true
            })
        } else {
            this.setState({
                expCss: { width: '0', padding: '0', border: 'none' },
                card: false
            })

        }
    }

    getAlerts() {
        return (
            this.state.card && this.props.data && this.props.data[0] ?
                this.props.data.map((alert, aidx) => {
                    console.log('alert    ', alert)
                    return (
                        <div className="Alert-Card" key={`alert-${aidx}`}>
                            <div className="Alert-Card-Title">
                                <i className="fas fa-exclamation-triangle Alert-Card-Item Alerts-Icon-Red"></i>
                                <div className="Alert-Card-Item">{`Alert ${alert.resource.id}`}</div>
                                <i className="far fa-eye-slash Alert-Clear" onClick={() => this.handleClearAlert(alert.resource.id)}></i>
                            </div>
                            {
                                alert.resource && alert.resource.entry && alert.resource.entry[0] ?
                                    alert.resource.entry.map((a, aidxx) => {
                                        return (
                                            <div key={`alert-${aidx}-${aidxx}`} className="Alert-Card-Info">
                                                {a.resource ? this.getFormattedResource(a.resource, aidxx) : null}
                                            </div>
                                        )
                                    }) : null
                            }

                        </div>
                    )
                })
                : null
        )
    }

    getNamefromResource(name) {
        return name && name[0] ? (name[0].text ? name[0].text : `${name[0].given.toString().replace(',', ' ')} ${name[0].family}`) : 'Undefined'
    }

    getFormattedResource(res, aidx) {
        console.log(res)
        switch (res.resourceType) {
            case 'AllergyIntolerance':
                return (
                    <div className="Alert-Card-Section">
                        <div className="Alert-Card-Title">
                            Allergy
                            </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Category</div>
                                <div className="Alert-Card-Value">{res.category ? res.category.toString().replace(/,/g, ', ') : null}</div>
                                <div className="Alert-Card-Label">Criticality</div>
                                <div className="Alert-Card-Value">{res.criticality ? res.criticality : null}</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">Status</div>
                                <div className="Alert-Card-Value">{res.clinicalStatus && res.clinicalStatus.coding && res.clinicalStatus.coding[0] ? res.clinicalStatus.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                                <div className="Alert-Card-Label">Verification</div>
                                <div className="Alert-Card-Value">{res.verificationStatus && res.verificationStatus.coding ? res.verificationStatus.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Allergy</div>
                                <div className="Alert-Card-Value">{res.code && res.code.coding && res.code.coding[0] ? res.code.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Substance</div>
                                <div className="Alert-Card-Value">{res.reaction && res.reaction[0] ? res.reaction.map(r => r.substance && r.substance.coding && r.substance.coding[0] ? r.substance.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Reaction</div>
                                <div className="Alert-Card-Value">{res.reaction && res.reaction[0] ? res.reaction.map(r => r.manifestation && r.manifestation[0] ? r.manifestation.map(m => m.coding && m.coding[0] ? m.coding.map(code => code.display || '').toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Onset Date</div>
                                <div className="Alert-Card-Value">{res.onsetDateTime ? moment(res.onsetDateTime).format('lll') : null}</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">Recorded Date</div>
                                <div className="Alert-Card-Value">{res.recordedDate ? moment(res.recordedDate).format('lll') : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Alert':
                return (
                    <div className="Alert-Card-Info">
                        <div className="Alert-Card-Header">
                            Status
                        </div>
                        <div className="Alert-Card-Body">
                            <div className="Alert-Card-Body-Info">
                                {res.status || null}
                            </div>
                        </div>
                        <div className="Alert-Card-Header">
                            Subject
                        </div>
                        <div className="Alert-Card-Body">
                            <div className="Alert-Card-Body-Info">
                                {res.subject && res.subject.display ? res.subject.display : null}
                            </div>
                        </div>
                        <div className="Alert-Card-Header">
                            Author
                        </div>
                        <div className="Alert-Card-Body">
                            <div className="Alert-Card-Body-Info">
                                {res.author && res.author.display ? res.author.display : null}
                            </div>
                        </div>
                        <div className="Alert-Card-Header">
                            Details
                        </div>
                        <div className="Alert-Card-Body">
                            <div className="Alert-Card-Body-Info">
                                {res.note && res.note.text ? res.note.text : (res.note.coding && res.note.coding[0] ? res.note.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null)}
                            </div>
                        </div>
                    </div>
                )
            case 'Condition':
                return (
                    <div className="Alert-Card-Section">
                        <div className="Alert-Card-Title">
                            Condition/Problem
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Category</div>
                                <div className="Alert-Card-Value">{res.category && res.category[0] ? res.category.map(t => t.coding && t.coding[0] ? t.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                                <div className="Alert-Card-Label">Severity</div>
                                <div className="Alert-Card-Value">{res.severity && res.severity.coding && res.severity.coding[0] ? res.severity.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">Verification</div>
                                <div className="Alert-Card-Value">{res.verificationStatus && res.verificationStatus.coding ? res.verificationStatus.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Code</div>
                                <div className="Alert-Card-Value">{res.code && res.code.coding && res.code.coding[0] ? res.code.coding.map(code => code.display || '').toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Onset Date</div>
                                <div className="Alert-Card-Value">{res.onsetDateTime ? moment(res.onsetDateTime).format('ll') : null}</div>
                            </div>
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Abatement</div>
                                <div className="Alert-Card-Value">{res.abatementString ? res.abatementString : null}</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">Recorded Date</div>
                                <div className="Alert-Card-Value">{res.recordedDate ? moment(res.recordedDate).format('ll') : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Encounter':
                return (
                    <div className="Alert-Card-Section">
                        <div className="Alert-Card-Title">
                            Encounter
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Class</div>
                                <div className="Alert-Card-Value">{res.class && res.class.display ? res.class.display : (res.class && res.class.code ? res.class.code : null)}</div>
                                <div className="Alert-Card-Label">Priority</div>
                                <div className="Alert-Card-Value">{res.priority && res.priority.coding ? res.priority.coding.map(code => code.display ? code.display : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">Status</div>
                                <div className="Alert-Card-Value">{res.status ? res.status : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Type</div>
                                <div className="Alert-Card-Value">{res.type && res.type[0] ? res.type.map(t => t.coding && t.coding[0] ? t.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Start</div>
                                <div className="Alert-Card-Value">{res.period && res.period.start ? moment(res.period.start).format('ll') : null}</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">End</div>
                                <div className="Alert-Card-Value">{res.period && res.period.end ? moment(res.period.end).format('ll') : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Reason</div>
                                <div className="Alert-Card-Value">{res.reasonCode && res.reasonCode[0] ? res.reasonCode.map(r => r.coding && r.coding[0] ? r.coding.map(code => code.display || code.text || code.code || code.text || []).toString().replace(/,/g, ', ') : (r.text ? r.text : [])).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Participant</div>
                                <div className="Alert-Card-Value">{res.participant && res.participant[0] ? res.participant.map(p => Object.keys(p).map(key => `${p[key].display} (${key})`)).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Provider</div>
                                <div className="Alert-Card-Value">{res.serviceProvider && (res.serviceProvider.display || res.serviceProvider.text) ? res.serviceProvider.display || res.serviceProvider.text : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Immunization':
                return (
                    <div className="Alert-Card-Section">
                        <div className="Alert-Card-Title">
                            Immunizations
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Vaccine</div>
                                <div className="Alert-Card-Value">{res.vaccineCode ? (res.vaccineCode.text ? res.vaccineCode.text : (res.vaccineCode.coding && res.vaccineCode.coding[0] ? res.vaccineCode.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null)) : null}</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">Status</div>
                                <div className="Alert-Card-Value">{res.status ? res.status : (res.active ? 'Active' : null)}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Primary Source</div>
                                <div className="Alert-Card-Value">{res.primarySource ? res.primarySource : null}</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">Lot</div>
                                <div className="Alert-Card-Value">{res.lotNumber ? res.lotNumber : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Reason</div>
                                <div className="Alert-Card-Value">{res.reasonCode && res.reasonCode[0] ? res.reasonCode.map(rc => rc.coding && rc.coding[0] ? rc.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">Dose</div>
                                <div className="Alert-Card-Value">{res.doseQuantity ? `${res.doseQuantity.value ? res.doseQuantity.value : null}${res.doseQuantity.value && res.doseQuantity.code ? ' ' + res.doseQuantity.code : null}` : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Site</div>
                                <div className="Alert-Card-Value">{res.site && res.site.coding && res.site.coding[0] ? res.site.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Route</div>
                                <div className="Alert-Card-Value">{res.route && res.route.coding && res.route.coding[0] ? res.route.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Occurence Date</div>
                                <div className="Alert-Card-Value">{res.occurrenceDataTime ? moment(res.occurrenceDataTime).format('ll') : null}</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">Expiration Date</div>
                                <div className="Alert-Card-Value">{res.expirationDate ? moment(res.expirationDate).format('ll') : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'MessageHeader':
                return (
                    <div className="Alert-Card-Section">
                        <div className="Alert-Card-Title">
                            {
                                res.eventCoding ? res.eventCoding.display || res.eventCoding.text || res.eventCoding.code || null : null
                            }
                        </div>
                        <div>
                            {
                                res.sender && res.sender.display &&
                                <div className="Alert-Card-Row">
                                    <div className="Alert-Card-Item-Left">
                                        <div className="Alert-Card-Label">Sender</div>
                                        <div className="Alert-Card-Value">{res.sender.display}</div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div>
                            {
                                res.author && res.author.display &&
                                <div className="Alert-Card-Row">
                                    <div className="Alert-Card-Item-Left">
                                        <div className="Alert-Card-Label">Author</div>
                                        <div className="Alert-Card-Value">{res.author.display}</div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div>
                            {
                                res.responsible && res.responsible.display &&
                                <div className="Alert-Card-Row">
                                    <div className="Alert-Card-Item-Left">
                                        <div className="Alert-Card-Label">Recipient</div>
                                        <div className="Alert-Card-Value">{res.responsible.display}</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                )
            case 'MedicationStatement':
                return (
                    <div className="Alert-Card-Section">
                        <div className="Alert-Card-Title">
                            Medication
                            </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Medication</div>
                                <div className="Alert-Card-Value">{res.medicationCodeableConcept && res.medicationCodeableConcept.coding && res.medicationCodeableConcept.coding[0] ? res.medicationCodeableConcept.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">Status</div>
                                <div className="Alert-Card-Value">{res.status ? res.status : null}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Start</div>
                                <div className="Alert-Card-Value">{res.effectivePeriod && res.effectivePeriod.start ? moment(res.effectivePeriod.start).format('ll') : (res.effectiveDateTime ? moment(res.effectiveDateTime).format('ll') : null)}</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">End</div>
                                <div className="Alert-Card-Value">{res.effectivePeriod && res.effectivePeriod.end ? moment(res.effectivePeriod.end).format('ll') : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Observation':
                return (
                    <div className="Alert-Card-Section">
                        <div className="Alert-Card-Title">
                            Observations/Vitals
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">Status</div>
                                <div className="Alert-Card-Value">{res.status ? res.status : (res.active ? 'Active' : null)}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Measurement Type</div>
                                <div className="Alert-Card-Value">{res.code && res.code.text ? res.code.text : (res.code && res.code.coding && res.code.coding[0] ? res.code.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : '')}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Measurement</div>
                            </div>
                            <div className="Alert-Card-Item-Right">
                                <div className="Alert-Card-Label">Interpretation</div>
                            </div>
                            {
                                res.component && res.component[0] ?
                                    res.component.map((comp, compidx) => {
                                        return (
                                            <div key={`measComp-${compidx}`} className="Alert-Card-Row">
                                                <div className="Alert-Card-Item-Left">
                                                    <div className="Alert-Card-Value">
                                                        {`${this.getValueQuantity(comp.valueQuantity || null)} ${this.getMeasType(comp.code || null)}`}
                                                    </div>
                                                </div>
                                                <div className="Alert-Card-Item-Right">
                                                    <div className="Alert-Card-Value">
                                                        {this.getInterpretation(comp.interpretation || null)}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : ''
                            }
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Period</div>
                                <div className="ResAlertource-Card-Value">
                                    {
                                        res.effectivePeriod && Object.keys(res.effectivePeriod) ? Object.keys(res.effectivePeriod).map((ep, epidx) => {
                                            return (
                                                <div key={`ep-${aidx}-${epidx}`}>{`${ep}: ${moment(res.effectivePeriod[ep])}`}</div>
                                            )
                                        }) : (res.effectiveDateTime ? moment(res.effectiveDateTime).format('ll') : '')
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case 'Patient':
                return (
                    <div className="Alert-Card-Section">
                        <div className="Alert-Card-Title">
                            Patient
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Name</div>
                                <div className="Alert-Card-Value">{this.getNamefromResource(res.name)}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Status</div>
                                <div className="Alert-Card-Value"><i className={`fas fa-circle ${res.active ? 'Member-Active' : 'Member-Inactive'}`}></i> {res.active ? 'ACTIVE' : 'INACTIVE'}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label"><i className="fas fa-female"></i><i className="fas fa-male"></i> Gender</div>
                                <div className="Alert-Card-Value">{res.gender ? res.gender.toUpperCase() : 'Undefined'}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label">Contact</div>
                                <div className="Alert-Card-Value">
                                    {
                                        res.telecom && res.telecom.length > 0 && res.telecom.map((t, tid) => {
                                            return (
                                                <div key={`p-${aidx}-t-${tid}`} className="Alert-Resource-Section">
                                                    <div className="Alert-Resource-Section-Label">{t.use && t.use === 'mobile' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {`${t.use ? t.use.toUpperCase() : 'TELECOM'}/${t.system ? t.system.toUpperCase() : 'PHONE'}`}</div>
                                                    <div className="Alert-Resource-Section-Value">{t.value ? t.value : 'Not Listed'}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label"><i className="fas fa-birthday-cake"></i> D.O.B.</div>
                                <div className="Alert-Card-Value">{res.birthDate || 'Undefined'}</div>
                            </div>
                        </div>
                        <div className="Alert-Card-Row">
                            <div className="Alert-Card-Item-Left">
                                <div className="Alert-Card-Label"><i className="fas fa-map-marker-alt"></i> Address</div>
                                <div className="Alert-Card-Value">
                                    {
                                        res.address && res.address[0] ?
                                            (
                                                res.address[0].text ?
                                                    <div className="Alert-Resource-Section-Value">{res.address[0].text}</div> :
                                                    <div className="Alert-Resource-Section-Value">{`${res.address[0].line ? res.address[0].line.toString().replace(/,/g, ', ') + ', ' : ''}${res.address[0].city ? res.address[0].city + ', ' : ''}${res.address[0].state ? res.address[0].state + ', ' : ''}${res.address[0].postalCode ? res.address[0].postalCode + ', ' : ''}${res.address[0].country ? res.address[0].country + ', ' : ''}`}</div>
                                            ) : <div className="Alert-Resource-Section-Value">Unlisted</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            default:
                return <div className="Alert-Card-Row"><pre>{JSON.stringify(res, undefined, 2)}</pre></div>
        }
    }

    getValueQuantity(val) {
        return val ? `${val && val.value ? parseInt(val.value) : ''} ${val && val.unit ? val.unit : ''}` : ''
    }

    getMeasType(code) {
        return code && code.coding && code.coding[0] ? code.coding[0].display || code.coding[0].code || '' : ''
    }

    getInterpretation(int) {
        return int && int[0] && int[0].text ? int[0].text : (int && int[0] && int[0].coding && int[0].coding[0] ? int[0].coding.map(code => code.display || code.code || []).toString().replace(/,/g, ', ') : '')
    }

    render() {
        console.log(this.state, this.props)
        return (
            <div>
                <div className="Alert-Container">
                    <div className="Alert-Main" onClick={() => this.toggleOpen()}><i className="fas fa-exclamation-triangle"></i></div>
                    <div className={`Alert-Open`} style={this.state.expCss}>
                        {this.state.card ?
                            <div className='Alert-Header'>
                                View {this.props.data.length} Alerts <i className="far fa-bell" style={{ marginLeft: '5px' }}></i>
                            </div> : null}
                    </div>
                </div>
                {this.state.card ? <div className="Alert-Cards" style={{ height: 'calc(100vh - 185px)', overflowY: 'auto' }}>{this.getAlerts()}</div> : null}
            </div>
        )
    }
}

export default Alert