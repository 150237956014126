import React, { Component } from 'react'
import { API } from 'aws-amplify'
import { Line, Bar } from "react-chartjs-2"
import moment from 'moment'
import Weight from '../../images/weight.svg'
import Running from '../../images/running.svg'
import Heartbeat from '../../images/heartbeat.svg'
import './care.css'
import '../mrp.css'
import './me.css'

const bpCalc = 0.00750062
const wtCalc = 0.00220462

class Care extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isResetting: false,
            isLoadingFHIR: false,
            displaySelections: false,
            searchSelection: null,
            search: null,
            mbr: null,
            file: null,
            fileDisplay: null,
            fileIdx: null,
            tid: null,
            iid: null,
            bm: false,
            edi: false,
            members: [],
            member: null,
            mbrId: null,
            mrn: null,
            bps: null,
            ws: null,
            view: 'search',
            menus: [
                'FHIR',
                'EDI',
                'Biometrics'
            ],
            headers: {
                Encounter: { open: false, res: [], title: 'Encounters' },
                AllergyIntolerance: { open: false, res: [], title: 'Allergies' },
                Condition: { open: false, res: [], title: 'Conditions/Problems' },
                MedicationStatement: { open: false, res: [], title: 'Medications' },
                Immunization: { open: false, res: [], title: 'Immunizations' },
                Observation: { open: false, res: [], title: 'Observations/Vitals' },
                Procedure: { open: false, res: [], title: 'Procedures' },
                ExplanationOfBenefit: { open: false, res: [], title: 'Explanation of Benefits' },
                Coverage: { open: false, res: [], title: 'Coverage' },
                Claim: { open: false, res: [], title: 'Claims' },
                ClaimResponse: { open: false, res: [], title: 'Claim Responses' },
                CommunicationRequest: { open: false, res: [], title: 'Communication Requests' },
                Communication: { open: false, res: [], title: 'Communications' },
                QuestionnaireResponse: { open: false, res: [], title: 'Questionnaire Responses' },
                CarePlan: { open: false, res: [], title: 'Care Plan' },
                MeasureReport: { open: false, res: [], title: 'Measure Reports' }
            },
            measures: { open: false, res: [], title: 'Measures' },
            orgsOpen: false,
            ors: [],
            org: null,
            srcOpen: false,
            srcs: [{ src: 'CDEX', type: 'prov' }, { src: 'PCDE', type: 'ins' }],
            sent: false
        }
    }

    async componentDidMount() {
        try {
            const orgs = await API.get('gwhpp', `/resource?res=organizations&touchstone=${this.props.touchstone}`)
            const filteredOrgs = orgs.data.filter(o => o.organization.toLowerCase() !== 'guidewell')
            let finalOrgs = []
            filteredOrgs.forEach(o => {
                let tmp = finalOrgs.filter(fo => fo.organization && fo.url && fo.organization.toLowerCase() === o.organization.toLowerCase() && fo.url === o.url)
                if (tmp.length === 0) finalOrgs.push(o)
            })
            if (orgs.data && orgs.data[0]) {
                this.setState({ orgs: finalOrgs })
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.iid)
        clearTimeout(this.state.tid)
    }

    handleUpdateResources(action) {
        if (this.state.mbrId && action) {
            this.setState({ isResetting: true })
            API.get('gwhpp', `/resource?res=lara&id=${this.state.mbrId}&action=${action}&touchstone=${this.props.touchstone}`)
                .then(resp => {
                    this.setState({ isResetting: false })
                })
                .catch(err => {
                    this.setState({ isResetting: false })
                    alert(err.message)
                })
        }
    }

    handleSelector(sel) {
        this.setState({ displaySelections: sel ? sel : !this.state.displaySelections, file: null, fileDisplay: null })
    }

    handleSelection(sel) {
        clearTimeout(this.state.tid)
        this.setState({
            displaySelections: false,
            searchSelection: sel,
            tid: null,
            members: []
        })
        if (this.state.search && this.state.search.length > 0) this.searchPatients(this.state.search, sel)
    }

    async handleSearchTextChange(text) {
        const search = text.toUpperCase()
        clearTimeout(this.state.tid)
        this.setState({
            tid: null,
            displaySelections: false,
            mbr: null,
            file: null,
            fileDisplay: null,
            search: search.length > 0 ? search : null,
            members: [],
            bundle: null
        })

        if (search.length > 0) {
            let tid = setTimeout(() => {
                this.searchPatients(search)
            }, 1000)
            this.setState({ tid })
        }
    }

    handleGetBioMetrics() {
        clearInterval(this.state.iid)
        this.setState({ iid: null, edi: false })
        try {
            let d = new Date()
            API.get('ecw', `/weight/868998031543974?start=${moment().subtract(100, 'days').format('x')}&stop=${Date.parse(d)}`)
                .then(resp => {
                    let ws = Object.keys(resp).map(k => { return { ts: k, values: resp[k].values } })
                    this.setState({ ws })
                })
            API.get('ecw', `/weight/868998034139143?start=${moment().subtract(100, 'days').format('x')}&stop=${Date.parse(d)}`)
                .then(resp => {
                    let bps = Object.keys(resp).map(k => { return { ts: k, values: resp[k].values } })
                    this.setState({ bps })
                })
            this.setState({ bm: true })
        } catch (e) {
            console.log(e.message)
        }
    }

    async handleMemberSelect(mbrId) {
        const member = this.state.members.filter(m => m.id === mbrId)
        if (member.length === 1) {
            let mrn = member[0].identifier && member[0].identifier[0] && member[0].identifier.filter(i => i.type && i.type.coding && i.type.coding[0] && i.type.coding.filter(c => c.code === 'MR').length === 1)
            this.getMRP(mbrId)
            Object.keys(this.state.headers).forEach(key => this.getFhirResource(key, mbrId))
            this.setState({ member: member[0], mbrId, view: 'FHIR', mrn: mrn && mrn[0] && mrn[0].value ? mrn.value : null })
            this.props.setMbrID(mbrId)
            this.getFhirData(mbrId)
        }
    }

    handleReturnToSearch() {
        clearInterval(this.state.iid)
        let headers = JSON.parse(JSON.stringify(this.state.headers))
        Object.keys(headers).forEach(key => {
            headers[key].open = false
            headers[key].res = []
        })
        this.setState({ view: 'search', mbrId: null, member: null, headers, iid: null, mrn: null })
    }

    handleMeasuresSelect() {
        let headers = JSON.parse(JSON.stringify(this.state.headers))
        Object.keys(headers).forEach(key => {
            headers[key].open = false
        })
        this.setState({ headers, measures: { ...this.state.measures, open: !this.state.measures.open } })
    }

    handleHeaderSelect(header) {
        let headers = JSON.parse(JSON.stringify(this.state.headers))
        Object.keys(headers).forEach(key => {
            if (key === header) {
                headers[key].open = !headers[key].open
            } else {
                headers[key].open = false
            }
        })
        this.setState({ headers })
    }

    handleOrgDisplay() {
        this.setState({ orgsOpen: !this.state.orgsOpen })
    }

    handleSrcDisplay() {
        this.setState({ srcOpen: !this.state.srcOpen })
    }

    handleOrgSelect(org) {
        this.setState({ org, orgsOpen: false, src: this.state.src && this.state.src.type === org.type ? this.state.src : null })
    }

    handleSrcSelect(src) {
        this.setState({ src, srcOpen: false, org: this.state.org && this.state.org.type === src.type ? this.state.org : null })
    }

    handleClearSrcDisplay() {
        this.setState({ src: null, org: null, srcOpen: false, orgOpen: false })
    }

    async handleSendToPayer() {
        if (this.state.org && this.state.src) {
            try {
                this.setState({ sent: false })
                const resp = await API.get('gwhpp', `/resource?res=communication&id=123456&senderOrg=${this.state.org.organization}&action=${this.state.src.src}&touchstone=${this.props.touchstone}`)
                console.log(resp.data || resp)
            } catch (e) {
                alert(e.message)
            }
        }
    }

    handleMenuSelect(view) {
        this.setState({ view })
    }

    handleDeleteResource(res, id) {
        try {
            API.get('gwhpp', `/resource?res=fhirResource&resName=${res}&id=${id}&action=delete`)
            if (res !== 'Parameters') {
                let idx = this.state.headers[res].res.findIndex(r => r.resource && r.resource.id === id)
                let newRes = { ...this.state.headers[res].res[idx], resource: { ...this.state.headers[res].res[idx].resource, deleted: true } }
                let resources = JSON.parse(JSON.stringify(this.state.headers[res].res))
                resources[idx] = newRes
                let header = { ...this.state.headers[res], res: resources }
                this.setState({ headers: { ...this.state.headers, [res]: header } })
            }
        } catch (e) {
            alert(e.message)
        }
    }

    async searchPatients(search, sel) {
        let selection = sel || this.state.searchSelection
        let searchArray = []
        let names = []
        let dob = null
        let id = null
        let members = []
        this.setState({ isLoading: true })
        searchArray = search.replace(/,/g, ' ').split(' ').map(s => s.length > 0 && s.trim())
        searchArray.forEach(sa => {
            if (sa) {
                if (!isNaN(sa) && !id) {
                    id = sa
                } else if (moment(sa).isValid() && (sa.indexOf('/') || sa.indexOf('-')) && !dob) {
                    dob = moment(sa).format('YYYY-MM-DD')
                } else {
                    names.push(sa)
                }
            }
        })
        if (selection === 'Member ID') {
            console.log('Member ID')
            if (id) {
                const ids = await API.get('gwhpp', `/resource?res=fhirpatientsearch&id=${id}&touchstone=${this.props.touchstone}`)
                const identifiers = await API.get('gwhpp', `/resource?res=fhirpatientsearch&identifier=${id}&touchstone=${this.props.touchstone}`)
                if (ids.data && ids.data.entry) ids.data.entry.forEach(id => {
                    if (id.resource && id.resource.resourceType && id.resource.resourceType === 'Patient') {
                        members.push(id.resource)
                        this.setState({ members, isLoading: false })
                    }
                })
                if (identifiers.data && identifiers.data.entry) identifiers.data.entry.forEach(id => {
                    if (id.resource && id.resource.resourceType && id.resource.resourceType === 'Patient') {
                        let found = false
                        members.forEach(mbr => { if (mbr.id === id.resource.id) found = true })
                        if (!found) {
                            members.push(id.resource)
                            this.setState({ members, isLoading: false })
                        }
                    }
                })
            }
        } else if (names.length > 0) {
            if (selection === 'Member First Name + Optional DOB') {
                console.log('Member First Name + Optional DOB')
                let name = names.toString().replace(',', ' ')
                const mbrs = await API.get('gwhpp', `/resource?res=fhirpatientsearch&given=${name}${dob ? '&dob=' + dob : ''}&touchstone=${this.props.touchstone}`)
                if (mbrs.data && mbrs.data.entry) mbrs.data.entry.forEach(mbr => {
                    if (mbr.resource && mbr.resource.resourceType && mbr.resource.resourceType === 'Patient') {
                        members.push(mbr.resource)
                        this.setState({ members, isLoading: false })
                    }
                })
            } else if (selection === 'Member Last Name + Optional DOB') {
                console.log('Member Last Name + Optional DOB')
                let name = names.toString().replace(',', ' ')
                const mbrs = await API.get('gwhpp', `/resource?res=fhirpatientsearch&family=${name}${dob ? '&dob=' + dob : ''}&touchstone=${this.props.touchstone}`)
                if (mbrs.data && mbrs.data.entry) mbrs.data.entry.forEach(mbr => {
                    if (mbr.resource && mbr.resource.resourceType && mbr.resource.resourceType === 'Patient') {
                        members.push(mbr.resource)
                        this.setState({ members, isLoading: false })
                    }
                })
            } else {
                names.forEach(async name => {
                    if (id) {
                        console.log('Member (Name/ID/DOB)')
                        const ids = await API.get('gwhpp', `/resource?res=fhirpatientsearch&name=${name}${dob ? '&dob=' + dob : ''}${id ? '&id=' + id : ''}&touchstone=${this.props.touchstone}`)
                        const identifiers = await API.get('gwhpp', `/resource?res=fhirpatientsearch&name=${name}${dob ? '&dob=' + dob : ''}${id ? '&identifier=' + id : ''}&touchstone=${this.props.touchstone}`)
                        if (ids.data && ids.data.entry) ids.data.entry.forEach(id => {
                            if (id.resource && id.resource.resourceType && id.resource.resourceType === 'Patient') {
                                let found = false
                                members.forEach(mbr => { if (mbr.id === id.resource.id) found = true })
                                if (!found) {
                                    members.push(id.resource)
                                    this.setState({ members, isLoading: false })
                                }
                            }
                        })
                        if (identifiers.data && identifiers.data.entry) identifiers.data.entry.forEach(id => {
                            if (id.resource && id.resource.resourceType && id.resource.resourceType === 'Patient') {
                                let found = false
                                members.forEach(mbr => { if (mbr.id === id.resource.id) found = true })
                                if (!found) {
                                    members.push(id.resource)
                                    this.setState({ members, isLoading: false })
                                }
                            }
                        })
                    } else {
                        console.log('Member (Name/DOB)')
                        const mbrs = await API.get('gwhpp', `/resource?res=fhirpatientsearch&name=${name}${dob ? '&dob=' + dob : ''}&touchstone=${this.props.touchstone}`)
                        if (mbrs.data && mbrs.data.entry) mbrs.data.entry.forEach(mbr => {
                            if (mbr.resource && mbr.resource.resourceType && mbr.resource.resourceType === 'Patient') {
                                members.push(mbr.resource)
                                this.setState({ members, isLoading: false })
                            }
                        })
                    }
                })
            }
        } else {
            if (id) {
                console.log('Member (ID/DOB)')
                const ids = await API.get('gwhpp', `/resource?res=fhirpatientsearch${dob ? '&dob=' + dob : ''}${id ? '&id=' + id : ''}&touchstone=${this.props.touchstone}`)
                const identifiers = await API.get('gwhpp', `/resource?res=fhirpatientsearch${dob ? '&dob=' + dob : ''}${id ? '&identifier=' + id : ''}&touchstone=${this.props.touchstone}`)
                if (ids.data && ids.data.entry) ids.data.entry.forEach(id => {
                    if (id.resource && id.resource.resourceType && id.resource.resourceType === 'Patient') {
                        members.push(id.resource)
                        this.setState({ members, isLoading: false })
                    }
                })
                if (identifiers.data && identifiers.data.entry) identifiers.data.entry.forEach(id => {
                    if (id.resource && id.resource.resourceType && id.resource.resourceType === 'Patient') {
                        let found = false
                        members.forEach(mbr => { if (mbr.id === id.resource.id) found = true })
                        if (!found) {
                            members.push(id.resource)
                            this.setState({ members, isLoading: false })
                        }
                    }
                })
            } else {
                console.log('Member DOB')
                const mbrs = await API.get('gwhpp', `/resource?res=fhirpatientsearch${dob ? '&dob=' + dob : ''}&touchstone=${this.props.touchstone}`)
                if (mbrs.data && mbrs.data.entry) mbrs.data.entry.forEach(mbr => {
                    if (mbr.resource && mbr.resource.resourceType && mbr.resource.resourceType === 'Patient') {
                        members.push(mbr.resource)
                        this.setState({ members, isLoading: false })
                    }
                })
            }
        }
    }

    getEDI() {
        clearInterval(this.state.iid)
        this.setState({ iid: null, bm: false, edi: true })
    }

    getMRP(mbrId) {
        API.get('gwhpp', `/resource?res=mrp&id=${mbrId}&touchstone=${this.props.touchstone}`)
            .then(resp => {
                if (resp.data && resp.data.entry && resp.data.entry[0]) {
                    this.setState({ measures: { ...this.state.measures, res: resp.data.entry } })
                }
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getFhirData(mbrId) {
        this.setState({ bmi: false, edi: false })
        let iid = setInterval(async () => {
            Object.keys(this.state.headers).forEach(key => this.getFhirResource(key, mbrId))
            this.getMRP(mbrId)
        }, 5000)
        this.setState({ iid })
    }

    async getFhirResource(res, mbrId) {
        try {
            const resp = await API.get('gwhpp', `/resource?res=fhirResource&resName=${res}&id=${mbrId}&touchstone=${this.props.touchstone}`)
            let header = { ...this.state.headers[res], res: resp.data && resp.data.entry ? resp.data.entry : [] }
            this.setState({ headers: { ...this.state.headers, [res]: header } })
        } catch (e) {
            console.log(e.message)
        }
    }

    getNamefromResource(name) {
        return name && name[0] ? (name[0].text ? name[0].text : `${name[0].given.toString().replace(',', ' ')} ${name[0].family}`) : 'Undefined'
    }

    getPatientCard(res, pidx) {
        return (
            <div className="">
                <div className="FHIR-Resource-Section-Title FHIR-Resource-Section-Inline">
                    <i className="far fa-address-card"></i> Member
                    <div className="FHIR-Resource-Section-Value FHIR-Resource-Section-Float-Right">{res.id || 'Undefined'}</div>
                    <div className="FHIR-Resource-Section-Label FHIR-Resource-Section-Float-Right">ID</div>
                </div>
                <div className="FHIR-Resource-Section-Flex">
                    <div className="FHIR-Resource-Section">
                        <div className="FHIR-Resource-Initial-Container">
                            <div className="FHIR-Resource-Initial">{this.getNamefromResource(res.name).split(' ').map(n => n[0]).join("")}</div>
                        </div>
                    </div>
                    <div className="FHIR-Resource-Section">
                        <div className="FHIR-Resource-Section-Label">Name</div>
                        <div className="FHIR-Resource-Section-Value">{this.getNamefromResource(res.name)}</div>
                    </div>
                    <div className="FHIR-Resource-Section">
                        <div className="FHIR-Resource-Section-Label">Status</div>
                        <div className="FHIR-Resource-Section-Value"><i className={`fas fa-circle ${res.active ? 'Member-Active' : 'Member-Inactive'}`}></i> {res.active ? 'ACTIVE' : 'INACTIVE'}</div>
                    </div>
                    <div className="FHIR-Resource-Section">
                        <div className="FHIR-Resource-Section-Label"><i className="fas fa-female"></i><i className="fas fa-male"></i> Gender</div>
                        <div className="FHIR-Resource-Section-Value">{res.gender ? res.gender.toUpperCase() : 'Undefined'}</div>
                    </div>
                </div>
                <div className="FHIR-Resource-Section-Flex">
                    {
                        res.telecom && res.telecom.length > 0 && res.telecom.map((t, tid) => {
                            return (
                                <div key={`p-${pidx}-t-${tid}`} className="FHIR-Resource-Section">
                                    <div className="FHIR-Resource-Section-Label">{t.use && t.use === 'mobile' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {`${t.use ? t.use.toUpperCase() : 'TELECOM'}/${t.system ? t.system.toUpperCase() : 'PHONE'}`}</div>
                                    <div className="FHIR-Resource-Section-Value">{t.value ? t.value : 'Not Listed'}</div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="FHIR-Resource-Section-Flex">
                    <div className="FHIR-Resource-Section">
                        <div className="FHIR-Resource-Section-Label"><i className="fas fa-birthday-cake"></i> D.O.B.</div>
                        <div className="FHIR-Resource-Section-Value">{res.birthDate || 'Undefined'}</div>
                    </div>
                    <div className="FHIR-Resource-Section">
                        <div className="FHIR-Resource-Section-Label">{res.deceasedBoolean ? <i className="fas fa-user-alt-slash"></i> : <i className="fas fa-user-alt"></i>} Deceased</div>
                        <div className="FHIR-Resource-Section-Value">{res.deceasedBoolean ? 'Yes' : 'No'}</div>
                    </div>
                </div>
                <div className="FHIR-Resource-Section-Flex">
                    <div className="FHIR-Resource-Section">
                        <div className="FHIR-Resource-Section-Label"><i className="fas fa-map-marker-alt"></i> Address</div>
                        {
                            res.address && res.address[0] ?
                                (
                                    res.address[0].text ?
                                        <div className="FHIR-Resource-Section-Value">{res.address[0].text}</div> :
                                        <div className="FHIR-Resource-Section-Value">{`${res.address[0].line ? res.address[0].line.toString().replace(/,/g, ', ') + ', ' : ''}${res.address[0].city ? res.address[0].city + ', ' : ''}${res.address[0].state ? res.address[0].state + ', ' : ''}${res.address[0].postalCode ? res.address[0].postalCode + ', ' : ''}${res.address[0].country ? res.address[0].country + ', ' : ''}`}</div>
                                ) : <div className="FHIR-Resource-Section-Value">Unlisted</div>
                        }
                    </div>
                </div>
                <div className="FHIR-Resource-Section-Flex">
                    <div className="FHIR-Resource-Section">
                        <div className="FHIR-Resource-Section-Label"><i className="fas fa-user-md"></i> Practitioner</div>
                        <div className="FHIR-Resource-Section-Value">{res.generalPractitioner && res.generalPractitioner[0] && res.generalPractitioner[0].display ? res.generalPractitioner[0].display : 'Not Listed'}</div>                    </div>
                </div>
            </div>

        )
    }

    getPatientHeader() {
        const pat = this.state.member ? this.state.member : null
        return (
            pat ?
                <div className="Member-Header-Container">
                    <div className="Member-Header-Column-Group">
                        <div className="Member-Header-Column">
                            <div className="FHIR-Resource-Initial-Container">
                                <div className="FHIR-Resource-Initial">{this.getNamefromResource(pat.name).split(' ').map(n => n[0]).join("")}</div>
                            </div>
                        </div>
                        <div className="Member-Header-Column">
                            <div className="Member-Resource-Section-Value">{this.getNamefromResource(pat.name)}</div>
                            <div className="Member-Resource-Section-Value"><i className="far fa-id-card"></i> {pat.id || 'Undefined'}</div>
                            <div className={`Member-Resource-Section-Value ${pat.active ? 'Member-Active' : 'Member-Inactive'}`}><i className="fas fa-circle"></i>{pat.active ? 'ACTIVE' : 'INACTIVE'}</div>
                        </div>
                    </div>
                    <div className="Member-Header-Column">
                        {
                            pat.telecom && pat.telecom.length > 0 && pat.telecom.map((t, tid) => {
                                return (
                                    <div key={`t-${tid}`} className="FHIR-Resource-Section">
                                        <div className="Member-Resource-Section-Value">{t.use && t.use === 'mobile' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {t.value ? t.value : 'Not Listed'}</div>
                                    </div>
                                )
                            })
                        }
                        {
                            pat.address && pat.address[0] ?
                                (
                                    pat.address[0].text ?
                                        <div className="Member-Resource-Section-Value"><i className="fas fa-map-marker-alt"></i> {pat.address[0].text}</div> :
                                        <div className="Member-Resource-Section-Value"><i className="fas fa-map-marker-alt"></i> {`${pat.address[0].line ? pat.address[0].line.toString().replace(/,/g, ', ') + ', ' : ''}${pat.address[0].city ? pat.address[0].city + ', ' : ''}${pat.address[0].state ? pat.address[0].state + ', ' : ''}${pat.address[0].postalCode ? pat.address[0].postalCode + ', ' : ''}${pat.address[0].country ? pat.address[0].country + ', ' : ''}`}</div>
                                ) : <div className="Member-Resource-Section-Value">Unlisted</div>
                        }
                    </div>
                    <div className="Member-Header-Column">
                        <div className="Member-Resource-Section-Value"><i className="fas fa-birthday-cake"></i> {pat.birthDate || 'Undefined'}</div>
                        <div className="Member-Resource-Section-Value"><i className="fas fa-female"></i><i className="fas fa-male"></i> {pat.gender ? pat.gender.toUpperCase() : 'Undefined'}</div>
                    </div>
                    <div className="Member-Header-Column">
                        <div onClick={() => this.handleReturnToSearch()}>
                            <div className="Member-Return-Button"><i className="fas fa-search"></i> Return to Search</div>
                        </div>
                        <div className="Reset-Container">
                            <div className="Reset-Button-Container" onClick={() => this.handleUpdateResources('clear')}>
                                <i className="fas fa-eraser"></i>
                            </div>
                            <div className="Reset-Button-Container Reset-Button-Container-Spin">
                                {this.state.isResetting && <i className="fas fa-spinner"></i>}
                            </div>
                            <div className="Reset-Button-Container" onClick={() => this.handleUpdateResources('load')}>
                                <i className="fas fa-cloud-upload-alt"></i>
                            </div>
                        </div>
                        <div className="Request-Container">
                            <div className="Request-Text">
                                Request
                            </div>
                            <div className="Org-List-Button" onClick={() => this.handleSrcDisplay()}>
                                {
                                    this.state.src ? this.state.src.src : "Source..."
                                }
                                <div className="Care-Search-Selector-Arrow" style={{ marginLeft: '10px' }}></div>
                            </div>
                            <div className="Request-Text">
                                From
                            </div>
                            <div className="Org-List-Button" onClick={() => this.handleOrgDisplay()}>
                                {
                                    this.state.org ? this.state.org.organization : "Target..."
                                }
                                <div className="Care-Search-Selector-Arrow" style={{ marginLeft: '10px' }}></div>
                            </div>
                            <div className="Request-Button-Container" onClick={() => this.handleSendToPayer()}>
                                <i className="fas fa-cloud-download-alt"></i>
                            </div>
                            <div className="Request-Button-Container" onClick={() => this.handleClearSrcDisplay()}>
                                <i className="fas fa-undo-alt"></i>
                            </div>
                        </div>
                        {
                            this.state.srcOpen &&
                            <div className="Org-List-Container">
                                {
                                    this.state.srcs.filter(s => !this.state.org || this.state.org.type === s.type).map((src, sidx) => {
                                        return (
                                            <div key={`src-${sidx}`} className="Org-List-Item" onClick={() => this.handleSrcSelect(src)}>
                                                <div className="Org-List-Name">{src.src}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            this.state.orgsOpen &&
                            <div className="Org-List-Container">
                                {
                                    this.state.orgs.filter(o => !this.state.src || this.state.src.type === o.type).map((org, oidx) => {
                                        return (
                                            <div key={`org-${oidx}`} className="Org-List-Item" onClick={() => this.handleOrgSelect(org)}>
                                                <div className="Org-List-Name">{org.organization}</div>
                                                <div className="Org-List-Url">{org.url}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                </div> : null
        )
    }

    getResource(res, resType) {
        switch (resType) {
            case 'AllergyIntolerance':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Allergy
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Category</div>
                                <div className="Resource-Card-Value">{res.category ? res.category.toString().replace(/,/g, ', ') : null}</div>
                                <div className="Resource-Card-Label">Criticality</div>
                                <div className="Resource-Card-Value">{res.criticality ? res.criticality : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.clinicalStatus && res.clinicalStatus.coding && res.clinicalStatus.coding[0] ? res.clinicalStatus.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                                <div className="Resource-Card-Label">Verification</div>
                                <div className="Resource-Card-Value">{res.verificationStatus && res.verificationStatus.coding ? res.verificationStatus.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Allergy</div>
                                <div className="Resource-Card-Value">{res.code && res.code.coding && res.code.coding[0] ? res.code.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Substance</div>
                                <div className="Resource-Card-Value">{res.reaction && res.reaction[0] ? res.reaction.map(r => r.substance && r.substance.coding && r.substance.coding[0] ? r.substance.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Reaction</div>
                                <div className="Resource-Card-Value">{res.reaction && res.reaction[0] ? res.reaction.map(r => r.manifestation && r.manifestation[0] ? r.manifestation.map(m => m.coding && m.coding[0] ? m.coding.map(code => code.display || '').toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Onset Date</div>
                                <div className="Resource-Card-Value">{res.onsetDateTime ? moment(res.onsetDateTime).format('lll') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Recorded Date</div>
                                <div className="Resource-Card-Value">{res.recordedDate ? moment(res.recordedDate).format('lll') : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'CarePlan':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Care Plan
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Category</div>
                                <div className="Resource-Card-Value">{res.category && res.category[0] ? res.category.map(t => t.coding && t.coding[0] ? t.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : (res.active ? 'Active' : null)}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Period</div>
                                <div className="Resource-Card-Value">
                                    {
                                        res.effectivePeriod && Object.keys(res.effectivePeriod) ? Object.keys(res.effectivePeriod).map((ep, epidx) => {
                                            return (
                                                <div key={`ep-${epidx}`}>{`${ep}: ${moment(res.effectivePeriod[ep]).format('ll')}`}</div>
                                            )
                                        }) : (res.period && Object.keys(res.period) ? Object.keys(res.period).map((ep, epidx) => {
                                            return (
                                                <div key={`ep-${epidx}`}>{`${ep}: ${moment(res.period[ep]).format('ll')}`}</div>
                                            )
                                        }) : null)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Reason</div>
                                <div className="Resource-Card-Value">{res.note && res.note[0] ? res.note.map(n => n.text ? n.text : []).toString().replace(/,/g, ', ') : (res.fhir_comments && res.fhir_comments[0] ? res.fhir_comments.toString().replace(/,/g, ', ') : null)}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Care Team</div>
                                <div className="Resource-Card-Value">{res.careTeam && res.careTeam.reference ? res.careTeam.reference : (res.careTeam && res.careTeam[0] ? res.careTeam.map(ct => ct.reference ? ct.reference : []).toString().replace(/,/g, ', ') : null)}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Goals</div>
                                <div className="Resource-Card-Value">{res.goal && res.goal[0] ? res.goal.map(g => g.reference ? g.reference : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Activities</div>
                                <div className="Resource-Card-Value">{res.activity && res.activity[0] ? res.activity.map(a => `${a.detail.status ? '(' + a.detail.status + ') ' : null}${a.detail && a.detail.code && a.detail.code.coding && a.detail.code.coding[0] ? a.detail.code.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []}`).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Intent</div>
                                <div className="Resource-Card-Value">{res.intent ? res.intent : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Claim':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Claim
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Use/Type</div>
                                <div className="Resource-Card-Value">{res.use ? res.use : null}</div>
                                <div className="Resource-Card-Label">Claim Type</div>
                                <div className="Resource-Card-Value">{res.type && res.type.coding && res.type.coding[0] ? res.type.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : (res.active ? 'Active' : null)}</div>
                                <div className="Resource-Card-Label">Priority</div>
                                <div className="Resource-Card-Value">{res.priority && res.priority.coding && res.priority.coding[0] ? res.priority.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Provider</div>
                                <div className="Resource-Card-Value">{res.provider ? (res.provider.display || res.provider.referenceRange || null) : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Billable Period</div>
                                <div className="Resource-Card-Value">
                                    {
                                        res.billablePeriod && Object.keys(res.billablePeriod) ? Object.keys(res.billablePeriod).map((ep, epidx) => {
                                            return (
                                                <div key={`ep-${epidx}`}>{`${ep}: ${moment(res.billablePeriod[ep]).format('lll')}`}</div>
                                            )
                                        }) : null
                                    }
                                </div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Insurance</div>
                                <div className="Resource-Card-Value">{res.insurance && res.insurance[0] ? res.insurance.map(i => i.coverage && i.coverage.display ? i.coverage.display : []).toString().replace(/,/g, ', ') : null}</div>
                                <div className="Resource-Card-Label">Total</div>
                                <div className="Resource-Card-Value">{res.total && res.total.value ? `${res.total.currency && res.total.currency.toUpperCase() === 'USD' ? '$' : ''}${res.total.value.toFixed(2)}` : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Products or Services</div>
                                {
                                    res.item && res.item[0] ? res.item.filter(item => item.productOrService && item.productOrService.coding && item.productOrService.coding[0]).map((i, iidx) => {
                                        return (
                                            <div key={`item-${iidx}`} className="Resource-Card-Value">{`${i.productOrService.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ')}${i.net && i.net.value ? `: ${i.net.currency && i.net.currency.toUpperCase() === 'USD' ? '$' : ''}${i.net.value.toFixed(2)}` : ''}`}</div>
                                        )
                                    }) : null
                                }
                            </div>
                        </div>
                    </div>
                )
            case 'ClaimResponse':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Claim Response
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Use/Type</div>
                                <div className="Resource-Card-Value">{res.use ? res.use : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : (res.active ? 'Active' : null)}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Claim Type</div>
                                <div className="Resource-Card-Value">{res.type && res.type.coding && res.type.coding[0] ? res.type.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Outcome</div>
                                <div className="Resource-Card-Value">{res.outcome ? res.outcome : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Reference</div>
                                <div className="Resource-Card-Value">{res.request && res.request.reference ? res.request.reference : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Pre-Authorization Reference</div>
                                <div className="Resource-Card-Value">{res.preAuthRef ? res.preAuthRef : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Communication':
                console.log('communication     ', res.payload)
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Communication
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Sender</div>
                                <div className="Resource-Card-Value">{res.sender && res.sender.reference ? res.sender.reference : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value"> {res.status ? res.status : (res.active ? 'Active' : null)}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Recipients</div>
                                <div className="Resource-Card-Value">{res.recipient && res.recipient[0] ? res.recipient.map(r => r.reference ? r.reference : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Commnunication Request</div>
                                <div className="Resource-Card-Value">{res.payload && res.payload[0] ? res.payload.filter(p => p.contentString).map(cs => cs.contentString).toString().replace(/,/, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Attachment</div>
                                <div className="Resource-Card-Value">{res.payload && res.payload[0] && res.payload[0].contentAttachment && res.payload[0].contentAttachment.data ? <a href={`data:application/octet-stream;base64,${res.payload[0].contentAttachment.data}`} download='file.pdf'>View Documentation</a> : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'CommunicationRequest':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Communication Request
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Requester</div>
                                <div className="Resource-Card-Value">{res.requester && res.requester.reference ? res.requester.reference : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : (res.active ? 'Active' : null)}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Request</div>
                                <div className="Resource-Card-Value">{res.payload && res.payload[0] ? res.payload.map(p => p.contentString ? p.contentString : (p.extension && p.extension[0] ? p.extension.map(e => e.valueCodeableConcept && e.valueCodeableConcept.coding ? e.valueCodeableConcept.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : [])).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Recipients</div>
                                <div className="Resource-Card-Value">{res.recipient && res.recipient[0] ? res.recipient.map(r => r.reference ? r.reference : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Sender</div>
                                <div className="Resource-Card-Value">{res.sender && res.sender.reference ? res.sender.reference : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Condition':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Condition/Problem
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Category</div>
                                <div className="Resource-Card-Value">{res.category && res.category[0] ? res.category.map(t => t.coding && t.coding[0] ? t.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                                <div className="Resource-Card-Label">Severity</div>
                                <div className="Resource-Card-Value">{res.severity && res.severity.coding && res.severity.coding[0] ? res.severity.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Verification</div>
                                <div className="Resource-Card-Value">{res.verificationStatus && res.verificationStatus.coding ? res.verificationStatus.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Code</div>
                                <div className="Resource-Card-Value">{res.code && res.code.coding && res.code.coding[0] ? res.code.coding.map(code => code.display || code.code || '').toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Onset Date</div>
                                <div className="Resource-Card-Value">{res.onsetDateTime ? moment(res.onsetDateTime).format('ll') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Abatement</div>
                                <div className="Resource-Card-Value">{res.abatementString ? res.abatementString : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Recorded Date</div>
                                <div className="Resource-Card-Value">{res.recordedDate ? moment(res.recordedDate).format('ll') : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Coverage':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Coverage
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Type</div>
                                <div className="Resource-Card-Value">{res.type && res.type.coding && res.type.coding[0] ? res.type.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : (res.active ? 'Active' : null)}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'ExplanationOfBenefit':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Explanation Of Benefits
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">ID</div>
                                {res.identifier && res.identifier[0] ? res.identifier.map(i => {
                                    return (
                                        <div className="Resource-Card-Value">{i.value}</div>
                                    )
                                }) : null}
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : (res.active ? 'Active' : null)}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Type</div>
                                <div className="Resource-Card-Value">{res.type ? (res.type.text ? res.type.text : res.type.coding && res.type.coding[0] ? res.type.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null) : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Billable Period</div>
                                <div className="Resource-Card-Value">
                                    {
                                        res.billablePeriod && Object.keys(res.billablePeriod) ? Object.keys(res.billablePeriod).map((ep, epidx) => {
                                            return (
                                                <div key={`ep-${epidx}`}>{`${ep}: ${moment(res.billablePeriod[ep]).format('lll')}`}</div>
                                            )
                                        }) : null
                                    }
                                </div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Outcome</div>
                                <div className="Resource-Card-Value">{res.outcome ? res.outcome : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                        <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Insurance</div>
                                {res.insurance && res.insurance[0] ? res.insurance.map(i => {
                                    return (
                                    <div className="Resource-Card-Value">{i.coverage && i.coverage.type ? i.coverage.type : null}</div>
                                    )
                                }) : 'None'}
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Adjudication</div>
                                {res.adjudication && res.adjudication[0] ? res.adjudication.map(a => {
                                    return (
                                        <div className="Resource-Card-Value">{a.amount ? `$${(a.amount.value || '0.00')}` : null}</div>
                                    )
                                }) : null}
                            </div>
                        </div>
                    </div>
                )
            case 'Encounter':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Encounter
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Class</div>
                                <div className="Resource-Card-Value">{res.class && res.class.display ? res.class.display : (res.class && res.class.code ? res.class.code : null)}</div>
                                <div className="Resource-Card-Label">Priority</div>
                                <div className="Resource-Card-Value">{res.priority && res.priority.coding ? res.priority.coding.map(code => code.display ? code.display : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Type</div>
                                <div className="Resource-Card-Value">{res.type && res.type[0] ? res.type.map(t => t.coding && t.coding[0] ? t.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Start</div>
                                <div className="Resource-Card-Value">{res.period && res.period.start ? moment(res.period.start).format('ll') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">End</div>
                                <div className="Resource-Card-Value">{res.period && res.period.end ? moment(res.period.end).format('ll') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Reason</div>
                                <div className="Resource-Card-Value">{res.reasonCode && res.reasonCode[0] ? res.reasonCode.map(r => r.coding && r.coding[0] ? r.coding.map(code => code.display || code.text || code.code || code.text || []).toString().replace(/,/g, ', ') : (r.text ? r.text : [])).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Participant</div>
                                <div className="Resource-Card-Value">{res.participant && res.participant[0] ? res.participant.map(p => Object.keys(p).map(key => `${p[key].display} (${key})`)).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Provider</div>
                                <div className="Resource-Card-Value">{res.serviceProvider && (res.serviceProvider.display || res.serviceProvider.text) ? res.serviceProvider.display || res.serviceProvider.text : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Immunization':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Immunizations
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Vaccine</div>
                                <div className="Resource-Card-Value">{res.vaccineCode ? (res.vaccineCode.text ? res.vaccineCode.text : (res.vaccineCode.coding && res.vaccineCode.coding[0] ? res.vaccineCode.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null)) : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : (res.active ? 'Active' : null)}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Primary Source</div>
                                <div className="Resource-Card-Value">{res.primarySource ? res.primarySource : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Lot</div>
                                <div className="Resource-Card-Value">{res.lotNumber ? res.lotNumber : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Reason</div>
                                <div className="Resource-Card-Value">{res.reasonCode && res.reasonCode[0] ? res.reasonCode.map(rc => rc.coding && rc.coding[0] ? rc.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Dose</div>
                                <div className="Resource-Card-Value">{res.doseQuantity ? `${res.doseQuantity.value ? res.doseQuantity.value : null}${res.doseQuantity.value && res.doseQuantity.code ? ' ' + res.doseQuantity.code : null}` : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Site</div>
                                <div className="Resource-Card-Value">{res.site && res.site.coding && res.site.coding[0] ? res.site.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Route</div>
                                <div className="Resource-Card-Value">{res.route && res.route.coding && res.route.coding[0] ? res.route.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Occurence Date</div>
                                <div className="Resource-Card-Value">{res.occurrenceDataTime ? moment(res.occurrenceDataTime).format('ll') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Expiration Date</div>
                                <div className="Resource-Card-Value">{res.expirationDate ? moment(res.expirationDate).format('ll') : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'MedicationStatement':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Medication
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Medication</div>
                                <div className="Resource-Card-Value">{res.medicationCodeableConcept && res.medicationCodeableConcept.text ? res.medicationCodeableConcept.text : (res.medicationCodeableConcept && res.medicationCodeableConcept.coding && res.medicationCodeableConcept.coding[0] ? res.medicationCodeableConcept.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null)}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Start</div>
                                <div className="Resource-Card-Value">{res.effectivePeriod && res.effectivePeriod.start ? moment(res.effectivePeriod.start).format('ll') : (res.effectiveDateTime ? moment(res.effectiveDateTime).format('ll') : null)}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">End</div>
                                <div className="Resource-Card-Value">{res.effectivePeriod && res.effectivePeriod.end ? moment(res.effectivePeriod.end).format('ll') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Notes</div>
                                <div className="Resource-Card-Value">{res.note && res.note[0] ? res.note.map(n => n.text ? n.text : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Reason</div>
                                <div className="Resource-Card-Value">{res.reasonCode && res.reasonCode[0] ? res.reasonCode.map(rc => rc.coding && rc.coding[0] ? rc.coding.map(code => code.display || code.code || []) : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'MeasureReport':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Measure Reports/Gaps in Care
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : null}</div>
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.date ? moment(res.date).format('lll') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-left">
                                <div className="Resource-Card-Label">Type</div>
                                <div className="Resource-Card-Value">{res.type ? res.type : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Start</div>
                                <div className="Resource-Card-Value">{res.period && res.period.start ? moment(res.period.start).format('L') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">End</div>
                                <div className="Resource-Card-Value">{res.period && res.period.end ? moment(res.period.end).format('L') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">% Complete</div>
                                <div className="Resource-Card-Value">{this.getPcntComplete(res)}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Observation':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Observations/Vitals
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Category</div>
                                <div className="Resource-Card-Value">{res.category && res.category[0] ? res.category.map(cat => cat.coding && cat.coding[0] ? cat.coding.map(code => code.display || code.text || code.code || []) : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : (res.active ? 'Active' : null)}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Measurement Type</div>
                                <div className="Resource-Card-Value">{res.code && res.code.coding && res.code.coding[0] ? res.code.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Measurement</div>
                                <div className="Resource-Card-Value">{res.component && res.component[0] ? res.component.map(c => this.getMeasurement(c)).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Reference</div>
                                <div className="Resource-Card-Value">
                                    {
                                        res.referenceRange && res.referenceRange[0] ? res.referenceRange.map((rr, rridx) => {
                                            return (

                                                Object.keys(rr) ? Object.keys(rr).map((r, ridx) => {
                                                    return (
                                                        <div key={`rr-${rridx}-${ridx}`}>{`${r}: ${rr[r] && rr[r].value ? rr[r].value : null} ${rr[r] && rr[r].unit ? rr[r].unit : null}`}</div>
                                                    )
                                                }) : null

                                            )
                                        }) : null
                                    }
                                </div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Interpretation</div>
                                <div className="Resource-Card-Value">{res.interpretation && res.interpretation[0] ? res.interpretation.map(t => t.coding && t.coding[0] ? t.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Period</div>
                                <div className="Resource-Card-Value">
                                    {
                                        res.effectivePeriod && Object.keys(res.effectivePeriod) ? Object.keys(res.effectivePeriod).map((ep, epidx) => {
                                            return (
                                                <div key={`ep-${epidx}`}>{`${ep}: ${moment(res.effectivePeriod[ep]).format('ll')}`}</div>
                                            )
                                        }) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div >
                )
            case 'Procedure':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Observations/Vitals
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Procedure</div>
                                {res.code && res.code.coding && res.code.coding[0] ? res.code.coding.map(c => {
                                    return <div className="Resource-Card-Value">{c.display || c.code || null}</div>
                                }) : null}
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Procedure Date</div>
                                <div className="Resource-Card-Value">{res.performedDateTime ? res.performedDateTime : null}</div>
                            </div>
                        </div>
                    </div>
                )
            default:
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            {resType}
                            <div className={`Resource-Card-Delete ${res.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource(resType, res.id || null)}><i className="fas fa-trash-alt"></i></div>
                            <div className="Resource-Card-Item-Right">
                                {res.id ? `ID ${res.id}` : null}
                            </div>
                        </div>
                        <div className="Member-Table-Body"><pre>{JSON.stringify(res, undefined, 2)}</pre></div>
                    </div>
                )
        }
    }

    getMeasurement(m) {
        let code = m.code && m.code.coding && m.code.coding[0] ? m.code.coding[0].display || m.code.coding[0].text || m.code.coding[0].code || null : null
        let val = m.valueQuantity ? `${m.valueQuantity.value ? m.valueQuantity.value : ''} ${m.valueQuantity.unit || m.valueQuantity.code || ''}` : null
        let interp = m.interpretation && m.interpretation[0] ? m.interpretation.map(i => i.coding && i.coding[0] ? i.coding.map(c => c.display || c.text || c.code || []) : []).toString().replace(/,/g, ', ') : null
        return `${code ? code + ': ' : ''}${val || ''} ${interp ? `(${interp})` : ''}`
    }

    getPcntComplete(res) {
        if (res.group && res.group[0] && res.group.filter(g => g.population && g.population[0] && g.population.filter(p => p.code && p.code.coding && p.code.coding[0] && p.code.coding[0].code && (p.code.coding[0].code === 'numerator' || p.code.coding[0].code === 'denominator')).length === 2).length === 1) {
            let grp = res.group.findIndex(g => g.population && g.population[0] && g.population.filter(p => p.code && p.code.coding && p.code.coding[0] && p.code.coding[0].code && (p.code.coding[0].code === 'numerator' || p.code.coding[0].code === 'denominator')).length === 2)
            let num = res.group[grp].population[res.group[grp].population.findIndex(p => p.code && p.code.coding && p.code.coding[0] && p.code.coding[0].code && p.code.coding[0].code === 'numerator')].count
            let den = res.group[grp].population[res.group[grp].population.findIndex(p => p.code && p.code.coding && p.code.coding[0] && p.code.coding[0].code && p.code.coding[0].code === 'denominator')].count
            return `${parseInt(num / den * 100)}%`
        } else return null
    }

    getFormattedResource(param, pidx, rid) {
        const res = param.resource
        if (res) {
            switch (res.resourceType) {
                case 'Encounter':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-ambulance"></i> Encounter</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status ? res.status.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Code</div>
                            <div className="MRP-Result-Section-Value">{res.class && res.class.display ? res.class.display.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Type</div>
                            <div className="MRP-Result-Section-Value">{res.type && res.type[0] && res.type[0].text ? res.type[0].text : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Patient</div>
                            <div className="MRP-Result-Section-Value">{res.subject && res.subject.display ? res.subject.display : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Date</div>
                            <div className="MRP-Result-Section-Value">{res.period && res.period.start ? moment(res.period.start).format('LLL') : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Reason</div>
                            <div className="MRP-Result-Section-Value">{res.reasonCode && res.reasonCode[0] && res.reasonCode[0].text ? res.reasonCode[0].text : 'Not Listed'}</div>
                            <div className="MRP-Result-Section-Label">Diagnosis</div>
                            <div className="MRP-Result-Section-Value">{res.diagnosis && res.diagnosis[0] && res.diagnosis[0].condition && res.diagnosis[0].condition.display ? res.diagnosis[0].condition.display : 'Not Listed'}</div>
                        </div>
                    )
                case 'Immunization':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section" style={{ minWidth: '195px' }}>
                            <div className="MRP-Result-Section-Title"><i className="fas fa-syringe"></i> Immunization</div>
                            <div className="MRP-Result-Section-Label">Vaccine</div>
                            <div className="MRP-Result-Section-Value">{res.vaccineCode ? (res.vaccineCode.text ? res.vaccineCode.text : (res.vaccineCode.coding && res.vaccineCode.coding[0] ? res.vaccineCode.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null)) : null}</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status && res.status.toLowerCase().indexOf('complete') >= 0 ? <i className="far fa-check-circle"></i> : <i className="fas fa-exclamation-circle"></i>} {res.status ? res.status.toUpperCase() : 'Unavailable'}</div>
                            <div className="MRP-Result-Section-Label">Source</div>
                            <div className="MRP-Result-Section-Value">{res.primarySource ? res.primarySource : null}</div>
                            <div className="MRP-Result-Section-Label">Lot</div>
                            <div className="MRP-Result-Section-Value">{res.lotNumber ? res.lotNumber : null}</div>
                            <div className="MRP-Result-Section-Label">Reason</div>
                            <div className="MRP-Result-Section-Value">{res.reasonCode && res.reasonCode[0] ? res.reasonCode.map(rc => rc.coding && rc.coding[0] ? rc.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            <div className="MRP-Result-Section-Label">Dose</div>
                            <div className="MRP-Result-Section-Value">{res.doseQuantity ? `${res.doseQuantity.value ? res.doseQuantity.value : null}${res.doseQuantity.value && res.doseQuantity.code ? ' ' + res.doseQuantity.code : null}` : null}</div>
                            <div className="MRP-Result-Section-Label">Site</div>
                            <div className="MRP-Result-Section-Value">{res.site && res.site.coding && res.site.coding[0] ? res.site.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            <div className="MRP-Result-Section-Label">Route</div>
                            <div className="MRP-Result-Section-Value">{res.route && res.route.coding && res.route.coding[0] ? res.route.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            <div className="MRP-Result-Section-Label">Occurence Date</div>
                            <div className="MRP-Result-Section-Value">{res.occurrenceDataTime ? moment(res.occurrenceDataTime).format('ll') : null}</div>
                            <div className="MRP-Result-Section-Label">Expiration Date</div>
                            <div className="MRP-Result-Section-Value">{res.expirationDate ? moment(res.expirationDate).format('ll') : null}</div>
                        </div>
                    )
                case 'Location':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-map-marker-alt"></i> Location</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status && res.status.toLowerCase() === 'active' ? <i className="far fa-check-circle"></i> : <i className="fas fa-exclamation-circle"></i>} {res.status ? res.status.toUpperCase() : 'Unavailable'}</div>
                            <div className="MRP-Result-Section-Label">Name</div>
                            <div className="MRP-Result-Section-Value">{res.name ? res.name : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Description</div>
                            <div className="MRP-Result-Section-Value">{res.description ? res.description : 'Undefined'}</div>
                            {
                                res.telecom && res.telecom.length > 0 && res.telecom.map((t, tid) => {
                                    return (
                                        <div key={`t-${rid}-${pidx}-${tid}`}>
                                            <div className="MRP-Result-Section-Label">{t.use && t.use === 'home' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {`${t.use ? t.use.toUpperCase() : 'Phone'}/${t.system ? t.system.toUpperCase() : 'Phone'}`}</div>
                                            <div className="MRP-Result-Section-Value">{t.value ? t.value : 'Undefined'}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className="MRP-Result-Section-Label"><i className="fas fa-map-marker-alt"></i> Address</div>
                            {
                                res.address && res.address && res.address.text ? res.address.text.split(", ").map((addr, aidx) => {
                                    return <div key={`patAddr-${rid}-${pidx}-${aidx}`} className="MRP-Result-Section-Value">{addr}</div>
                                }) : <div className="MRP-Result-Section-Value">Unlisted</div>
                            }
                        </div>
                    )
                case 'MeasureReport':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-clipboard-check"></i> Report</div>
                            <div className="MRP-Result-Section-Label">Type</div>
                            <div className="MRP-Result-Section-Value">{res.type ? res.type.toUpperCase() : 'Unavailable'}</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status && res.status.toLowerCase().indexOf('complete') >= 0 ? <i className="far fa-check-circle"></i> : <i className="fas fa-exclamation-circle"></i>} {res.status ? res.status.toUpperCase() : 'Unavailable'}</div>
                            <div className="MRP-Result-Section-Label">Measure</div>
                            <div className="MRP-Result-Section-Value">{res.measure ? res.measure.split('/')[res.measure.split('/').length - 1] : null}</div>
                            <div className="MRP-Result-Section-Label">Measure ID</div>
                            <div className="MRP-Result-Section-Value">{res.id || null}</div>
                            <div className="MRP-Result-Section-Label">Date</div>
                            <div className="MRP-Result-Section-Value">{res.date ? moment(res.date).format('lll') : moment().format('ll')}</div>
                        </div>
                    )
                case 'Organization':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="far fa-hospital"></i> Organization</div>
                            <div className="MRP-Result-Section-Label">{res.identifier && res.identifier[0] && res.identifier[0].type && res.identifier[0].type.text ? res.identifier[0].type.text : 'Identifier'}</div>
                            <div className="MRP-Result-Section-Value">{res.identifier && res.identifier[0] && res.identifier[0].value ? res.identifier[0].value : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Active</div>
                            <div className="MRP-Result-Section-Value">{res.active ? 'Yes' : 'No'}</div>
                            <div className="MRP-Result-Section-Label">Name</div>
                            <div className="MRP-Result-Section-Value">{res.name ? res.name : 'Undefined'}</div>
                            {
                                res.telecom && res.telecom.length > 0 && res.telecom.map((t, tid) => {
                                    return (
                                        <div key={`t-${rid}-${pidx}-${tid}`}>
                                            <div className="MRP-Result-Section-Label">{t.use && t.use === 'home' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {`${t.use ? t.use.toUpperCase() : 'Phone'}/${t.system ? t.system.toUpperCase() : 'Phone'}`}</div>
                                            <div className="MRP-Result-Section-Value">{t.value ? t.value : 'Undefined'}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className="MRP-Result-Section-Label"><i className="fas fa-map-marker-alt"></i> Address</div>
                            {
                                res.address && res.address[0] && res.address[0].text ? res.address[0].text.split(", ").map((addr, aidx) => {
                                    return <div key={`patAddr-${rid}-${pidx}-${aidx}`} className="MRP-Result-Section-Value">{addr}</div>
                                }) : <div className="MRP-Result-Section-Value">Unlisted</div>
                            }
                        </div>
                    )
                case 'Patient':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-procedures"></i> Patient</div>
                            <div className="MRP-Result-Section-Label">ID</div>
                            <div className="MRP-Result-Section-Value">{res.id || 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.active ? 'ACTIVE' : 'INACTIVE'}</div>
                            <div className="MRP-Result-Section-Label">Name</div>
                            <div className="MRP-Result-Section-Value">{res.name && res.name[0] && res.name[0].text ? res.name[0].text : 'Undefined'}</div>
                            {
                                res.telecom && res.telecom.length > 0 && res.telecom.map((t, tid) => {
                                    return (
                                        <div key={`t-${rid}-${pidx}-${tid}`}>
                                            <div className="MRP-Result-Section-Label">{t.use && t.use === 'home' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {`${t.use ? t.use.toUpperCase() : 'Phone'}/${t.system ? t.system.toUpperCase() : 'Phone'}`}</div>
                                            <div className="MRP-Result-Section-Value">{t.value ? t.value : 'Undefined'}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className="MRP-Result-Section-Label"><i className="fas fa-female"></i><i className="fas fa-male"></i> Gender</div>
                            <div className="MRP-Result-Section-Value">{res.gender ? res.gender.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label"><i className="fas fa-birthday-cake"></i> D.O.B.</div>
                            <div className="MRP-Result-Section-Value">{res.birthDate || 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Deceased</div>
                            <div className="MRP-Result-Section-Value">{res.deceasedBoolean ? 'Yes' : 'No'}</div>
                            <div className="MRP-Result-Section-Label"><i className="fas fa-map-marker-alt"></i> Address</div>
                            {
                                res.address && res.address[0] && res.address[0].text ? res.address[0].text.split(", ").map((addr, aidx) => {
                                    return <div key={`patAddr-${rid}-${pidx}-${aidx}`} className="MRP-Result-Section-Value">{addr}</div>
                                }) : <div className="MRP-Result-Section-Value">Unlisted</div>
                            }
                            <div className="MRP-Result-Section-Label"><i className="fas fa-user-md"></i> Practitioner</div>
                            <div className="MRP-Result-Section-Value">{res.generalPractitioner && res.generalPractitioner[0] && res.generalPractitioner[0].display ? res.generalPractitioner[0].display : 'Undefined'}</div>
                        </div>

                    )
                case 'Practitioner':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-user-md"></i> Provider</div>
                            <div className="MRP-Result-Section-Label">NPI</div>
                            <div className="MRP-Result-Section-Value">{res.identifier && res.identifier[0] && res.identifier[0].value ? res.identifier[0].value : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Name</div>
                            <div className="MRP-Result-Section-Value">{res.name && res.name[0] && res.name[0].text ? res.name[0].text : 'Undefined'}</div>
                        </div>
                    )
                case 'Task':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-clipboard-list"></i> Task</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status && res.status.toLowerCase() === 'accepted' ? <i className="far fa-check-circle"></i> : <i className="fas fa-exclamation-circle"></i>} {res.status ? res.status.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Intent</div>
                            <div className="MRP-Result-Section-Value">{res.intent ? res.intent.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Priority</div>
                            <div className="MRP-Result-Section-Value">{res.priority ? res.priority.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">CPT Reason</div>
                            <div className="MRP-Result-Section-Value">{res.code && res.code.text ? res.code.text : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Authored On</div>
                            <div className="MRP-Result-Section-Value">{res.authoredOn ? moment(res.authoredOn).format('LLL') : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Start-End</div>
                            <div className="MRP-Result-Section-Value">{`${res.executionPeriod && res.executionPeriod.start ? moment(res.executionPeriod.start).format('L') : 'Undefined'}-${res.executionPeriod && res.executionPeriod.end ? moment(res.executionPeriod.end).format('L') : 'Undefined'}`}</div>
                        </div>
                    )
                default:
                    return (
                        <div className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title">
                                {res.resourceType}
                            </div>
                            <div className="Member-Table-Body" style={{ maxWidth: "200px" }}><pre>{JSON.stringify(res, undefined, 2)}</pre></div>
                        </div>
                    )
            }
        }
        return null
    }


    getView() {
        switch (this.state.view) {
            case 'FHIR':
                if (!this.state.iid) this.getFhirData(this.props.mbrId)
                return (
                    <div className="Care-Row">
                        {this.getPatientHeader()}
                        {this.getMenu()}
                        <div className="Member-Table-Container">
                            {
                                Object.keys(this.state.headers).map((h, hidx) => {
                                    return (
                                        <div key={`header-${hidx}`}>
                                            <div className={`Member-Table-Header ${this.state.headers[h].open ? 'Member-Table-Header-Open' : 'Member-Table-Header-Close'}`} onClick={() => this.handleHeaderSelect(h)}>
                                                <div className="Member-Table-Header-Name">{this.state.headers[h].title}</div>
                                                <div className="Member-Table-Header-Info">{this.state.headers[h].open ? <i className="fas fa-arrow-circle-up"></i> : <i className="fas fa-arrow-circle-down"></i>}</div>
                                                <div className="Member-Table-Header-Info">{this.state.headers[h].res.length}</div>
                                            </div>
                                            {this.state.headers[h].open ?
                                                <div className="Resource-Card-Container">
                                                    {
                                                        this.state.headers[h].res.map((res, ridx) => {
                                                            return <div key={`header-${ridx}`}>{this.getResource(res.resource, h)}</div>
                                                        })
                                                    }
                                                </div> : null
                                            }
                                        </div>
                                    )
                                })
                            }
                            {
                                this.state.measures.res.length > 0 ?
                                    <div>
                                        <div className={`Member-Table-Header ${this.state.measures.open ? 'Member-Table-Header-Open' : 'Member-Table-Header-Close'}`} onClick={() => this.handleMeasuresSelect()}>
                                            <div className="Member-Table-Header-Name">{this.state.measures.title}</div>
                                            <div className="Member-Table-Header-Info">{this.state.measures.open ? <i className="fas fa-arrow-circle-up"></i> : <i className="fas fa-arrow-circle-down"></i>}</div>
                                            <div className="Member-Table-Header-Info">{this.state.measures.res.length}</div>
                                        </div>
                                        {this.state.measures.open ?
                                            <div className="Resource-Card-Container">
                                                {
                                                    this.state.measures.res.map((params, pidx) => {
                                                        return (
                                                            <div key={`meas-${pidx}`} className="Resource-Card-Container-MRP">
                                                                <div className="Resource-Card-Title" style={{ marginBottom: '20px' }}>
                                                                    Quality Measure
                                                                    <div className={`Resource-Card-Delete ${this.state.measures.deleted ? 'Resource-Card-Delete-Blink' : ''}`} onClick={() => this.handleDeleteResource('Parameters', params.resource.id || null)}><i className="fas fa-trash-alt"></i></div>
                                                                    <div className="Resource-Card-Item-Right">
                                                                        {params.resource.id ? `ID ${params.resource.id}` : null}
                                                                    </div>
                                                                </div>
                                                                <div className="Member-MRP-Row">
                                                                    {
                                                                        params.resource && params.resource.parameter && params.resource.parameter.map((res, ridx) => {
                                                                            return <div key={`mrp-${pidx}-${ridx}`} className="Member-Table-MRP-Row">
                                                                                {this.getFormattedResource(res, pidx, ridx)}
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> : null
                                        }
                                    </div> : null
                            }
                        </div>
                    </div>
                )
            case "EDI":
                if (!this.state.edi) this.getEDI()
                return (
                    <div className="Care-Row">
                        {this.getPatientHeader()}
                        {this.getMenu()}
                    </div>
                )
            case "Biometrics":
                if (!this.state.bm) this.handleGetBioMetrics()
                return (
                    <div className="Care-Row">
                        {this.getPatientHeader()}
                        {this.getMenu()}
                        <div className="Me-Section-Graphs">
                            {this.getWeightGraph()}
                            {this.getBPGraph()}
                            {this.getPulseGraph()}
                        </div>
                    </div>
                )
            default:
                return (
                    <div>
                        <div className="Care-Row">
                            <div className="Care-Search-Container">
                                <div className="Care-Row Care-Search-Label">Member Smart Search{this.state.isLoading && <i className="fas fa-spinner"></i>}</div>
                                <div className="Care-Row Care-Search-Outter">
                                    <div className="Care-Search-Selector" onClick={() => this.handleSelector()}>
                                        {this.state.searchSelection ? this.state.searchSelection : 'Member (Name/ID/DOB)'}
                                        <div className="Care-Search-Selector-Arrow"></div>
                                    </div>
                                    <input type="text" value={this.state.search || ''} onChange={event => this.handleSearchTextChange(event.target.value)} className="Care-Search-Bar" placeholder={`Search by ${this.state.searchSelection ? this.state.searchSelection : "Member (Name/ID/DOB)"}`}></input>
                                    <div className="Care-Search-Button" onClick={() => this.handleSearchTextChange(this.state.search)}><i className="fas fa-search"></i></div>
                                </div>
                                {
                                    this.state.displaySelections &&
                                    <div className="Care-Row Care-Search-Selections">
                                        <div className="Care-Search-Selections-Row" onClick={() => this.handleSelection('Member (Name/ID/DOB)')}>Member (Name/ID/DOB)</div>
                                        <div className="Care-Search-Selections-Row" onClick={() => this.handleSelection('Member Name + Optional DOB')}>Member Name + DOB</div>
                                        <div className="Care-Search-Selections-Row" onClick={() => this.handleSelection('Member First Name + Optional DOB')}>Member First + DOB </div>
                                        <div className="Care-Search-Selections-Row" onClick={() => this.handleSelection('Member Last Name + Optional DOB')}>Member Last + DOB </div>
                                        <div className="Care-Search-Selections-Row" onClick={() => this.handleSelection('Member ID')}>Member ID</div>
                                    </div>
                                }
                            </div>
                        </div>
                        {this.getSearchResults()}
                    </div>
                )
        }
    }

    getMenu() {
        return (
            <div className="Member-Menu-Container">
                {
                    this.state.menus.map((m, midx) => {
                        return <div key={`memu-${midx}`} className={`Member-Menu ${this.state.view === m ? 'Member-Menu-Selected' : 'Member-Menu-Not-Selected'}`} onClick={() => this.handleMenuSelect(m)}>{m}</div>
                    })
                }
            </div>
        )
    }

    getSearchResults() {
        return (
            <div className="Members-Search-Results-Container">
                {
                    this.state.members.map((m, midx) => {
                        return (
                            <div key={`mbr-${midx}`} className="Members-Search-Result-Container" onClick={() => this.handleMemberSelect(m.id)}>{this.getPatientCard(m, midx)}</div>
                        )
                    })
                }
            </div>
        )
    }

    getWeightGraph() {
        const dataWS = this.state.ws ? this.state.ws.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return 1
            if (x < y) return -1
            return 0
        }).map(v => parseInt(v.values.weight * wtCalc)) : []
        return (
            <div className="Dashboard-Card-Graph-Container-1">
                <div className="Dashboard-Details-Graph-Title">
                    <img src={Weight} alt="" className="Dashboard-Details-Graph-Title-Left" />
                    <div className="Dashboard-Details-Graph-Title-Left">Weight</div>
                    <div className="Dashboard-Details-Graph-Title-Right" style={{ color: '#fff' }}>{dataWS[0] ? `${dataWS[dataWS.length - 1]} lbs` : ''}</div>
                </div>
                {this.state.ws && <Line data={{
                    labels: this.state.ws && this.state.ws.map(v => moment.unix(v.ts / 1000).format('l')),
                    datasets: [
                        {
                            label: "Weight",
                            strokeColor: "#3771be",
                            pointColor: "#5399f4",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "#fff",
                            data: dataWS
                        }
                    ]
                }} width={250} height={200} options={{ datasetFill: false, scaleGridLineColor: '#fff', legend: { display: false } }}
                />}
            </div>
        )
    }

    getPulseGraph() {
        const dataP = this.state.bps ? this.state.bps.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return 1
            if (x < y) return -1
            return 0
        }).map(v => parseInt(v.values.pulse)) : []
        return (
            <div className="Dashboard-Card-Graph-Container-2">
                <div className="Dashboard-Details-Graph-Title">
                    <img src={Running} alt="" className="Dashboard-Details-Graph-Title-Left" />
                    <div className="Dashboard-Details-Graph-Title-Left">Heart Rate</div>
                    <div className="Dashboard-Details-Graph-Title-Right" style={{ color: '#fff' }}>{dataP[0] ? `${dataP[dataP.length - 1]} bpm` : ''}</div>
                </div>
                {this.state.bps && <Bar data={{
                    labels: this.state.bps && this.state.bps.map(v => moment.unix(v.ts / 1000).format('l')),
                    datasets: [
                        {
                            label: "Pulse",
                            strokeColor: "#3771be",
                            fillColor: "#82defa",
                            HighlightFill: "#fff",
                            HighlightStroke: "#fff",
                            data: dataP
                        }
                    ]
                }} width={250} height={200} options={{ datasetFill: false, scaleGridLineColor: '#fff', scales: { yAxes: [{ ticks: { suggestedMin: 50 } }] }, legend: { display: false } }}
                />}
            </div>
        )
    }

    getBPGraph() {
        const dataS = this.state.bps && this.state.bps[0] ? this.state.bps.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return -1
            if (x < y) return 1
            return 0
        }).map(v => parseInt(v.values.systolic * bpCalc)) : []
        const dataD = this.state.bps && this.state.bps[0] ? this.state.bps.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return -1
            if (x < y) return 1
            return 0
        }).map(v => parseInt(v.values.diastolic * bpCalc)) : []

        return (
            <div className="Dashboard-Card-Graph-Container-3">
                <div className="Dashboard-Details-Graph-Title">
                    <img src={Heartbeat} alt="" className="Dashboard-Details-Graph-Title-Left" />
                    <div className="Dashboard-Details-Graph-Title-Left">BP</div>
                    <div className="Dashboard-Details-Graph-Title-Right" style={{ color: '#fff' }}>{dataS[0] && dataD[0] ? `${dataS[dataS.length - 1]}/${dataD[dataD.length - 1]} mmHg` : ''}</div>
                </div>
                {this.state.bps && <Line data={{
                    labels: this.state.bps && this.state.bps.map(v => moment.unix(v.ts / 1000).format('l')),
                    datasets: [
                        {
                            label: "Systolic",
                            strokeColor: "#41c2e9",
                            pointColor: "#5399f4",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "#fff",
                            data: dataS
                        },
                        {
                            label: "Diastolic",
                            strokeColor: "#82defa",
                            pointColor: "#5399f4",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "#fff",
                            data: dataD
                        }
                    ]
                }} width={250} height={200} options={{ datasetFill: false, scaleGridLineColor: '#fff', legend: { display: false } }}
                />}
            </div>
        )
    }

    render() {
        console.log(this.state)
        return (
            <div className="Care">
                {this.getView()}
            </div>
        )
    }
}

export default Care