import React, { Component } from 'react'
import { HelpBlock, FormGroup, FormControl, ControlLabel } from 'react-bootstrap'
import LoaderButton from '../components/loaderButton'
import './signup.css'
import { Auth, API } from 'aws-amplify';

class Signup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            email: '',
            pw: '',
            cpw: '',
            code: '',
            fn: '',
            ln: '',
            dob: '',
            id: '',
            user: null
        }
    }

    formIsValid() {
        return (
            this.state.email.length > 0 &&
            this.state.pw.length > 0 &&
            this.state.cpw === this.state.pw
        )
    }

    codeIsValid() {
        return this.state.code.length > 0
    }

    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleSubmit = async event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        try {
            const user = await Auth.signUp({
                username: this.state.email,
                password: this.state.pw
            })
            this.setState({ user })
        } catch (e) {
            if (e.code === 'UsernameExistsException') {
                Auth.resendSignUp(this.state.email)
                this.setState({ user: true })
            } else {
                alert(e.message)
            }
        }

        this.setState({ isLoading: false })
    }

    handleConfirmSubmit = async event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        try {
            await Auth.confirmSignUp(this.state.email, this.state.code)
            await Auth.signIn(this.state.email, this.state.pw)
            let currUser = await Auth.currentAuthenticatedUser()
            this.setState({ user: currUser })
            API.post('member', '/member', { body: {
                email: this.state.email,
                firstName: this.state.fn,
                lastName: this.state.ln,
                dob: this.state.dob,
                mbrId: this.state.id,
                uid: currUser.username
            } })
            this.props.setUser({
                email: this.state.email,
                firstName: this.state.fn,
                lastName: this.state.ln,
                dob: this.state.dob,
                mbrId: this.state.id,
                uid: currUser.username
            })
            this.props.userHasAuthenticated(true)
            this.props.history.push('/')
        } catch (e) {
            alert(e.message)
            this.setState({ isLoading: false })
        }
    }

    getValidation(field) {
        switch (field) {
            case 'email':
                return this.state.email.length > 0 ? (this.state.email.indexOf('.') < 0 || this.state.email.indexOf('@') < 0 ? 'warning' : 'success') : 'error'
            case 'pw':
                return this.state.pw.length > 0 ? (this.state.pw.length >= 8 ? 'success' : 'warning') : 'error'
            case 'cpw':
                return this.state.cpw.length > 0 && this.state.cpw === this.state.pw ? 'success' : 'error'
            case 'fn':
                return this.state.fn.length > 0 ? 'success' : 'error'
            case 'ln':
                return this.state.ln.length > 0 ? 'success' : 'error'
            case 'dob':
                return this.state.ln.length > 0 ? 'success' : 'error'
            case 'id':
                return this.state.id.length > 0 ? 'success' : 'error'
            default:
                return 'warning'
        }
    }

    getUserForm() {
        return (
            <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="fn" bsSize="large" validationState={this.getValidation('fn')}>
                    <ControlLabel>First Name</ControlLabel>
                    <FormControl
                        autoFocus
                        placeholder="First Name"
                        type="text"
                        value={ this.state.fn }
                        onChange={ this.handleChange }
                    />
                </FormGroup>
                <FormGroup controlId="ln" bsSize="large" validationState={this.getValidation('ln')}>
                    <ControlLabel>Last Name</ControlLabel>
                    <FormControl
                        placeholder="Last Name"
                        type="text"
                        value={ this.state.ln }
                        onChange={ this.handleChange }
                    />
                </FormGroup>
                <FormGroup controlId="dob" bsSize="large" validationState={this.getValidation('dob')}>
                    <ControlLabel>Date of Birth</ControlLabel>
                    <FormControl
                        type="date"
                        value={ this.state.dob }
                        onChange={ this.handleChange }
                    />
                </FormGroup>
                <FormGroup controlId="id" bsSize="large" validationState={this.getValidation('id')}>
                    <ControlLabel>Member ID</ControlLabel>
                    <FormControl
                        placeholder="Patient or Member ID"
                        type="text"
                        value={ this.state.id }
                        onChange={ this.handleChange }
                    />
                </FormGroup>
                <FormGroup controlId="email" bsSize="large" validationState={this.getValidation('email')}>
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                        type="email"
                        value={ this.state.email }
                        onChange={ this.handleChange }
                    />
                </FormGroup>
                <FormGroup controlId="pw" bsSize="large" validationState={this.getValidation('pw')}>
                    <ControlLabel>Password</ControlLabel>
                    <FormControl
                        value={ this.state.pw }
                        onChange={ this.handleChange }
                        type="password"
                    />
                    <HelpBlock>Password must be at least 8 characters, 1 number, 1 special character and 1 uppercase letter</HelpBlock>
                </FormGroup>
                <FormGroup controlId="cpw" bsSize="large" validationState={this.getValidation('cpw')}>
                    <ControlLabel>Confirm Password</ControlLabel>
                    <FormControl
                        value={ this.state.cpw }
                        onChange={ this.handleChange }
                        type="password"
                    />
                </FormGroup>
                <LoaderButton
                    block
                    bsSize="large"
                    disabled={!this.formIsValid()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Signup"
                    loadingText="Signing up…"
                />
            </form>
        )
    }

    getConfirmForm() {
        return (
            <form onSubmit={this.handleConfirmSubmit}>
                <FormGroup controlId="code" bsSize="large">
                    <ControlLabel>Confirmation Code</ControlLabel>
                    <FormControl
                        autoFocus
                        type="tel"
                        value={this.state.code}
                        onChange={this.handleChange}
                    />
                    <HelpBlock>Please check your email for the code.</HelpBlock>
                </FormGroup>
                <LoaderButton
                    block
                    bsSize="large"
                    disabled={!this.codeIsValid()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Verify"
                    loadingText="Verifying…"
                />
            </form>
        )
    }

    render() {
        console.log(this.state, this.props)
        return (
            <div className="Signup">
                {this.state.user ? this.getConfirmForm() : this.getUserForm()}
            </div>
        )
    }
}

export default Signup