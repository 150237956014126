import React, { Component } from 'react'
//import { API } from 'aws-amplify'
import './rx.css'

class Rx extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    getMeds() {

    }
    render() {
        return (
            <div>rx</div>
        )
    }
}

export default Rx