import React from 'react'
import { Button, Glyphicon } from 'react-bootstrap'
import './loaderButton.css'

export default ({
    isLoading,
    text,
    loadingText,
    className = '',
    disabled = false,
    ...props
}) => 
<Button className={`LoaderButton ${className}`}
    disabled={ disabled || isLoading }
    { ...props }>
    { isLoading && <Glyphicon glyph="refresh" className="spinning" /> }
    { isLoading ? loadingText : text }
</Button>