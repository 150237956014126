import React, { Component } from 'react'
import moment from 'moment'
import { Line, Bar } from "react-chartjs-2"
import { API } from 'aws-amplify'
import IDCard from '../../images/idcard.svg'
import MedInfo from '../../images/stethoscope.svg'
import MyInfo from '../../images/form.svg'
import Weight from '../../images/weight.svg'
import Running from '../../images/running.svg'
import Heartbeat from '../../images/heartbeat.svg'
import './me.css'

const bpCalc = 0.00750062
const wtCalc = 0.00220462

class Me extends Component {
    constructor(props) {
        super(props)

        this.state = {
            bps: null,
            ws: null,
            tid: null,
            pid: null,
            headers: {
                AllergyIntolerance: { open: false, res: [], title: 'Allergies' },
                Encounter: { open: false, res: [], title: 'Encounters' },
                Condition: { open: false, res: [], title: 'Conditions/Problems' },
                MedicationStatement: { open: false, res: [], title: 'Medications' },
                Observation: { open: false, res: [], title: 'Observations/Vitals' },
                Immunization: { open: false, res: [], title: 'Immunizations' },
            },
            mbr: null,
            med: null,
            meds: [],
            plan: '10207VA0380003',
            plans: [],
            planDetails: null,
            fetching: false
        }
    }

    componentWillMount() {
        //return false
        let d = new Date()
        let mbrId = this.props.mbrId || '214'
        API.get('ecw', `/weight/868998031543974?start=${moment().subtract(100, 'days').format('x')}&stop=${Date.parse(d)}`)
            .then(resp => {
                let ws = Object.keys(resp).map(k => { return { ts: k, values: resp[k].values } })
                this.setState({ ws })
            })
        API.get('ecw', `/weight/868998034139143?start=${moment().subtract(100, 'days').format('x')}&stop=${Date.parse(d)}`)
            .then(resp => {
                let bps = Object.keys(resp).map(k => { return { ts: k, values: resp[k].values } })
                this.setState({ bps })
            })
        API.get('gwhpp', `/resource?res=fhirpatientsearch&id=${mbrId}&touchstone=${this.props.touchstone}`)
            .then(mbr => {
                this.setState({ mbr: mbr.data && mbr.data.entry && mbr.data.entry[0] && mbr.data.entry[0].resource ? mbr.data.entry[0].resource : null })
            })
    }

    componentDidMount() {
        this.getPlan()
        this.getPlans()
        this.handleGetFHIR()
        //return false
        const iid = setTimeout(() => {
            try {
                let d = new Date()
                API.get('ecw', `/weight/868998031543974?start=${moment().subtract(100, 'days').format('x')}&stop=${Date.parse(d)}`)
                    .then(resp => {
                        let ws = Object.keys(resp).map(k => { return { ts: k, values: resp[k].values } })
                        this.setState({ ws })
                    })
                API.get('ecw', `/weight/868998034139143?start=${moment().subtract(100, 'days').format('x')}&stop=${Date.parse(d)}`)
                    .then(resp => {
                        let bps = Object.keys(resp).map(k => { return { ts: k, values: resp[k].values } })
                        this.setState({ bps })
                    })
            } catch (e) {
                console.log(e.message)
            }
        }, 10000)
        this.setState({ iid })
    }

    componentWillUnmount() {
        clearTimeout(this.state.tid)
        clearTimeout(this.state.pid)
    }

    handlePlanChange(dir) {
        if(dir && this.state.plans && this.state.plans.length > 0) {
            let curr = this.state.plans.findIndex(p => p.id === this.state.plan)
            if(curr > -1) {
                if(dir === 'left') {
                    this.setState({plan: curr - 1 > -1 ? this.state.plans[curr - 1].id : this.state.plans[this.state.plans.length - 1].id})
                    this.getPlan(curr - 1 > -1 ? this.state.plans[curr - 1].id : this.state.plans[this.state.plans.length - 1].id)
                } else {
                    this.setState({plan: curr + 1 === this.state.plans.length ? this.state.plans[0].id : this.state.plans[curr + 1].id})
                    this.getPlan(curr + 1 === this.state.plans.length ? this.state.plans[0].id : this.state.plans[curr + 1].id)
                }
            }
        }
    }

    getPlans() {
        API.get('gwhpp', `/resource?res=formulary&action=plan`)
            .then(plans => {
                this.setState({ plans: plans.data && plans.data.entry && plans.data.entry[0] ? plans.data.entry.filter(p => p.resource && p.resource.identifier && p.resource.identifier[0] && p.resource.identifier[0].value && p.resource.title).map(f => ({plan: f.resource.title, id: f.resource.identifier[0].value})) : []})
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getPlan(plan) {
        console.log('plan:     ', plan)
        API.get('gwhpp', `/resource?res=formulary&action=plan&identifier=${plan || this.state.plan}`)
            .then(plan => {
                this.setState({ planDetails: plan.data && plan.data.entry && plan.data.entry[0] && plan.data.entry[0].resource ? plan.data.entry[0].resource : null })
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    getPlanType(plan) {
        let idx = plan.findIndex(p => p.url === 'drugTierID' && p.valueCodeableConcept && p.valueCodeableConcept.coding && p.valueCodeableConcept.coding[0])
        return idx > -1 ? plan[idx].valueCodeableConcept.coding.map(code => code.display || code.code || []).toString().replace(/,/g, ', ') : null
    }

    getMailOrder(plan) {
        let mailOrder = false
        let idx = plan.findIndex(p => p.url === 'mailOrder' && p.valueBoolean)
        if (idx > -1) mailOrder = plan[idx].valueBoolean || false
        return mailOrder
    }

    getCopay(plan) {
        let idx = plan.findIndex(p => p.url === 'copayAmount' && p.valueMoney && p.valueMoney.value >= 0)
        return idx > -1 ? `$${plan[idx].valueMoney.value}` : null
    }

    getCoins(plan) {
        let idx = plan.findIndex(p => p.url === 'coinsuranceRate' && p.valueDecimal >= 0)
        return idx > -1 ? `${plan[idx].valueDecimal * 100}%` : null
    }

    getCopayOpt(plan) {
        let idx = plan.findIndex(p => p.url === 'copayOption' && p.valueCodeableConcept && p.valueCodeableConcept.coding && p.valueCodeableConcept.coding[0])
        return idx > -1 ? plan[idx].valueCodeableConcept.coding.map(code => code.display || code.code || []).toString().replace(/,/g, ', ') : null
    }

    getCoinsOpt(plan) {
        let idx = plan.findIndex(p => p.url === 'coinsuranceOption' && p.valueCodeableConcept && p.valueCodeableConcept.coding && p.valueCodeableConcept.coding[0])
        return idx > -1 ? plan[idx].valueCodeableConcept.coding.map(code => code.display || code.code || []).toString().replace(/,/g, ', ') : null
    }

    getRxInfo(rx, val) {
        if (val && rx) {
            let s = ''
            switch (val) {
                case 'priorAuth':
                    s = 'http://hl7.org/fhir/us/Davinci-drug-formulary/StructureDefinition/usdf-PriorAuthorization-extension'
                    break
                case 'stepLimit':
                    s = 'http://hl7.org/fhir/us/Davinci-drug-formulary/StructureDefinition/usdf-StepTherapyLimit-extension'
                    break
                case 'quantityLimit':
                    s = 'http://hl7.org/fhir/us/Davinci-drug-formulary/StructureDefinition/usdf-QuantityLimit-extension'
                    break
                default:
                    s = null
            }
            let idx = rx && rx.extension && rx.extension[0] ? rx.extension.findIndex(ext => ext.url && ext.url === s) : -1
            return idx > -1 && rx.extension[idx].valueBoolean ? rx.extension[idx].valueBoolean || false : null
        }
    }

    getTier(rx) {
        let idx = rx && rx.extension && rx.extension[0] ? rx.extension.findIndex(ext => ext.url && ext.url === 'http://hl7.org/fhir/us/Davinci-drug-formulary/StructureDefinition/usdf-DrugTierID-extension') : -1
        return idx > -1 && rx.extension[idx].valueCodeableConcept && rx.extension[idx].valueCodeableConcept.coding && rx.extension[idx].valueCodeableConcept.coding[0] ? rx.extension[idx].valueCodeableConcept.coding.map(code => code.display || code.code || []).toString().replace(/,/g, ', ') : null
    }

    getPlanDetails() {
        return (
            this.state.planDetails ?
                <div className="Rx-Plan-Container">
                    <div className="Rx-Plan-Title">
                        {this.state.planDetails.title}
                        <div className="Rx-Plan-Title-Plans">
                            <div className="Rx-Plan-Selector" onClick={() => this.handlePlanChange('left')}><i className="fas fa-chevron-circle-left"></i></div>&nbsp;<div className="Rx-Plan-Selector" onClick={() => this.handlePlanChange('right')}><i className="fas fa-chevron-circle-right"></i></div>
                        </div>
                    </div>
                    {
                        this.state.planDetails.extension && this.state.planDetails.extension[0] ?
                            this.state.planDetails.extension.map((outer, outerId) => {
                                const pharm = outer.extension && outer.extension[0] ? outer.extension.filter(ext => ext.url === 'costSharing') : []
                                return (
                                    <div key={`plan-${outerId}`} className="">
                                        {outer.extension && outer.extension[0] ?
                                            <div className="Rx-Plan-Table-Container">
                                                <div className="Rx-Plan-Table-Title">{this.getPlanType(outer.extension)} {this.getMailOrder(outer.extension) ? 'Mail Order' : 'Go to the store'}</div>
                                                <div className="Rx-Plan-Table-Header">
                                                    <div className="Rx-Plan-Column-Header">Copayment</div>
                                                    <div className="Rx-Plan-Column-Header">Coinsurance</div>
                                                    <div className="Rx-Plan-Column-Header">Copayment Option</div>
                                                    <div className="Rx-Plan-Column-Header">Coinsurance Option</div>
                                                </div>
                                                {
                                                    pharm.map((rx, rxid) => {
                                                        return (
                                                            <div key={`rx-${outerId}-${rxid}`} className="Rx-Plan-Table-Row">
                                                                <div className="Rx-Plan-Table-Item">{this.getCopay(rx.extension)}</div>
                                                                <div className="Rx-Plan-Table-Item">{this.getCoins(rx.extension)}</div>
                                                                <div className="Rx-Plan-Table-Item">{this.getCopayOpt(rx.extension)}</div>
                                                                <div className="Rx-Plan-Table-Item">{this.getCoinsOpt(rx.extension)}</div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : null}
                                    </div>
                                )
                            }) : null
                    }
                </div>
                : null
        )
    }

    async handleGetFHIR() {
        let mbrId = this.props.mbrId || '214'
        Object.keys(this.state.headers).forEach(key => this.getFhirResource(key, mbrId))
    }

    async getFhirResource(res, mbrId) {
        try {
            const resp = await API.get('gwhpp', `/resource?res=fhirResource&resName=${res}&id=${mbrId}&touchstone=${this.props.touchstone}`)
            let header = { ...this.state.headers[res], res: resp.data && resp.data.entry ? resp.data.entry : [] }
            this.setState({ headers: { ...this.state.headers, [res]: header } })
        } catch (e) {
            console.log(e.message)
        }
    }

    getWeightGraph() {
        const dataWS = this.state.ws ? this.state.ws.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return 1
            if (x < y) return -1
            return 0
        }).map(v => parseInt(v.values.weight * wtCalc)) : []
        return (
            <div className="Dashboard-Card-Graph-Container-1">
                <div className="Dashboard-Details-Graph-Title">
                    <img src={Weight} alt="" className="Dashboard-Details-Graph-Title-Left" />
                    <div className="Dashboard-Details-Graph-Title-Left">Weight</div>
                    <div className="Dashboard-Details-Graph-Title-Right" style={{ color: '#fff' }}>{dataWS[0] ? `${dataWS[dataWS.length - 1]} lbs` : ''}</div>
                </div>
                {this.state.ws && <Line data={{
                    labels: this.state.ws && this.state.ws.map(v => moment.unix(v.ts / 1000).format('l')),
                    datasets: [
                        {
                            label: "Weight",
                            strokeColor: "#3771be",
                            pointColor: "#5399f4",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "#fff",
                            data: dataWS
                        }
                    ]
                }} width={250} height={200} options={{ datasetFill: false, scaleGridLineColor: '#fff', legend: { display: false } }}
                />}
            </div>
        )
    }

    getPulseGraph() {
        const dataP = this.state.bps ? this.state.bps.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return 1
            if (x < y) return -1
            return 0
        }).map(v => parseInt(v.values.pulse)) : []
        return (
            <div className="Dashboard-Card-Graph-Container-2">
                <div className="Dashboard-Details-Graph-Title">
                    <img src={Running} alt="" className="Dashboard-Details-Graph-Title-Left" />
                    <div className="Dashboard-Details-Graph-Title-Left">Heart Rate</div>
                    <div className="Dashboard-Details-Graph-Title-Right" style={{ color: '#fff' }}>{dataP[0] ? `${dataP[dataP.length - 1]} bpm` : ''}</div>
                </div>
                {this.state.bps && <Bar data={{
                    labels: this.state.bps && this.state.bps.map(v => moment.unix(v.ts / 1000).format('l')),
                    datasets: [
                        {
                            label: "Pulse",
                            strokeColor: "#3771be",
                            fillColor: "#82defa",
                            HighlightFill: "#fff",
                            HighlightStroke: "#fff",
                            data: dataP
                        }
                    ]
                }} width={250} height={200} options={{ datasetFill: false, scaleGridLineColor: '#fff', scales: { yAxes: [{ ticks: { suggestedMin: 50 } }] }, legend: { display: false } }}
                />}
            </div>
        )
    }

    getBPGraph() {
        const dataS = this.state.bps && this.state.bps[0] ? this.state.bps.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return -1
            if (x < y) return 1
            return 0
        }).map(v => parseInt(v.values.systolic * bpCalc)) : []
        const dataD = this.state.bps && this.state.bps[0] ? this.state.bps.sort((a, b) => {
            let x = a.ts
            let y = b.ts
            if (x > y) return -1
            if (x < y) return 1
            return 0
        }).map(v => parseInt(v.values.diastolic * bpCalc)) : []

        return (
            <div className="Dashboard-Card-Graph-Container-3">
                <div className="Dashboard-Details-Graph-Title">
                    <img src={Heartbeat} alt="" className="Dashboard-Details-Graph-Title-Left" />
                    <div className="Dashboard-Details-Graph-Title-Left">BP</div>
                    <div className="Dashboard-Details-Graph-Title-Right" style={{ color: '#fff' }}>{dataS[0] && dataD[0] ? `${dataS[dataS.length - 1]}/${dataD[dataD.length - 1]} mmHg` : ''}</div>
                </div>
                {this.state.bps && <Line data={{
                    labels: this.state.bps && this.state.bps.map(v => moment.unix(v.ts / 1000).format('l')),
                    datasets: [
                        {
                            label: "Systolic",
                            strokeColor: "#41c2e9",
                            pointColor: "#5399f4",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "#fff",
                            data: dataS
                        },
                        {
                            label: "Diastolic",
                            strokeColor: "#82defa",
                            pointColor: "#5399f4",
                            pointStrokeColor: "#fff",
                            pointHighlightFill: "#fff",
                            pointHighlightStroke: "#fff",
                            data: dataD
                        }
                    ]
                }} width={250} height={200} options={{ datasetFill: false, scaleGridLineColor: '#fff', legend: { display: false } }}
                />}
            </div>
        )
    }

    getNamefromResource(name) {
        return name && name[0] ? (name[0].text ? name[0].text : `${name[0].given.toString().replace(',', ' ')} ${name[0].family}`) : 'Undefined'
    }

    getDemographics() {
        const pat = this.state.mbr ? this.state.mbr : null
        return (
            pat ?
                <div className="Me-Demographics-Container">
                    <div className="Member-Header-Column-Group">
                        <div className="Member-Header-Column">
                            <div className="FHIR-Resource-Initial-Container">
                                <div className="FHIR-Resource-Initial">{this.getNamefromResource(pat.name).split(' ').map(n => n[0]).join("")}</div>
                            </div>
                        </div>
                        <div className="Member-Header-Column">
                            <div className="Member-Resource-Section-Value">{this.getNamefromResource(pat.name)}</div>
                            <div className="Member-Resource-Section-Value"><i className="far fa-id-card"></i> {pat.id || 'Undefined'}</div>
                            <div className={`Member-Resource-Section-Value ${pat.active ? 'Member-Active' : 'Member-Inactive'}`}><i className="fas fa-circle"></i>{pat.active ? 'ACTIVE' : 'INACTIVE'}</div>
                        </div>
                    </div>
                    <div className="Member-Header-Column">
                        {
                            pat.telecom && pat.telecom.length > 0 && pat.telecom.map((t, tid) => {
                                return (
                                    <div key={`t-${tid}`} className="FHIR-Resource-Section">
                                        <div className="Member-Resource-Section-Value">{t.use && t.use === 'mobile' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {t.value ? t.value : 'Not Listed'}</div>
                                    </div>
                                )
                            })
                        }
                        {
                            pat.address && pat.address[0] ?
                                (
                                    pat.address[0].text ?
                                        <div className="Member-Resource-Section-Value"><i className="fas fa-map-marker-alt"></i> {pat.address[0].text}</div> :
                                        <div className="Member-Resource-Section-Value"><i className="fas fa-map-marker-alt"></i> {`${pat.address[0].line ? pat.address[0].line.toString().replace(/,/g, ', ') + ', ' : ''}${pat.address[0].city ? pat.address[0].city + ', ' : ''}${pat.address[0].state ? pat.address[0].state + ', ' : ''}${pat.address[0].postalCode ? pat.address[0].postalCode + ', ' : ''}${pat.address[0].country ? pat.address[0].country + ', ' : ''}`}</div>
                                ) : <div className="Member-Resource-Section-Value">Unlisted</div>
                        }
                    </div>
                    <div className="Member-Header-Column">
                        <div className="Member-Resource-Section-Value"><i className="fas fa-birthday-cake"></i> {pat.birthDate || 'Undefined'}</div>
                        <div className="Member-Resource-Section-Value"><i className="fas fa-female"></i><i className="fas fa-male"></i> {pat.gender ? pat.gender.toUpperCase() : 'Undefined'}</div>
                    </div>
                </div> : null
        )
    }

    getResource(res, resType) {
        switch (resType) {
            case 'AllergyIntolerance':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Allergy
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Category</div>
                                <div className="Resource-Card-Value">{res.category ? res.category.toString().replace(/,/g, ', ') : null}</div>
                                <div className="Resource-Card-Label">Criticality</div>
                                <div className="Resource-Card-Value">{res.criticality ? res.criticality : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.clinicalStatus && res.clinicalStatus.coding && res.clinicalStatus.coding[0] ? res.clinicalStatus.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                                <div className="Resource-Card-Label">Verification</div>
                                <div className="Resource-Card-Value">{res.verificationStatus && res.verificationStatus.coding ? res.verificationStatus.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Allergy</div>
                                <div className="Resource-Card-Value">{res.code && res.code.coding && res.code.coding[0] ? res.code.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Substance</div>
                                <div className="Resource-Card-Value">{res.reaction && res.reaction[0] ? res.reaction.map(r => r.substance && r.substance.coding && r.substance.coding[0] ? r.substance.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Reaction</div>
                                <div className="Resource-Card-Value">{res.reaction && res.reaction[0] ? res.reaction.map(r => r.manifestation && r.manifestation[0] ? r.manifestation.map(m => m.coding && m.coding[0] ? m.coding.map(code => code.display || '').toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Onset Date</div>
                                <div className="Resource-Card-Value">{res.onsetDateTime ? moment(res.onsetDateTime).format('lll') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Recorded Date</div>
                                <div className="Resource-Card-Value">{res.recordedDate ? moment(res.recordedDate).format('lll') : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Encounter':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Encounter
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Class</div>
                                <div className="Resource-Card-Value">{res.class && res.class.display ? res.class.display : (res.class && res.class.code ? res.class.code : null)}</div>
                                <div className="Resource-Card-Label">Priority</div>
                                <div className="Resource-Card-Value">{res.priority && res.priority.coding ? res.priority.coding.map(code => code.display ? code.display : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Type</div>
                                <div className="Resource-Card-Value">{res.type && res.type[0] ? res.type.map(t => t.coding && t.coding[0] ? t.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Start</div>
                                <div className="Resource-Card-Value">{res.period && res.period.start ? moment(res.period.start).format('ll') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">End</div>
                                <div className="Resource-Card-Value">{res.period && res.period.end ? moment(res.period.end).format('ll') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Reason</div>
                                <div className="Resource-Card-Value">{res.reasonCode && res.reasonCode[0] ? res.reasonCode.map(r => r.coding && r.coding[0] ? r.coding.map(code => code.display || code.text || code.code || code.text || []).toString().replace(/,/g, ', ') : (r.text ? r.text : [])).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Participant</div>
                                <div className="Resource-Card-Value">{res.participant && res.participant[0] ? res.participant.map(p => Object.keys(p).map(key => `${p[key].display} (${key})`)).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Provider</div>
                                <div className="Resource-Card-Value">{res.serviceProvider && (res.serviceProvider.display || res.serviceProvider.text) ? res.serviceProvider.display || res.serviceProvider.text : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Condition':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Condition/Problem
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Category</div>
                                <div className="Resource-Card-Value">{res.category && res.category[0] ? res.category.map(t => t.coding && t.coding[0] ? t.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                                <div className="Resource-Card-Label">Severity</div>
                                <div className="Resource-Card-Value">{res.severity && res.severity.coding && res.severity.coding[0] ? res.severity.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Verification</div>
                                <div className="Resource-Card-Value">{res.verificationStatus && res.verificationStatus.coding ? res.verificationStatus.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Code</div>
                                <div className="Resource-Card-Value">{res.code && res.code.coding && res.code.coding[0] ? res.code.coding.map(code => code.display || code.code || '').toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Onset Date</div>
                                <div className="Resource-Card-Value">{res.onsetDateTime ? moment(res.onsetDateTime).format('ll') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Abatement</div>
                                <div className="Resource-Card-Value">{res.abatementString ? res.abatementString : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Recorded Date</div>
                                <div className="Resource-Card-Value">{res.recordedDate ? moment(res.recordedDate).format('ll') : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'MedicationKnowledge':
                return (
                    <div className="Me-Med-Card">
                        <div className="Me-Med-Title">
                            {res.code.coding.map(code => code.display || code.code || []).toString().replace(/,/g, ', ')}
                        </div>
                        <div className="Me-Med-Row">
                            <div className="Resource-Card-Item-Left" style={{width: '75px'}}>
                                <div className="Me-Med-Item-Value">
                                    {this.getRxInfo(res, 'priorAuth') ? <i className="fas fa-user-lock"></i> : null}
                                </div>
                            </div>
                            <div className="Resource-Card-Item-Left">
                                <div className="Me-Med-Item-Value">
                                {this.getRxInfo(res, 'stepLimit') ? <i className="fas fa-sort-amount-up"></i> : null }
                                </div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Me-Med-Item-Value">
                                {this.getRxInfo(res, 'quantityLimit') ? <i className="fas fa-weight"></i> : null}
                                </div>
                            </div>
                        </div>
                        <div className="Me-Med-Row">
                            <div className="Me-Med-Item">
                                <div className="Me-Med-Item-Title">
                                    Tier
                                </div>
                                <div className="Me-Med-Item-Value">
                                    {this.getTier(res)}
                                </div>
                            </div>                            
                        </div>
                    </div>
                )
            case 'MedicationStatement':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Medication
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Medication</div>
                                <div className="Resource-Card-Value" style={{ cursor: 'pointer' }} onClick={() => this.handleMedClick(res.medicationCodeableConcept && res.medicationCodeableConcept.text ? res.medicationCodeableConcept.text : (res.medicationCodeableConcept && res.medicationCodeableConcept.coding && res.medicationCodeableConcept.coding[0] ? res.medicationCodeableConcept.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null))}>{res.medicationCodeableConcept && res.medicationCodeableConcept.text ? res.medicationCodeableConcept.text : (res.medicationCodeableConcept && res.medicationCodeableConcept.coding && res.medicationCodeableConcept.coding[0] ? res.medicationCodeableConcept.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null)}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Start</div>
                                <div className="Resource-Card-Value">{res.effectivePeriod && res.effectivePeriod.start ? moment(res.effectivePeriod.start).format('ll') : (res.effectiveDateTime ? moment(res.effectiveDateTime).format('ll') : null)}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">End</div>
                                <div className="Resource-Card-Value">{res.effectivePeriod && res.effectivePeriod.end ? moment(res.effectivePeriod.end).format('ll') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Notes</div>
                                <div className="Resource-Card-Value">{res.note && res.note[0] ? res.note.map(n => n.text ? n.text : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Reason</div>
                                <div className="Resource-Card-Value">{res.reasonCode && res.reasonCode[0] ? res.reasonCode.map(rc => rc.coding && rc.coding[0] ? rc.coding.map(code => code.display || code.code || []) : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                    </div>
                )
            case 'Observation':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Observations/Vitals
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Category</div>
                                <div className="Resource-Card-Value">{res.category && res.category[0] ? res.category.map(cat => cat.coding && cat.coding[0] ? cat.coding.map(code => code.display || code.text || code.code || []) : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : (res.active ? 'Active' : null)}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Measurement Type</div>
                                <div className="Resource-Card-Value">{res.code && res.code.coding && res.code.coding[0] ? res.code.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Measurement</div>
                                <div className="Resource-Card-Value">{res.component && res.component[0] ? res.component.map(c => this.getMeasurement(c)).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Reference</div>
                                <div className="Resource-Card-Value">
                                    {
                                        res.referenceRange && res.referenceRange[0] ? res.referenceRange.map((rr, rridx) => {
                                            return (

                                                Object.keys(rr) ? Object.keys(rr).map((r, ridx) => {
                                                    return (
                                                        <div key={`rr-${rridx}-${ridx}`}>{`${r}: ${rr[r] && rr[r].value ? rr[r].value : null} ${rr[r] && rr[r].unit ? rr[r].unit : null}`}</div>
                                                    )
                                                }) : null

                                            )
                                        }) : null
                                    }
                                </div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Interpretation</div>
                                <div className="Resource-Card-Value">{res.interpretation && res.interpretation[0] ? res.interpretation.map(t => t.coding && t.coding[0] ? t.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Period</div>
                                <div className="Resource-Card-Value">
                                    {
                                        res.effectivePeriod && Object.keys(res.effectivePeriod) ? Object.keys(res.effectivePeriod).map((ep, epidx) => {
                                            return (
                                                <div key={`ep-${epidx}`}>{`${ep}: ${moment(res.effectivePeriod[ep]).format('ll')}`}</div>
                                            )
                                        }) : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case 'Immunization':
                return (
                    <div className="Resource-Card">
                        <div className="Resource-Card-Title">
                            Immunizations
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Vaccine</div>
                                <div className="Resource-Card-Value">{res.vaccineCode ? (res.vaccineCode.text ? res.vaccineCode.text : (res.vaccineCode.coding && res.vaccineCode.coding[0] ? res.vaccineCode.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null)) : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Status</div>
                                <div className="Resource-Card-Value">{res.status ? res.status : (res.active ? 'Active' : null)}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Primary Source</div>
                                <div className="Resource-Card-Value">{res.primarySource ? res.primarySource : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Lot</div>
                                <div className="Resource-Card-Value">{res.lotNumber ? res.lotNumber : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Reason</div>
                                <div className="Resource-Card-Value">{res.reasonCode && res.reasonCode[0] ? res.reasonCode.map(rc => rc.coding && rc.coding[0] ? rc.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Dose</div>
                                <div className="Resource-Card-Value">{res.doseQuantity ? `${res.doseQuantity.value ? res.doseQuantity.value : null}${res.doseQuantity.value && res.doseQuantity.code ? ' ' + res.doseQuantity.code : null}` : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Site</div>
                                <div className="Resource-Card-Value">{res.site && res.site.coding && res.site.coding[0] ? res.site.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Route</div>
                                <div className="Resource-Card-Value">{res.route && res.route.coding && res.route.coding[0] ? res.route.coding.map(code => code.display || code.text || code.code || []).toString().replace(/,/g, ', ') : null}</div>
                            </div>
                        </div>
                        <div className="Resource-Card-Row">
                            <div className="Resource-Card-Item-Left">
                                <div className="Resource-Card-Label">Occurence Date</div>
                                <div className="Resource-Card-Value">{res.occurrenceDataTime ? moment(res.occurrenceDataTime).format('ll') : null}</div>
                            </div>
                            <div className="Resource-Card-Item-Right">
                                <div className="Resource-Card-Label">Expiration Date</div>
                                <div className="Resource-Card-Value">{res.expirationDate ? moment(res.expirationDate).format('ll') : null}</div>
                            </div>
                        </div>
                    </div>
                )
            default:
                return <div className="Member-Table-Body"><pre>{JSON.stringify(res, undefined, 2)}</pre></div>
        }
    }

    getMeasurement(m) {
        let code = m.code && m.code.coding && m.code.coding[0] ? m.code.coding[0].display || m.code.coding[0].text || m.code.coding[0].code || null : null
        let val = m.valueQuantity ? `${m.valueQuantity.value ? m.valueQuantity.value : ''} ${m.valueQuantity.unit || m.valueQuantity.code || ''}` : null
        let interp = m.interpretation && m.interpretation[0] ? m.interpretation.map(i => i.coding && i.coding[0] ? i.coding.map(c => c.display || c.text || c.code || []) : []).toString().replace(/,/g, ', ') : null
        return `${code ? code + ': ' : ''}${val || ''} ${interp ? `(${interp})` : ''}`
    }

    handleMedClick(med) {
        if (med === this.state.med) {
            this.setState({ med: null, meds: [] })
        } else {
            this.setState({ med })
            API.get('gwhpp', `/resource?res=formulary&action=med&name=${med.split(' ')[0]}&identifier=${this.state.plan}&touchstone=${this.props.touchstone}`)
                .then(m => {
                    console.log(m.data)
                    this.setState({ fetching: false, meds: m.data && m.data.entry && m.data.entry[0] ? m.data.entry.map(rx => rx.resource || null) : null })
                })
                .catch(err => {
                    this.setState({ fetching: false, meds: [] })
                    console.log(err.message)
                })
        }
    }

    getMed() {
        return (
            <div className="Me-Med">
                {
                    this.state.meds && this.state.meds[0] && this.state.meds.map((med, medidx) => med.resourceType ?
                        <div key={`medrx-${medidx}`} className="Me-Med-List">{this.getResource(med, med.resourceType)}</div> : null)
                }
            </div>
        )
    }

    render() {
        console.log(this.state, this.props)
        return (
            <div className="Me-Container">
                <div className="Me-Section">
                    <div className="Me-Section-Header">
                        <img src={IDCard} alt="" />
                        Personal Information
                    </div>
                    <div className="Me-Section-Body">
                        {this.getDemographics()}
                    </div>
                </div>
                <div className="Me-Section">
                    <div className="Me-Section-Header">
                        <i className="fas fa-chart-line"></i>
                        My Trackers
                    </div>
                    <div className="Me-Section-Body">
                        <div className="Me-Section-Graphs">
                            {this.getWeightGraph()}
                            {this.getBPGraph()}
                            {this.getPulseGraph()}
                        </div>
                    </div>
                </div>
                <div className="Me-Section">
                    <div className="Me-Section-Header">
                        <i className="fas fa-prescription-bottle-alt"></i>
                        Pharmacy Plan
                    </div>
                    <div className="Me-Section-Row">
                        {this.getPlanDetails()}
                    </div>
                </div>
                <div className="Me-Section">
                    <div className="Me-Section-Header">
                        <i className="fas fa-prescription-bottle"></i>
                        Medications
                    </div>
                    <div className="Me-Section-Row Me-Med">
                        {this.getMed()}
                    </div>
                    {
                        this.state.headers.MedicationStatement.res && this.state.headers.MedicationStatement.res[0] ?
                            <div className="Me-Section-Body">
                                <div className="Me-Section-Graphs">
                                    {this.state.headers.MedicationStatement.res.map((res, ridx) => {
                                        return <div className="Me-Section-Graphs" key={`med-${ridx}`}>{this.getResource(res.resource, 'MedicationStatement')}</div>
                                    })}
                                </div>
                            </div> : null
                    }
                </div>
                {
                    this.state.headers.AllergyIntolerance.res && this.state.headers.AllergyIntolerance.res[0] ?
                        <div className="Me-Section">
                            <div className="Me-Section-Header">
                                <i className="fas fa-allergies"></i>
                                Allergies
                    </div>
                            <div className="Me-Section-Body">
                                <div className="Me-Section-Graphs">
                                    {this.state.headers.AllergyIntolerance.res.map((res, ridx) => {
                                        return <div className="Me-Section-Graphs" key={`med-${ridx}`}>{this.getResource(res.resource, 'AllergyIntolerance')}</div>
                                    })}
                                </div>
                            </div>
                        </div> : null
                }
                {
                    this.state.headers.Immunization.res && this.state.headers.Immunization.res[0] ?
                        <div className="Me-Section">
                            <div className="Me-Section-Header">
                                <i className="fas fa-syringe"></i>
                                Immunizations
                    </div>
                            <div className="Me-Section-Body">
                                <div className="Me-Section-Graphs">
                                    {this.state.headers.Immunization.res.map((res, ridx) => {
                                        return <div className="Me-Section-Graphs" key={`med-${ridx}`}>{this.getResource(res.resource, 'Immunization')}</div>
                                    })}
                                </div>
                            </div>
                        </div> : null
                }
                {
                    this.state.headers.Encounter.res && this.state.headers.Encounter.res[0] ?
                        <div className="Me-Section">
                            <div className="Me-Section-Header">
                                <i className="fas fa-hospital"></i>
                                Visits
                    </div>
                            <div className="Me-Section-Body">
                                <div className="Me-Section-Graphs">
                                    {this.state.headers.Encounter.res.map((res, ridx) => {
                                        return <div className="Me-Section-Graphs" key={`med-${ridx}`}>{this.getResource(res.resource, 'Encounter')}</div>
                                    })}
                                </div>
                            </div>
                        </div> : null
                }
                {
                    this.state.headers.Condition.res && this.state.headers.Condition.res[0] ?
                        <div className="Me-Section">
                            <div className="Me-Section-Header">
                                <img src={MedInfo} alt="" />
                                Conditions/Problems
                    </div>
                            <div className="Me-Section-Body">
                                <div className="Me-Section-Graphs">
                                    {this.state.headers.Condition.res.map((res, ridx) => {
                                        return <div className="Me-Section-Graphs" key={`med-${ridx}`}>{this.getResource(res.resource, 'Condition')}</div>
                                    })}
                                </div>
                            </div>
                        </div> : null
                }
                {
                    this.state.headers.Observation.res && this.state.headers.Observation.res[0] ?
                        <div className="Me-Section">
                            <div className="Me-Section-Header">
                                <img src={MyInfo} alt="" />
                                Observations/Vitals
                    </div>
                            <div className="Me-Section-Body">
                                <div className="Me-Section-Graphs">
                                    {this.state.headers.Observation.res.map((res, ridx) => {
                                        return <div className="Me-Section-Graphs" key={`med-${ridx}`}>{this.getResource(res.resource, 'Observation')}</div>
                                    })}
                                </div>
                            </div>
                        </div> : null
                }
            </div>
        )
    }
}

export default Me
