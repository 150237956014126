import React, { Component } from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'
import './mrp.css'

class SearchMRP extends Component {
    constructor(props) {
        super(props)

        this.state = {
            results: [],
            isLoading: false,
            displaySelections: false,
            searchSelection: null,
            search: null
        }
    }

    handleSubmit = async event => {
        event.preventDefault()
        this.setState({ isLoading: true })
        let qs = null
        if (this.state.searchSelection) {
            qs = `${this.state.searchSelection.toLowerCase()}=${this.state.search}`
        } else {
            qs = !this.state.searchSelection ? `practitioner=${this.state.search}&patient=${this.state.search}&location=${this.state.search}&organization=${this.state.search}` : null
        }
        try {
            const results = await API.get('mrp', `/searchMRP?${qs}`)
            this.setState({ results, isLoading: false })
        } catch (e) {
            alert(e.message)
            this.setState({ isLoading: false })
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSelector(sel) {
        this.setState({ displaySelections: sel ? sel : !this.state.displaySelections })
    }

    handleEnter() {
        if (!this.state.search) this.setState({ search: '' })
    }

    handleSelection(sel) {
        this.setState({ displaySelections: false, searchSelection: sel })
    }

    handleSearchTextChange(event) {
        this.setState({ displaySelections: false, search: event.target.value.length > 0 ? event.target.value : null })
    }

    getFormattedResource(param, pidx, rid) {
        const res = param.resource
        if (res) {
            switch (res.resourceType) {
                case 'Encounter':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-ambulance"></i> Encounter</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status ? res.status.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Code</div>
                            <div className="MRP-Result-Section-Value">{res.class && res.class.display ? res.class.display.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Type</div>
                            <div className="MRP-Result-Section-Value">{res.type && res.type[0] && res.type[0].text ? res.type[0].text : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Patient</div>
                            <div className="MRP-Result-Section-Value">{res.subject && res.subject.display ? res.subject.display : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Date</div>
                            <div className="MRP-Result-Section-Value">{res.period && res.period.start ? moment(res.period.start).format('LLL') : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Reason</div>
                            <div className="MRP-Result-Section-Value">{res.reasonCode && res.reasonCode[0] && res.reasonCode[0].text ? res.reasonCode[0].text : 'Not Listed'}</div>
                            <div className="MRP-Result-Section-Label">Diagnosis</div>
                            <div className="MRP-Result-Section-Value">{res.diagnosis && res.diagnosis[0] && res.diagnosis[0].condition && res.diagnosis[0].condition.display ? res.diagnosis[0].condition.display : 'Not Listed'}</div>
                        </div>
                    )
                case 'Location':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-map-marker-alt"></i> Location</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status && res.status.toLowerCase() === 'active' ? <i className="far fa-check-circle"></i> : <i className="fas fa-exclamation-circle"></i>} {res.status ? res.status.toUpperCase() : 'Unavailable'}</div>
                            <div className="MRP-Result-Section-Label">Name</div>
                            <div className="MRP-Result-Section-Value">{res.name ? res.name : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Description</div>
                            <div className="MRP-Result-Section-Value">{res.description ? res.description : 'Undefined'}</div>
                            {
                                res.telecom && res.telecom.length > 0 && res.telecom.map((t, tid) => {
                                    return (
                                        <div key={`t-${rid}-${pidx}-${tid}`}>
                                            <div className="MRP-Result-Section-Label">{t.use && t.use === 'home' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {`${t.use ? t.use.toUpperCase() : 'Phone'}/${t.system ? t.system.toUpperCase() : 'Phone'}`}</div>
                                            <div className="MRP-Result-Section-Value">{t.value ? t.value : 'Undefined'}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className="MRP-Result-Section-Label"><i className="fas fa-map-marker-alt"></i> Address</div>
                            {
                                res.address && res.address && res.address.text ? res.address.text.split(", ").map((addr, aidx) => {
                                    return <div key={`patAddr-${rid}-${pidx}-${aidx}`} className="MRP-Result-Section-Value">{addr}</div>
                                }) : <div className="MRP-Result-Section-Value">Unlisted</div>
                            }
                        </div>
                    )
                case 'MeasureReport':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-clipboard-check"></i> Report</div>
                            <div className="MRP-Result-Section-Label">Type</div>
                            <div className="MRP-Result-Section-Value">{res.type ? res.type.toUpperCase() : 'Unavailable'}</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status && res.status.toLowerCase() === 'complete' ? <i className="far fa-check-circle"></i> : <i className="fas fa-exclamation-circle"></i>} {res.status ? res.status.toUpperCase() : 'Unavailable'}</div>
                        </div>
                    )
                case 'Organization':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="far fa-hosptial"></i> Organization</div>
                            <div className="MRP-Result-Section-Label">{res.identifier && res.identifier[0] && res.identifier[0].type && res.identifier[0].type.text ? res.identifier[0].type.text : 'Identifier'}</div>
                            <div className="MRP-Result-Section-Value">{res.identifier && res.identifier[0] && res.identifier[0].value ? res.identifier[0].value : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Active</div>
                            <div className="MRP-Result-Section-Value">{res.active ? 'Yes' : 'No'}</div>
                            <div className="MRP-Result-Section-Label">Name</div>
                            <div className="MRP-Result-Section-Value">{res.name ? res.name : 'Undefined'}</div>
                            {
                                res.telecom && res.telecom.length > 0 && res.telecom.map((t, tid) => {
                                    return (
                                        <div key={`t-${rid}-${pidx}-${tid}`}>
                                            <div className="MRP-Result-Section-Label">{t.use && t.use === 'home' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {`${t.use ? t.use.toUpperCase() : 'Phone'}/${t.system ? t.system.toUpperCase() : 'Phone'}`}</div>
                                            <div className="MRP-Result-Section-Value">{t.value ? t.value : 'Undefined'}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className="MRP-Result-Section-Label"><i className="fas fa-map-marker-alt"></i> Address</div>
                            {
                                res.address && res.address[0] && res.address[0].text ? res.address[0].text.split(", ").map((addr, aidx) => {
                                    return <div key={`patAddr-${rid}-${pidx}-${aidx}`} className="MRP-Result-Section-Value">{addr}</div>
                                }) : <div className="MRP-Result-Section-Value">Unlisted</div>
                            }
                        </div>
                    )
                case 'Patient':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-procedures"></i> Patient</div>
                            <div className="MRP-Result-Section-Label">ID</div>
                            <div className="MRP-Result-Section-Value">{res.id || 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.active ? 'ACTIVE' : 'INACTIVE'}</div>
                            <div className="MRP-Result-Section-Label">Name</div>
                            <div className="MRP-Result-Section-Value">{res.name && res.name[0] && res.name[0].text ? res.name[0].text : 'Undefined'}</div>
                            {
                                res.telecom && res.telecom.length > 0 && res.telecom.map((t, tid) => {
                                    return (
                                        <div key={`t-${rid}-${pidx}-${tid}`}>
                                            <div className="MRP-Result-Section-Label">{t.use && t.use === 'home' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {`${t.use ? t.use.toUpperCase() : 'Phone'}/${t.system ? t.system.toUpperCase() : 'Phone'}`}</div>
                                            <div className="MRP-Result-Section-Value">{t.value ? t.value : 'Undefined'}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className="MRP-Result-Section-Label"><i className="fas fa-female"></i><i className="fas fa-male"></i> Gender</div>
                            <div className="MRP-Result-Section-Value">{res.gender ? res.gender.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label"><i className="fas fa-birthday-cake"></i> D.O.B.</div>
                            <div className="MRP-Result-Section-Value">{res.birthDate || 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Deceased</div>
                            <div className="MRP-Result-Section-Value">{res.deceasedBoolean ? 'Yes' : 'No'}</div>
                            <div className="MRP-Result-Section-Label"><i className="fas fa-map-marker-alt"></i> Address</div>
                            {
                                res.address && res.address[0] && res.address[0].text ? res.address[0].text.split(", ").map((addr, aidx) => {
                                    return <div key={`patAddr-${rid}-${pidx}-${aidx}`} className="MRP-Result-Section-Value">{addr}</div>
                                }) : <div className="MRP-Result-Section-Value">Unlisted</div>
                            }
                            <div className="MRP-Result-Section-Label"><i className="fas fa-user-md"></i> Practitioner</div>
                            <div className="MRP-Result-Section-Value">{res.generalPractitioner && res.generalPractitioner[0] && res.generalPractitioner[0].display ? res.generalPractitioner[0].display : 'Undefined'}</div>
                        </div>

                    )
                case 'Practitioner':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-user-md"></i> Provider</div>
                            <div className="MRP-Result-Section-Label">NPI</div>
                            <div className="MRP-Result-Section-Value">{res.identifier && res.identifier[0] && res.identifier[0].value ? res.identifier[0].value : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Name</div>
                            <div className="MRP-Result-Section-Value">{res.name && res.name[0] && res.name[0].text ? res.name[0].text : 'Undefined'}</div>
                        </div>
                    )
                case 'Task':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-clipboard-list"></i> Task</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status && res.status.toLowerCase() === 'accepted' ? <i className="far fa-check-circle"></i> : <i className="fas fa-exclamation-circle"></i>} {res.status ? res.status.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Intent</div>
                            <div className="MRP-Result-Section-Value">{res.intent ? res.intent.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Priority</div>
                            <div className="MRP-Result-Section-Value">{res.priority ? res.priority.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">CPT Reason</div>
                            <div className="MRP-Result-Section-Value">{res.code && res.code.text ? res.code.text : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Authored On</div>
                            <div className="MRP-Result-Section-Value">{res.authoredOn ? moment(res.authoredOn).format('LLL') : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Start-End</div>
                            <div className="MRP-Result-Section-Value">{`${res.executionPeriod && res.executionPeriod.start ? moment(res.executionPeriod.start).format('L') : 'Undefined'}-${res.executionPeriod && res.executionPeriod.end ? moment(res.executionPeriod.end).format('L') : 'Undefined'}`}</div>
                        </div>
                    )
                default:
                    return null
            }
        }        
        return null
    }

    getMRP() {
        return (
            <div className="MRP-Results">
                <div className="MRP-Results-List">
                    {
                        this.state.results.sort((a, b) => {
                            let x = a.id
                            let y = b.id
                            if (x > y) return -1
                            if (x < y) return 1
                            return 0
                        }).map((result, rid) => {
                            const measrpt = result.parameter ? result.parameter.filter(param => param.name.toLowerCase() === 'measurereport') : []
                            console.log('measrpt    ', measrpt)
                            return (
                                <div key={`result-${rid}`} className="MRP-Result">
                                    <div className="MRP-Result-Title">
                                        {result.id}
                                        <div className="MRP-Result-Date">
                                            {result.ts}
                                        </div>
                                    </div>
                                    <div className="MRP-Result-Row">
                                        {
                                            result.parameter.map((param, pidx, rid) => {
                                                return this.getFormattedResource(param, pidx)
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    render() {
        console.log(this.state)
        return (
            <div className="MRP">
                <div className="MRP-Row">
                    <div className="MRP-Search-Container">
                        <form onSubmit={event => this.handleSubmit(event)}>
                            <div className="MRP-Row MRP-Search-Label">Smart Search{this.state.isLoading && <i className="fas fa-spinner"></i>}</div>
                            <div className="MRP-Row MRP-Search-Outter">
                                <div className="MRP-Search-Selector" onClick={() => this.handleSelector()}>
                                    {this.state.searchSelection ? this.state.searchSelection : 'All Data'}
                                    <div className="MRP-Search-Selector-Arrow"></div>
                                </div>
                                <input type="text" onChange={event => this.handleSearchTextChange(event)} className="MRP-Search-Bar" placeholder={`Search by ${this.state.searchSelection ? this.state.searchSelection : "Provider (Name/ID), Patient (Name/ID), Org(Name/Tax ID) or Location"}`}></input>
                                <div className="MRP-Search-Button" onClick={event => this.handleSubmit(event)}><i className="fas fa-search"></i></div>
                            </div>
                            {
                                this.state.displaySelections &&
                                <div className="MRP-Search-Selections">
                                    <div className="MRP-Search-Selections-Row" onClick={() => this.handleSelection()}>All Data</div>
                                    <div className="MRP-Search-Selections-Row" onClick={() => this.handleSelection('Patient')}>Patient</div>
                                    <div className="MRP-Search-Selections-Row" onClick={() => this.handleSelection('Practitioner')}>Provider</div>
                                    <div className="MRP-Search-Selections-Row" onClick={() => this.handleSelection('Organization')}>Organization</div>
                                    <div className="MRP-Search-Selections-Row" onClick={() => this.handleSelection('Location')}>Location</div>
                                </div>
                            }
                        </form>
                    </div>
                </div>
                <div className="MRP-Row">
                    {this.getMRP()}
                </div>
            </div>
        )
    }
}

export default SearchMRP
