import React, { Component } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker';
import { API } from 'aws-amplify'
import FB from '../../images/FB_logo.png'
import PrettyCBGRB from '../../components/fields/prettyCbgrb'
import PrettyTB from '../../components/fields/prettyTb'
import './athena.css'

class Athena extends Component {
    constructor(props) {
        super(props)

        this.state = {
            providers: [],
            patient: null,
            departments: [],
            displaySearchForm: false,
            displayNewSearchForm: true,
            searchResults: null,
            searchResultsx: [
                {
                    "name": "Dr.KRISTOFER BERNHAR",
                    "lastname": "Bernhard",
                    "firstname": "Kristofer",
                    "providertype": "MD",
                    "npi": null,
                    "distance": { "text": "100 mi" },
                    "specialty": [
                        "Group",
                        "Single Specialty"
                    ],
                    "url": "1959-1.portal.athenahealth.com",
                    "address": {
                        "streetAddress": "501 Broad St, Rome, GA 30161, USA",
                        "zip": "30161",
                        "city": "Rome",
                        "state": "GA"
                    },
                    "facility": {
                        "name": "Cruickshank HEALTH CARE",
                        "department": "Rome Office - this is a test to see how long this field actually is. 75 ch",
                        "providerGroup": "7 Hills Medical Group"
                    },
                    "altLocations": [
                        {
                            "alt": {
                                "address": {
                                    "streetAddress": "61 W Main St, Cartersville, GA 30120, USA",
                                    "zip": "30120",
                                    "city": "Cartersville",
                                    "state": "GA"
                                },
                                "facility": {
                                    "name": "MacGyver MED GROUP",
                                    "department": "Cartersville Office-",
                                    "providerGroup": "7 Hills Medical Group"
                                }
                            }
                        },
                        {
                            "alt": {
                                "address": {
                                    "streetAddress": "3 Hatley Rd, Belfast, ME 04915, USA",
                                    "zip": "04915",
                                    "city": "Belfast",
                                    "state": "ME"
                                },
                                "facility": {
                                    "name": "Murray HEALTH GROUP",
                                    "department": "Shawnee Mission Health",
                                    "providerGroup": "7 Hills Medical Group"
                                }
                            }
                        },
                        {
                            "alt": {
                                "address": {
                                    "streetAddress": "86 Razorville Rd, Washington, ME 04574, USA",
                                    "zip": "04574",
                                    "city": "Washington",
                                    "state": "ME"
                                },
                                "facility": {
                                    "name": "Metz HEATLH INC",
                                    "department": "Patient Face Name Test Department 47 College St",
                                    "providerGroup": "7 Hills Medical Group"
                                }
                            }
                        },
                        {
                            "alt": {
                                "address": {
                                    "streetAddress": "3 Hatley Rd, Belfast, ME 04915, USA",
                                    "zip": "04915",
                                    "city": "Belfast",
                                    "state": "ME"
                                },
                                "facility": {
                                    "name": "Gerlach HEATLH INC",
                                    "department": "Gerlach HEATLH INC",
                                    "providerGroup": "7 Hills Medical Group"
                                }
                            }
                        },
                        {
                            "alt": {
                                "address": {
                                    "streetAddress": "3115 New Hope Rd, Zebulon, GA 30295, USA",
                                    "zip": "30295",
                                    "city": "Zebulon",
                                    "state": "GA"
                                },
                                "facility": {
                                    "name": "Jacobs ATHENA HEALTH CARE INC",
                                    "department": "CommOpsDevTest",
                                    "providerGroup": "7 Hills Medical Group"
                                }
                            }
                        },
                        {
                            "alt": {
                                "address": {
                                    "streetAddress": "15 East Road, Queensbury, NY 12804, USA",
                                    "zip": "12804",
                                    "city": "Queensbury",
                                    "state": "NY"
                                },
                                "facility": {
                                    "name": "Kessler ATHENA HEALTH CARE INC",
                                    "department": "Main Office - NY",
                                    "providerGroup": "7 Hills Medical Group"
                                }
                            }
                        },
                        {
                            "alt": {
                                "address": {
                                    "streetAddress": "311 Arsenal St, Watertown, MA 02472, USA",
                                    "zip": "02472",
                                    "city": "Watertown",
                                    "state": "MA"
                                },
                                "facility": {
                                    "name": "Lockman MED GROUP",
                                    "department": "Training Dept Patient Facing Name",
                                    "providerGroup": "7 Hills Medical Group"
                                }
                            }
                        },
                        {
                            "alt": {
                                "address": {
                                    "streetAddress": "123 Main St, Watertown, MA 02472, USA",
                                    "zip": "02472",
                                    "city": "Watertown",
                                    "state": "MA"
                                },
                                "facility": {
                                    "name": "Swift INC",
                                    "department": "16 11 testing",
                                    "providerGroup": "7 Hills Medical Group"
                                }
                            }
                        }
                    ]
                }
            ],
            displayAccessibility: false,
            search: null,
            searchCSS: 'OPD-Search-Form-Hide',
            zip: null,
            showFields: false,
            fields: [
                {
                    "id": 0,
                    "row": 0,
                    "type": "tb",
                    "subtype": "text",
                    "label": "Search For: ",
                    "name": "provider",
                    "placeholder": "Provider LastName, Facility, or Specialty",
                    "max": 40,
                    "style": {
                        "fontWeight": "bold"
                    },
                    "validation": "",
                    "required": false
                },
                {
                    "id": 1,
                    "row": 0,
                    "label": "Search Within: ",
                    "name": "distance",
                    "type": "cbgrb",
                    "required": true,
                    "options": [
                        {
                            "label": "5 Miles",
                            "val": "5"
                        },
                        {
                            "label": "10 Miles",
                            "val": "10"
                        },
                        {
                            "label": "20 Miles",
                            "val": "20"
                        },
                        {
                            "label": "50 Miles",
                            "val": "50"
                        },
                        {
                            "label": "100 Miles",
                            "val": "100"
                        },
                        {
                            "label": "500 Miles",
                            "val": "500"
                        },
                        {
                            "label": "1000 Miles",
                            "val": "1000"
                        },
                        {
                            "label": "1500 Miles",
                            "val": "1500"
                        }
                    ],
                    "answer": {
                        "label": "5 Miles",
                        "val": "5"
                    }
                },
                {
                    "id": 2,
                    "row": 0,
                    "type": "tb",
                    "subtype": "zip",
                    "label": "My ZIP: ",
                    "name": "zip",
                    "placeholder": "Zip Code",
                    "max": 10,
                    "style": {
                        "fontWeight": "bold"
                    },
                    "validation": "name",
                    "required": true,
                    "help": "Zip Code Only"
                },
                {
                    "id": 3,
                    "row": 0,
                    "type": "tb",
                    "subtype": "text",
                    "label": "Provider Location: ",
                    "name": "address",
                    "placeholder": "Zip Code, City and State, or Street Address",
                    "max": 50,
                    "style": {
                        "fontWeight": "bold"
                    },
                    "validation": "",
                    "required": false
                },
                {
                    "id": 4,
                    "row": 0,
                    "label": "Provider Type: ",
                    "name": "type",
                    "type": "cbgrb",
                    "required": false,
                    "options": [
                        {
                            "label": "No Preference",
                            "val": "na"
                        }
                    ]
                },
                {
                    "id": 5,
                    "row": 0,
                    "label": "Doctor's Gender: ",
                    "name": "gender",
                    "type": "cbgrb",
                    "required": false,
                    "options": [
                        {
                            "label": "No Preference",
                            "val": "na"
                        },
                        {
                            "label": "Male",
                            "val": "male"
                        },
                        {
                            "label": "Female",
                            "val": "female"
                        }
                    ]
                },
                {
                    "id": 6,
                    "row": 0,
                    "label": "Doctor Speaks: ",
                    "name": "doclang",
                    "type": "cbgrb",
                    "required": false,
                    "options": [
                        {
                            "label": "No Preference",
                            "val": "na"
                        }
                    ]
                },
                {
                    "id": 7,
                    "row": 0,
                    "label": "Staff Speaks: ",
                    "name": "stafflang",
                    "type": "cbgrb",
                    "required": false,
                    "options": [
                        {
                            "label": "No Preference",
                            "val": "na"
                        }
                    ]
                },
                {
                    "id": 8,
                    "row": 0,
                    "label": "Accepting New Patients: ",
                    "name": "new",
                    "type": "cbgrb",
                    "required": false,
                    "options": [
                        {
                            "label": "No Preference",
                            "val": "na"
                        },
                        {
                            "label": "New Patients",
                            "val": "new"
                        },
                        {
                            "label": "Existing Patients",
                            "val": "existing"
                        }
                    ]
                },
                {
                    "id": 9,
                    "row": 0,
                    "label": "Programs: ",
                    "name": "programs",
                    "type": "cbgrb",
                    "required": false,
                    "options": [
                        {
                            "label": "No Preference",
                            "val": "na"
                        }
                    ]
                },
                {
                    "id": 10,
                    "row": 0,
                    "label": "Office Hours: ",
                    "name": "hours",
                    "type": "cbgrb",
                    "required": false,
                    "options": [
                        {
                            "label": "No Preference",
                            "val": "na"
                        },
                        {
                            "label": "Regular",
                            "val": "regular"
                        },
                        {
                            "label": "Extended",
                            "val": "extended"
                        },
                        {
                            "label": "Weekends",
                            "val": "weekends"
                        }
                    ]
                },
                {
                    "id": 11,
                    "row": 0,
                    "label": "Allowed Reasons: ",
                    "name": "type",
                    "type": "cbgrb",
                    "required": true,
                    "options": []
                },
                {
                    "id": 12,
                    "row": 0,
                    "type": "tb",
                    "subtype": "text",
                    "label": "Cancellation Reason: ",
                    "name": "provider",
                    "placeholder": "Reason for cancelling appointment (100 chars max)",
                    "max": 100,
                    "style": {
                        "fontWeight": "bold"
                    },
                    "validation": "",
                    "required": false
                }
            ],
            formHeight: 700,
            hasSearched: false,
            ptid: null,
            displayProviderList: false,
            providerSearchSel: false,
            selectedProvider: null,
            isBooking: false,
            apptDate: null,
            evalDate: moment().format('L'),
            selectedSlot: null,
            isProcessing: false,
            scheduleMsg: null,
            scheduleIcon: null,
            appointments: [],
            patientid: null
        }

        this.updateWindowSize = this.updateWindowSize.bind(this)
        this.handleValueChange = this.handleValueChange.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
    }

    componentDidMount() {
        this.handleGetLoc()
        this.updateWindowSize()
        window.addEventListener('resize', this.updateWindowSize)

        //return false

        API.post('gwhpp', '/athena?action=getProviders')
            .then(resp => {
                let providers = resp.providers
                providers.forEach(p => p.specialty = (p.ansinamecode ? p.ansinamecode.replace(/\s\(.*\)/, '').split(' : ') : []))
                this.setState({ providers })
                console.log('here 1.1')
            })
            .catch(e => {
                console.log(e)
            })
        API.post('gwhpp', `/athena?action=getLocations`, { body: {} }) //limit: 15, offset: 1
            .then(resp => {
                let departments = resp.departments.filter(dept => dept.latitude && dept.longitude && dept.providerlist && dept.providerlist.length > 0)
                departments.forEach(dept => {
                    API.get('gwhpp', `/geo?coord=${dept.latitude},${dept.longitude}`)
                        .then(addr => {
                            if (addr && addr.data && addr.data.results[0]) {
                                let streetAddress = addr.data.results[0].formatted_address ? addr.data.results[0].formatted_address : 'Address Unavailable'
                                let zipIdx = addr.data.results[0].address_components && addr.data.results[0].address_components[0] ? addr.data.results[0].address_components.findIndex(c => c.types && c.types.indexOf('postal_code') > -1) : -1
                                let cityIdx = addr.data.results[0].address_components && addr.data.results[0].address_components[0] ? addr.data.results[0].address_components.findIndex(c => c.types && c.types.indexOf('locality') > -1) : -1
                                let stateIdx = addr.data.results[0].address_components && addr.data.results[0].address_components[0] ? addr.data.results[0].address_components.findIndex(c => c.types && c.types.indexOf('administrative_area_level_1') > -1) : -1
                                let zip = zipIdx > -1 && addr.data.results[0].address_components[zipIdx].short_name ? addr.data.results[0].address_components[zipIdx].short_name : null
                                let city = cityIdx > -1 && addr.data.results[0].address_components[cityIdx].short_name ? addr.data.results[0].address_components[cityIdx].short_name : null
                                let state = stateIdx > -1 && addr.data.results[0].address_components[stateIdx].short_name ? addr.data.results[0].address_components[stateIdx].short_name : null
                                dept.addr = {
                                    streetAddress,
                                    zip,
                                    city,
                                    state
                                }
                            }
                            else {
                                dept.addr = {}
                            }
                            if (departments.filter(d => !d.addr).length === 0) {
                                this.setState({ departments })
                                console.log('here 1.2')
                                this.getDepartmentsDist()
                            }

                        })
                })

            })
            .catch(e => {
                console.log(e)
            })
    }

    componentWillMount() {
        if (!this.props.user || !this.props.user[0] || this.props.user[0].restrict) this.props.history.push('/')
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.zip && prevState.zip !== this.state.zip && this.state.zip.search(/^\d{5}$|^\d{5}-\d{4}$/) > -1 && this.state.departments.length > 0) {
            console.log('address changed, prev, curr, 5D -->   ', prevState.zip, this.state.zip, this.state.zip.search(/^\d{5}||(\d{5}-\d{4})$/))
            this.getDepartmentsDist()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowSize)
    }

    updateWindowSize() {
        this.setState({
            formHeight: window.innerWidth > 970 ? 580 : 770
        })
    }

    getDepartmentsDist() {
        let departments = [...this.state.departments]
        departments.forEach(d => {
            API.get('gwhpp', `/geo?origin=${this.state.zip}&destination=${d.addr.streetAddress}`)
                .then(dist => {
                    if (dist.data && dist.data.rows[0] && dist.data.rows[0].elements[0] && dist.data.rows[0].elements[0].distance) d.distance = { ...dist.data.rows[0].elements[0].distance, text: dist.data.rows[0].elements[0].distance.text.replace('mi', 'miles') }
                })
        })
    }

    handleDateChange(date) {
        this.setState({ apptDate: date, evalDate: moment(date).format('L') })
    }

    handleProviderListSelect(type, val, text) {
        let fields = [...this.state.fields]
        fields[0] = { ...this.state.fields[0], answer: text, name: type }
        this.setState({ displayProviderList: false, fields, search: type === 'provider' ? { providerid: val } : null })
    }

    handleGetLoc() {
        let gl = navigator.geolocation
        gl ? gl.getCurrentPosition(position => {
            API.get('gwhpp', `/geo?coord=${position.coords.latitude.toFixed(7)},${position.coords.longitude.toFixed(7)}`)
                .then(addr => {
                    let zipIdx = addr.data.results[0].address_components && addr.data.results[0].address_components[0] ? addr.data.results[0].address_components.findIndex(c => c.types && c.types.indexOf('postal_code') > -1) : -1
                    let zip = zipIdx > -1 && addr.data.results[0].address_components[zipIdx].short_name ? addr.data.results[0].address_components[zipIdx].short_name : null
                    this.setState({ zip })
                    this.handleValueChange(this.state.fields[2], zip)
                })
                .catch(e => console.log(e))
        }, err => {
            console.log(err)
        }) : console.log('geo failed')
    }

    handleValueChange(field, val, idx, cell) {
        console.log('field val     ', field, val, typeof val)
        let fields = [...this.state.fields]
        fields[field.id] = { ...field, answer: val }
        this.setState({ fields })
        if (field.id === 2 && val.search(/^\d{5}$|^\d{5}-\d{4}$/) > -1) {
            this.setState({ zip: val })
        }
        if (field.id === 0 && val.length > 2) this.setState({ displayProviderList: true })
    }

    handleDisplayNewSearch(show) {
        this.setState({ displayNewSearchForm: typeof (show) === 'boolean' ? show : !this.state.displayNewSearchForm })
    }

    handleDisplaySearch(show) {
        let nextShow = typeof (show) === 'boolean' ? show : !this.state.displaySearchForm

        if (nextShow) {
            this.setState({ displaySearchForm: nextShow, searchCSS: 'OPD-Search-Form-Hide' })
            setTimeout(() => { this.setState({ searchCSS: 'OPD-Search-Form-Show' }) }, 100)
            setTimeout(() => { this.setState({ showFields: true }) }, 500)
        } else {
            this.setState({ searchCSS: 'OPD-Search-Form-Hide', showFields: false })
            setTimeout(() => { this.setState({ displaySearchForm: false }) }, 500)
        }
    }

    handleDisplayAccessibility(show) {
        this.setState({ displayAccessibility: typeof (show) === 'boolean' ? show : !this.state.displayAccessibility })
    }

    handleNewSearchSelection() {
        let search = this.state.search || {}
        if (this.searchIsValid()) {
            this.state.fields.forEach(f => { if (f.answer) search[f.name] = f.answer || f.answer.val })
            this.setState({ search, searchMsg: null, zip: search.zip, displaySearchForm: false, searchCSS: 'OPD-Search-Form-Hide', showFields: false, hasSearched: true })
            this.handleSearch(search)
            console.log('new search   ->    ', search)
        } else {
            this.setState({ searchMsg: "Search Criteria is missing required values." })
        }
    }

    handleSearchSelection() {
        let search = this.state.search || {}
        if (this.searchIsValid()) {
            this.state.fields.forEach(f => { if (f.answer) search[f.name] = f.answer || f.answer.val })
            this.setState({ search, searchMsg: null, zip: search.zip, displaySearchForm: false, searchCSS: 'OPD-Search-Form-Hide', showFields: false, hasSearched: true })
            this.handleSearch(search)
        } else {
            this.setState({ searchMsg: "Search Criteria is missing required values." })
        }
    }

    handleStartNewSearch() {
        this.setState({ search: null, searchResults: null, displaySearchForm: false, searchCSS: 'OPD-Search-Form-Hide', showFields: false, hasSearched: false })
    }

    searchIsValid() {
        return this.state.fields.filter(f => f.answer && f.required && (f.id === 2 ? f.answer && f.answer.search(/^\d{5}$|^\d{5}-\d{4}$/) > -1 : true)).length > 0
    }

    handleProviderSelect(selectedProvider) {
        this.setState({ selectedProvider: selectedProvider || null, selectedSlot: null, isBooking: false, isProcessing: false })
    }

    handleBackToDetails() {
        this.setState({ isBooking: false, isProcessing: false, selectedSlot: null })
    }

    handleBackToSearch() {
        this.setState({ isBooking: false })
        this.handleProviderSelect(null)
    }

    handleSearch(search) {
        console.log('handle search     ', search)
        let searchResults = []
        let usedProviders = []
        let deptIDs = []
        if (search) {
            let provider = search.providerid || null
            let dist = search.distance && search.distance.val ? parseFloat(search.distance.val) : null
            let addr = search.address || null
            let newPatients = search.newPatients || null
            let specialty = search.specialty || null
            let facility = search.facility || null
            this.state.departments.filter(dept => {
                let compAddr = addr ? (dept.address.streetAddress === addr || dept.address.zip === addr || `${dept.address.city}, ${dept.address.state}` === addr) : true
                let compDist = dist && dept.distance && dept.distance.text ? parseFloat(dept.distance.text.replace(' miles', '').replace(',', '')) <= dist : true
                let compFacility = facility ? facility === dept.name : true
                return compAddr && compDist && compFacility
            }).forEach(dept => {
                deptIDs.push(dept.departmentid)
                dept.providerlist.filter(pvdr =>
                    usedProviders.indexOf(pvdr) < 0 &&
                        provider ? pvdr === provider : true &&
                            specialty ? this.state.providers[this.state.providers.findIndex(pv => pv.providerid === parseInt(pvdr))].specialty || '' === specialty : true &&
                                typeof (newPatients) === 'boolean' ? this.state.providers[this.state.providers.findIndex(pv => pv.providerid === parseInt(pvdr))].acceptingnewpatients || false === newPatients : true
                ).forEach(p => {
                    let idx = this.state.providers.findIndex(pvdr => pvdr.providerid === parseInt(p))
                    usedProviders.push(p)
                    if (idx > -1) {
                        searchResults.push({
                            name: this.state.providers[idx].displayname || `Dr. ${this.state.providers[idx].lastname}`,
                            lastname: this.state.providers[idx].lastname || null,
                            firstname: this.state.providers[idx].firstname || null,
                            providerid: this.state.providers[idx].providerid || null,
                            providertype: this.state.providers[idx].providertype || null,
                            npi: this.state.providers[idx].npi || null,
                            specialty: this.state.providers[idx].ansinamecode ? this.state.providers[idx].ansinamecode.replace(/\s\(.*\)/, '').split(' : ') : [],
                            departmentid: dept.departmentid,
                            url: dept.portalurl,
                            distance: dept.distance,
                            address: {
                                streetAddress: dept.addr.streetAddress,
                                zip: dept.addr.zip,
                                city: dept.addr.city,
                                state: dept.addr.state
                            },
                            facility: {
                                name: dept.name || null,
                                department: dept.patientdepartmentname || null,
                                providerGroup: dept.providergroupname || null,
                                phone: dept.phone || null
                            },
                            altLocations: this.state.departments.filter(d => d.providerlist && d.providerlist.indexOf(p.toString()) > -1).map(d => {
                                return {
                                    alt: {
                                        address: {
                                            streetAddress: d.addr.streetAddress,
                                            zip: d.addr.zip,
                                            city: d.addr.city,
                                            state: d.addr.state
                                        },
                                        facility: {
                                            name: d.name || null,
                                            department: d.patientdepartmentname || null,
                                            providerGroup: d.providergroupname || null,
                                            phone: d.phone
                                        },
                                        departmentid: d.departmentid
                                    }
                                }
                            })
                        })
                    }
                })
            })
        } else {
            this.state.departments.forEach(dept => {
                dept.providerlist.filter(provider => usedProviders.indexOf(provider) < 0).forEach(p => {
                    let idx = this.state.providers.findIndex(pvdr => pvdr.providerid === parseInt(p))
                    usedProviders.push(p)
                    if (idx > -1) {
                        searchResults.push({
                            name: this.state.providers[idx].displayname || null,
                            lastname: this.state.providers[idx].lastname || null,
                            firstname: this.state.providers[idx].firstname || null,
                            providertype: this.state.providers[idx].providertype || null,
                            providerid: this.state.providers[idx].providerid || null,
                            npi: this.state.providers[idx].npi || null,
                            specialty: this.state.providers[idx].specialty,
                            departmentid: dept.departmentid,
                            url: dept.portalurl,
                            distance: dept.distance,
                            address: {
                                streetAddress: dept.addr.streetAddress,
                                zip: dept.addr.zip,
                                city: dept.addr.city,
                                state: dept.addr.state
                            },
                            facility: {
                                name: dept.name || null,
                                department: dept.patientdepartmentname || null,
                                providerGroup: dept.providergroupname || null,
                                phone: dept.phone || null
                            },
                            altLocations: this.state.departments.filter(d => d.providerlist && d.providerlist.indexOf(p.toString())).map(d => {
                                return {
                                    alt: {
                                        address: {
                                            streetAddress: d.addr.streetAddress,
                                            zip: d.addr.zip,
                                            city: d.addr.city,
                                            state: d.addr.state
                                        },
                                        facility: {
                                            name: d.name || null,
                                            department: d.patientdepartmentname || null,
                                            providerGroup: d.providergroupname || null,
                                            phone: d.phone
                                        },
                                        departmentid: d.departmentid
                                    }
                                }
                            })
                        })
                    }
                })
            })
        }

        searchResults.sort((a, b) => {
            let x = a.distance.value
            let y = b.distance.value
            if (x < y) return -1
            if (y < x) return 1
            return 0
        }).forEach(sr => {
            sr.slots = []
            sr.altLocations.forEach(al => al.slots = [])
        })
        this.setState({ searchResults })
        API.post('gwhpp', '/athena?action=getSlots', { body: { departmentId: deptIDs, providerId: usedProviders.slice(0, 99), reasonId: [1281, 1341, 1285, 1321] } })
            .then(resp => {
                resp.appointments.forEach(appt => {
                    let sridx = searchResults.findIndex(sr => parseInt(sr.departmentid) === appt.departmentid && sr.providerid === appt.providerid)
                    if (sridx > -1) {
                        searchResults[sridx].slots.push({
                            id: appt.appointmentid,
                            start: `${appt.date} ${appt.starttime}`,
                            org: 'athena',
                            reasonid: appt.reasonid
                        })
                    }
                })
                this.setState({ searchResults })
            })
            .catch(e => {
                console.log(e)
            })
    }

    handleBookClick(selectedProvider) {
        let fields = [...this.state.fields]
        this.setState({ selectedProvider: selectedProvider || null, isBooking: true })
        API.post('gwhpp', '/athena?action=getReasons', { body: { departmentId: selectedProvider.departmentid, providerId: selectedProvider.providerid } })
            .then(resp => {
                fields[11].options = resp.patientappointmentreasons.map(r => {
                    return { label: r.reason, val: r.reasonid }
                })
                this.setState({ fields })
            })
            .catch(e => {
                console.log(e)
            })

    }

    handleSlotSelection(selectedSlot) {
        if (this.props.user && this.props.user[0] && this.props.user[0].memberNumber && this.state.fields[11].answer && this.state.fields[11].answer.val) {
            console.log(selectedSlot)
            this.setState({ selectedSlot, scheduleIcon: 'fas fa-user', scheduleMsg: "Checking to see if you are a patient in the Athena EMR...", isProcessing: true })
            let patientid = null
            API.post('gwhpp', '/athena?action=getPatientId', { body: { hccid: this.props.user[0].memberNumber } })
                .then(resp => {
                    if (resp && resp[0] && resp.findIndex(p => p.practiceid === 195900) > -1) {
                        patientid = resp[resp.findIndex(p => p.practiceid === 195900)].patientid
                        this.setState({ patientid, scheduleIcon: 'far fa-calendar-alt', scheduleMsg: "Patient exists in EMR, booking appointment..." })
                        API.post('gwhpp', '/athena?action=bookAppointment', {
                            body:
                            {
                                appointmentid: selectedSlot.id,
                                practiceid: 195900,
                                patientid,
                                bookingnote: "Appointment booked for demo purposes.",
                                departmentid: parseInt(this.state.selectedProvider.departmentid),
                                reasonid: this.state.fields[11].answer.val
                            }
                        })
                            .then(appt => {
                                //this.props.history.push('/appointments')
                                this.setState({ scheduleIcon: 'far fa-calendar-check', scheduleMsg: "Appointment Booked Successfully" })
                            })
                    } else {
                        this.setState({ scheduleIcon: 'fas fa-user', scheduleMsg: "Patient does NOT exists in EMR, creating patient account..." })
                        API.post('gwhpp', '/athena?action=createPatient', {
                            body: {
                                practiceid: 195900,
                                address1: this.props.user[0].address1,
                                address2: this.props.user[0].address2,
                                city: this.props.user[0].city,
                                departmentid: this.state.selectedProvider.departmentid,
                                dob: moment(this.props.user[0].dob, 'YYYY-MM-DD').format('MM/DD/YYYY'),
                                email: 'declined',
                                firstname: this.props.user[0].firstName,
                                lastname: this.props.user[0].lastName,
                                homephone: this.props.user[0].homePhone.replace(/-/g, ''),
                                mobilephone: this.props.user[0].homePhone.replace(/-/g, ''),
                                state: this.props.user[0].state,
                                zip: this.props.user[0].zip,
                                hccid: this.props.user[0].memberNumber
                            }
                        })
                            .then(resp => {
                                console.log(resp)
                                if (resp && resp.patientid) {
                                    let pat = resp.patientid
                                    this.setState({ patientid: resp.patientid, scheduleIcon: 'fas fa-notes-medical', scheduleMsg: "Patient added to EMR, creating patient chart..." })
                                    API.post('gwhpp', '/athena?action=createProblem', {
                                        body:
                                        {
                                            patientId: pat,
                                            departmentid: parseInt(this.state.selectedProvider.departmentid),
                                            snomedcode: 398036000,
                                            startdate: "04/17/2020",
                                            note: "Enrolled in CHF care program"
                                        }
                                    })
                                        .then(patient => {
                                            this.setState({ scheduleIcon: 'far fa-calendar-alt', scheduleMsg: "Booking Appointment" })
                                            API.post('gwhpp', '/athena?action=bookAppointment', {
                                                body:
                                                {
                                                    appointmentid: selectedSlot.id,
                                                    practiceid: 195900,
                                                    patientid: pat,
                                                    bookingnote: "Appointment booked for demo purposes.",
                                                    departmentid: parseInt(this.state.selectedProvider.departmentid),
                                                    reasonid: this.state.fields[11].answer.val
                                                }
                                            })
                                                .then(appt => {
                                                    //this.props.history.push('/appointments')
                                                    this.setState({ scheduleIcon: 'far fa-calendar-check', scheduleMsg: "Appointment Booked Successfully" })
                                                })
                                                .catch(e => {
                                                    console.log(e)
                                                })
                                        })
                                        .catch(e => console.log(e))
                                }
                            })
                            .catch(e => {
                                console.log(e)
                            })
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        }
    }

    getSearchForm() {
        return (
            this.state.displaySearchForm ?
                <div className={`OPD-Search-Form-Container ${this.state.searchCSS}`} style={{ height: this.state.showFields ? this.state.formHeight : null }}>
                    <div className="OPD-Search-Form-Row">
                        <div className="OPD-Search-Form-Item">
                            {this.state.showFields && <PrettyTB field={this.state.fields[0]} onValueChange={this.handleValueChange} />}
                            {this.getProviderList()}
                        </div>
                        <div className="OPD-Search-Form-Item">
                            {this.state.showFields && <PrettyCBGRB field={this.state.fields[1]} onValueChange={this.handleValueChange} />}
                        </div>
                        <div className="OPD-Search-Form-Item">
                            {this.state.showFields && <PrettyTB field={this.state.fields[2]} onValueChange={this.handleValueChange} />}
                        </div>
                        <div className="OPD-Search-Form-Item">
                            {this.state.showFields && <PrettyTB field={this.state.fields[3]} onValueChange={this.handleValueChange} />}
                        </div>
                        <div className="OPD-Search-Form-Item">
                            {this.state.showFields && <PrettyCBGRB field={this.state.fields[4]} onValueChange={this.handleValueChange} />}
                        </div>
                    </div>
                    <div className="OPD-Search-Form-Row">
                        {this.state.showFields && <hr />}
                    </div>
                    <div className="OPD-Search-Form-Row">
                        <div className="OPD-Search-Form-Item">
                            {this.state.showFields && <PrettyCBGRB field={this.state.fields[5]} onValueChange={this.handleValueChange} />}
                        </div>
                        <div className="OPD-Search-Form-Item">
                            {this.state.showFields && <PrettyCBGRB field={this.state.fields[6]} onValueChange={this.handleValueChange} />}
                        </div>
                        <div className="OPD-Search-Form-Item">
                            {this.state.showFields && <PrettyCBGRB field={this.state.fields[7]} onValueChange={this.handleValueChange} />}
                        </div>
                        <div className="OPD-Search-Form-Item">
                            {this.state.showFields && <PrettyCBGRB field={this.state.fields[8]} onValueChange={this.handleValueChange} />}
                        </div>
                        <div className="OPD-Search-Form-Item">
                            {this.state.showFields && <PrettyCBGRB field={this.state.fields[9]} onValueChange={this.handleValueChange} />}
                        </div>
                        <div className="OPD-Search-Form-Item">
                            {this.state.showFields && <PrettyCBGRB field={this.state.fields[10]} onValueChange={this.handleValueChange} />}
                        </div>
                    </div>
                    <div className="OPD-Search-Form-Row">
                        <div className="OPD-Search-Form-Item"></div>
                        <div className="OPD-Search-Form-Item"></div>
                        <div className="OPD-Search-Form-Item">
                            {this.state.showFields && <div className="OPD-Account-Container" onClick={() => this.handleSearchSelection()}>Apply Filter</div>}
                        </div>
                    </div>
                </div> : null
        )
    }

    getNewSearchForm() {
        return (
            this.state.displayNewSearchForm && !this.state.hasSearched ?
                <div>
                    <div className="OPD-Search-Form-Row">
                        <div className="OPD-Search-Form-Item">
                            <PrettyTB field={this.state.fields[0]} onValueChange={this.handleValueChange} />
                            {this.getProviderList()}
                        </div>
                        <div className="OPD-Search-Form-Item">
                            <PrettyCBGRB field={this.state.fields[1]} onValueChange={this.handleValueChange} />
                        </div>
                        <div className="OPD-Search-Form-Item">
                            <PrettyTB field={this.state.fields[2]} onValueChange={this.handleValueChange} />
                        </div>
                        <div className="OPD-Search-Form-Item">
                            <PrettyTB field={this.state.fields[3]} onValueChange={this.handleValueChange} />
                        </div>
                        <div className="OPD-Search-Form-Item">
                            <PrettyCBGRB field={this.state.fields[4]} onValueChange={this.handleValueChange} />
                        </div>
                    </div>
                    <div className="OPD-Search-Form-Row">
                        <hr />
                    </div>
                    <div className="OPD-Search-Form-Row">
                        <div className="OPD-Search-Form-Item">
                            <PrettyCBGRB field={this.state.fields[5]} onValueChange={this.handleValueChange} />
                        </div>
                        <div className="OPD-Search-Form-Item">
                            <PrettyCBGRB field={this.state.fields[6]} onValueChange={this.handleValueChange} />
                        </div>
                        <div className="OPD-Search-Form-Item">
                            <PrettyCBGRB field={this.state.fields[7]} onValueChange={this.handleValueChange} />
                        </div>
                        <div className="OPD-Search-Form-Item">
                            <PrettyCBGRB field={this.state.fields[8]} onValueChange={this.handleValueChange} />
                        </div>
                        <div className="OPD-Search-Form-Item">
                            <PrettyCBGRB field={this.state.fields[9]} onValueChange={this.handleValueChange} />
                        </div>
                        <div className="OPD-Search-Form-Item">
                            <PrettyCBGRB field={this.state.fields[10]} onValueChange={this.handleValueChange} />
                        </div>
                    </div>
                    <div className="OPD-Search-Form-Row">
                        <div className="OPD-Search-Form-Item"></div>
                        <div className="OPD-Search-Form-Item"></div>
                        <div className="OPD-Search-Form-Item">
                            <div className="OPD-Account-Container" onClick={() => this.handleNewSearchSelection()}>Search Now</div>
                        </div>
                    </div>
                </div> : null
        )
    }

    getProviderList() {
        let dist = parseInt(this.state.fields[1].answer.val)
        let text = this.state.fields[0].answer && this.state.fields[0].answer.length > 2 ? this.state.fields[0].answer : null
        if (this.state.displayProviderList && text) {
            let localDepartments = this.state.departments.filter(dept => dept.distance && dept.distance.text && parseFloat(dept.distance.text.replace(' miles', '').replace(',', '')) < dist)
            let localProviders = []
            let facilities = []
            localDepartments.forEach(ld => { if (ld.name && ld.name.toLowerCase().indexOf(text.toLowerCase()) === 0) facilities.push({ name: ld.name, dist: ld.distance.text }) })
            localDepartments.forEach(ld => {
                ld.providerlist.forEach(p => { if (localProviders.indexOf(p) < 0) localProviders.push(p) })
            })
            let providers = []
            let specialties = []
            localProviders.forEach(lp => {
                let lpidx = this.state.providers.findIndex(p => p.providerid === parseInt(lp))
                if (lpidx > -1 && this.state.providers[lpidx].specialty && this.state.providers[lpidx].specialty) this.state.providers[lpidx].specialty.forEach(s => { if (s.toLowerCase().indexOf(text) === 0 && specialties.indexOf(s) < 0) specialties.push(s) }) //specialties.push(this.state.providers[lpidx].specialty)
                if (lpidx > -1 && this.state.providers[lpidx].displayname && this.state.providers[lpidx].lastname && this.state.providers[lpidx].lastname.toLowerCase().indexOf(text.toLowerCase()) === 0) providers.push({ lastname: this.state.providers[lpidx].lastname, displayname: this.state.providers[lpidx].displayname, providerid: lp })
            })

            return (facilities.length > 0 || providers.length > 0 || specialties.length > 0) && <div className="OPD-Provider-List-Container">
                {
                    facilities.length > 0 &&
                    <div className="OPD-Provider-List-Section-Container">
                        <div className="OPD-Provider-List-Section-Row OPD-Provider-List-Section-Title">Facilities</div>
                        {
                            facilities.sort((a, b) => {
                                let x = a.name
                                let y = b.name
                                if (x > y) return 1
                                if (x < y) return -1
                                return 0
                            }).map((f, fidx) => {
                                return (
                                    <div key={`facility-${fidx}`} className="OPD-Provider-List-Section-Row OPD-Provider-List-Section-Value" onClick={() => this.handleProviderListSelect('facility', f.name, f.name)}>
                                        {`${f.name} - ${f.dist}`}
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    providers.length > 0 &&
                    <div className="OPD-Provider-List-Section-Container">
                        <div className="OPD-Provider-List-Section-Row OPD-Provider-List-Section-Title">Providers</div>
                        {
                            providers.sort((a, b) => {
                                let x = a.lastname
                                let y = b.lastname
                                if (x > y) return 1
                                if (x < y) return -1
                                return 0
                            }).map((p, pidx) => {
                                return (
                                    <div key={`facility-${pidx}`} className="OPD-Provider-List-Section-Row OPD-Provider-List-Section-Value" onClick={() => this.handleProviderListSelect('provider', p.providerid, p.displayname)}>
                                        {p.displayname}
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    specialties.length > 0 &&
                    <div className="OPD-Provider-List-Section-Container">
                        <div className="OPD-Provider-List-Section-Row OPD-Provider-List-Section-Title">Specialties</div>
                        {
                            specialties.sort((a, b) => {
                                let x = a.name
                                let y = b.name
                                if (x > y) return 1
                                if (x < y) return -1
                                return 0
                            }).map((s, sidx) => {
                                return (
                                    <div key={`facility-${sidx}`} className="OPD-Provider-List-Section-Row OPD-Provider-List-Section-Value" onClick={() => this.handleProviderListSelect('specialty', s, s)}>
                                        {s}
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        }
    }

    getSelectedProvider() {
        return (
            <div className="OPD-Provider-Container">
                <div className="OPD-Provider-Details-Container">
                    <div className="OPD-Provider-Details-Row">
                        <div className="OPD-Provider-Details-Back" onClick={() => this.handleProviderSelect(null)}>
                            <i className="fas fa-arrow-circle-left"></i> Back to Search
                        </div>
                    </div>
                    <div className="OPD-Provider-Details-Row OPD-Result-Row-Offset">
                        <div className="OPD-Result-Column OPD-Result-Column-Avatar-Container">
                            <i className="fas fa-user-md OPD-Result-Column-Avatar"></i>
                        </div>
                        <div className="OPD-Result-Column">
                            <div className="OPD-Result-Title">{this.state.selectedProvider.name}</div>
                            <div className="OPD-Result-Practice">{this.state.selectedProvider.facility.name}</div>
                            <div className="OPD-Result-Practice-Type">{this.state.selectedProvider.specialty.toString().replace(/,/g, ' / ')}</div>
                            <div className="OPD-Result-Practice">{this.state.selectedProvider.facility.providerGroup}</div>
                            <div className="OPD-Result-Department">{this.state.selectedProvider.facility.department}</div>
                            <div className="OPD-Result-Rating-Row">
                                {
                                    ['OPD-Result-Rating-Star-Checked', 'OPD-Result-Rating-Star-Checked', 'OPD-Result-Rating-Star-Checked', 'OPD-Result-Rating-Star', 'OPD-Result-Rating-Star'].map((star, staridx) => {
                                        return (
                                            <div key={`star-${this.state.selectedProvider.providerid}-${staridx}`} className={star}><i className="fas fa-star"></i></div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="OPD-Result-Column">
                            <div className="OPD-Result-Title">{`Provider #: ${this.state.selectedProvider.providerid}`}</div>
                            <div className="OPD-Result-Practice">{this.state.selectedProvider.npi && `NPI #: ${this.state.selectedProvider.npi}`}</div>
                            <div className="OPD-Result-Website">{this.state.selectedProvider.url && <a href={`${this.state.selectedProvider.url.indexOf('http') ? 'https://' : ''}${this.state.selectedProvider.url}`} target="blank">Visit Website</a>}</div>
                            {
                                this.state.selectedProvider.slots.length > 0 &&
                                <div className="OPD-Result-Book-Link OPD-Provider-Button-Left" onClick={() => this.handleBookClick(this.state.selectedProvider)}>
                                    Book Now
                                </div>
                            }
                        </div>
                    </div>
                    <div className="OPD-Provider-Details-Row">
                        <div className="OPD-Provider-Details-Title">
                            Contact Information &amp; Locations
                        </div>
                        {
                            this.state.selectedProvider.address.streetAddress.split(', ').map((addr, aidx) => {
                                return (
                                    <div key={`paddr-${aidx}`} className="OPD-Provider-Details-Address">{addr}</div>
                                )
                            })
                        }
                        {
                            this.state.selectedProvider.facility && this.state.selectedProvider.facility.phone &&
                            <div className="OPD-Provider-Details-Address">{this.state.selectedProvider.facility.phone}</div>
                        }
                        <div className="OPD-Provider-Details-Address OPD-Result-Website">{this.state.selectedProvider.address && this.state.selectedProvider.address.streetAddress && <a href={`http://maps.google.com/maps?q=${this.state.selectedProvider.address.streetAddress}`} target='blank'>Map Location</a>}</div>
                    </div>
                    <div className="OPD-Provider-Details-Row-Flex">
                        {
                            this.state.selectedProvider.altLocations && this.state.selectedProvider.altLocations.map((al, alidx) => {
                                return (
                                    <div key={`alt-${alidx}`} className="OPD-Provider-Details-Item">
                                        <div className="">
                                            <div className="OPD-Provider-Details-Facility">{al.alt.facility.name}</div>
                                            <div className="OPD-Details-Column">
                                                <div className="OPD-Provider-Details-Facility-Item">{al.alt.facility.department}</div>
                                                <div className="OPD-Provider-Details-Facility-Item">{al.alt.facility.providergroup}</div>
                                                {al.alt.facility.phone && <div className="OPD-Provider-Details-Facility-Item">{al.alt.facility.phone}</div>}
                                            </div>
                                            <div className="OPD-Details-Column">
                                                {
                                                    al.alt.address.streetAddress.split(', ').map((aladdr, aladdridx) => {
                                                        return (
                                                            <div key={`aladdr-${aladdridx}`} className="OPD-Provider-Details-Address">{aladdr}</div>
                                                        )
                                                    })
                                                }
                                                <div className="OPD-Provider-Details-Address OPD-Result-Website">{al.alt.address && al.alt.address.streetAddress && <a href={`http://maps.google.com/maps?q=${this.state.selectedProvider.address.streetAddress}`} target='blank'>Map Location</a>}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

            </div>
        )
    }

    getBookingPage() {
        return (
            <div className="OPD-Provider-Container">
                <div className="OPD-Provider-Details-Container">
                    <div className="OPD-Provider-Details-Row">
                        <div className="OPD-Provider-Details-Back OPD-Provider-Button-Left" onClick={() => this.handleBackToDetails()}>
                            <i className="fas fa-arrow-circle-left"></i> Back to Details
                        </div>
                        <div className="OPD-Provider-Details-Back OPD-Provider-Button-Right" onClick={() => this.handleBackToSearch()}>
                            Back to Search <i className="fas fa-search"></i>
                        </div>
                    </div>
                    <div className="OPD-Provider-Details-Row OPD-Result-Row-Offset">
                        <div className="OPD-Result-Column OPD-Result-Column-Avatar-Container">
                            <i className="fas fa-user-md OPD-Result-Column-Avatar"></i>
                        </div>
                        <div className="OPD-Result-Column">
                            <div className="OPD-Result-Title">{this.state.selectedProvider.name}</div>
                            <div className="OPD-Result-Practice">{this.state.selectedProvider.facility.name}</div>
                            <div className="OPD-Result-Practice-Type">{this.state.selectedProvider.specialty.toString().replace(/,/g, ' / ')}</div>
                            <div className="OPD-Result-Practice">{this.state.selectedProvider.facility.providerGroup}</div>
                            <div className="OPD-Result-Department">{this.state.selectedProvider.facility.department}</div>
                            <div className="OPD-Result-Rating-Row">
                                {
                                    ['OPD-Result-Rating-Star-Checked', 'OPD-Result-Rating-Star-Checked', 'OPD-Result-Rating-Star-Checked', 'OPD-Result-Rating-Star', 'OPD-Result-Rating-Star'].map((star, staridx) => {
                                        return (
                                            <div key={`star-${this.state.selectedProvider.providerid}-${staridx}`} className={star}><i className="fas fa-star"></i></div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="OPD-Result-Column">
                            <div className="OPD-Result-Title">{`Provider #: ${this.state.selectedProvider.providerid}`}</div>
                            {
                                this.state.selectedProvider.address.streetAddress.split(', ').map((addr, aidx) => {
                                    return (
                                        <div key={`paddr-${aidx}`} className="OPD-Provider-Details-Address">{addr}</div>
                                    )
                                })
                            }
                            {
                                this.state.selectedProvider.facility && this.state.selectedProvider.facility.phone &&
                                <div className="OPD-Provider-Details-Address">{this.state.selectedProvider.facility.phone}</div>
                            }
                            <div className="OPD-Result-Practice">{this.state.selectedProvider.npi && `NPI #: ${this.state.selectedProvider.npi}`}</div>
                            <div className="OPD-Result-Website">{this.state.selectedProvider.url && <a href={`${this.state.selectedProvider.url.indexOf('http') ? 'https://' : ''}${this.state.selectedProvider.url}`} target="blank">Visit Website</a>}</div>
                        </div>
                    </div>
                </div>
                <div className="OPD-Provider-Booking-Container">
                    {this.getSlots()}
                </div>
            </div>
        )
    }

    getSlots() {
        return (
            <div className="OPD-Slots-Container">
                <div className="OPD-Slots-Row">
                    <div className="OPD-Slots-Row-Item-Left">Date: <DatePicker selected={this.state.apptDate || new Date()} onChange={this.handleDateChange} placeholderText="Select a date" todayButton={`Today (${moment().format('ll')})`} minDate={new Date()} /></div>
                    <div className="OPD-Slots-Row-Item-Right"><PrettyCBGRB field={this.state.fields[11]} onValueChange={this.handleValueChange} /></div>
                </div>
                <div className="OPD-Slots-Row">
                    <div className="OPD-Slots-List-Container">
                        {
                            this.state.selectedProvider.slots.filter(s => moment(s.start).format('L') === this.state.evalDate).length > 0 ?
                                this.state.selectedProvider.slots.filter(s => moment(s.start).format('L') === this.state.evalDate && (this.state.fields[11].answer && this.state.fields[11].answer.val ? s.reasonid.indexOf(this.state.fields[11].answer.val.toString()) > -1 : true))
                                    .sort((a, b) => {
                                        let x = moment(a.start)
                                        let y = moment(b.start)
                                        if (x < y) return -1
                                        if (x > y) return 1
                                        return 0
                                    })
                                    .map((s, sidx) => {
                                        return <div key={`slot-${sidx}`} className="OPD-Slots-List-Item" onClick={() => this.handleSlotSelection(s)}>{moment(s.start).format('h:mm A')}</div>
                                    }) : <div className="OPD-Slots-None">No Availability for {moment(this.state.evalDate).format('ll')}</div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    getSearchResults() {
        return (
            <div className="OPD-Results-Container">
                {
                    this.state.searchResults && this.state.searchResults.map((sr, sridx) => {
                        return (
                            <div key={`sr-${sridx}`} className={`OPD-Result-Row${sridx % 2 ? ' OPD-Result-Row-Offset' : ''}`}>
                                <div className="OPD-Result-Column OPD-Result-Column-Avatar-Container">
                                    <i className="fas fa-user-md OPD-Result-Column-Avatar"></i>
                                    <div className="OPD-Result-Rating-Row">
                                        {
                                            ['OPD-Result-Rating-Star-Checked', 'OPD-Result-Rating-Star-Checked', 'OPD-Result-Rating-Star-Checked', 'OPD-Result-Rating-Star', 'OPD-Result-Rating-Star'].map((star, staridx) => {
                                                return (
                                                    <div key={`star-${sr.providerid}-${staridx}`} className={star}><i className="fas fa-star"></i></div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="OPD-Result-Column">
                                    <div className="OPD-Result-Title" onClick={() => this.handleProviderSelect(sr)}>{sr.name}</div>
                                    <div className="OPD-Result-Practice">{sr.facility.name}</div>
                                    <div className="OPD-Result-Practice-Type">{sr.specialty.toString().replace(/,/g, ' / ')}</div>
                                    <div className="OPD-Result-Practice">{sr.facility.providerGroup}</div>
                                    <div className="OPD-Result-Department">{sr.facility.department}</div>
                                </div>
                                <div className="OPD-Result-Column">
                                    {
                                        sr.address.streetAddress.split(', ').map((a, aidx) => {
                                            return (
                                                <div key={`addr-${sr.providerid}-${aidx}`} className="OPD-Result-Address-Line">{a}</div>
                                            )
                                        })
                                    }
                                    {sr.facility.phone && <div className="OPD-Result-Phone-Line">
                                        <div className="OPD-Result-Phone-Label">Phone</div>
                                        <div className="OPD-Result-Phone-Value">{sr.facility.phone}</div>
                                    </div>}
                                    <div className="OPD-Result-Website">{sr.url && <a href={`${sr.url.indexOf('http') ? 'https://' : ''}${sr.url}`} target="blank">Visit Website</a>}</div>
                                </div>
                                <div className="OPD-Result-Column">
                                    <div className="OPD-Result-Distance">{sr.distance && sr.distance.text}</div>
                                    <div className="OPD-Result-Website">{sr.address && sr.address.streetAddress && <a href={`http://maps.google.com/maps?q=${sr.address.streetAddress}`} target='blank'>Map Location</a>}</div>
                                    {
                                        sr.slots.length > 0 && <div className="OPD-Result-Book-Link" onClick={() => this.handleBookClick(sr)}>Book Now</div>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    getSearchCriteria() {
        return this.state.search && this.state.searchResults && this.state.hasSearched ?
            <div className="OPD-Search-Criteria-Container">
                <div className="OPD-Search-Criteria-Label">You Searched For</div>
                {
                    Object.keys(this.state.search).map((k, kidx) => {
                        return (
                            <div key={`search-${kidx}`} className="OPD-Search-Criteria-Item-Container">
                                <div className="OPD-Search-Criteria-Item-Label">{k}: </div><div className="OPD-Search-Criteria-Item-Value">{this.state.search[k].label || this.state.search[k]}</div>
                            </div>
                        )
                    })
                }
            </div> : null
    }

    getSearchButtons() {
        return (
            this.state.searchResults && this.state.hasSearched ?
                <div>
                    <div className="OPD-Search-Buttons-Container">
                        <div className="OPD-Search-Button-Container OPD-Search-Refine-Button" onClick={() => this.handleDisplaySearch()}>
                            <i className="fas fa-filter" style={{ marginRight: '5px' }}></i> Refine Search Criteria
                    </div>
                        <div className="OPD-Search-Button-Container OPD-Search-New-Button" onClick={() => this.handleStartNewSearch()}>
                            <i className="fas fa-search" style={{ marginRight: '5px' }}></i> Start a New Search
                    </div>
                    </div>
                    <div className="OPD-Search-Criteria-Results">{this.state.searchResults && this.state.searchResults.length > 0 ? `${this.state.searchResults.length} Providers Found` : "Oops! We couldn't find any providers matching your criteria. Try modifying your request."}</div>
                </div>
                : null
        )
    }

    loadAppointments() {
        API.post('gwhpp', '/athena?action=getAppointments', {
            body: {
                patientid: ''
            }
        })

    }

    handleCancelAppointment(appt) {
        if (appt) {
            API.post('gwhpp', '/athena?action=cancelAppointment', {
                body: {
                    patientid: '',
                    appointmentid: '',
                    cancellationreason: ''
                }
            })

        }
    }

    getAppointments() {
        return (
            <div className="OPD-Appointments-Container">
                {
                    this.state.appointments.map((appt, apptidx) => {
                        return (
                            <div key={`appt-${apptidx}`} className="OPD-Appointment-Container">
                                <div className="OPD-Appointment-Row">
                                    Appointment {appt.appointmentid}
                                </div>
                                <div className="OPD-Appointment-Row">
                                    details 1
                                </div>
                                <div className="OPD-Appointment-Row">
                                    <PrettyTB field={this.state.fields[12]} onValueChange={this.handleValueChange} />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )



    }

    getConfirmationPage() {
        return (
            this.state.selectedSlot &&
            <div className="OPD-Conf-Container">
                <div className="OPD-Conf-Row">
                    <div className="OPD-Provider-Details-Back" onClick={() => this.handleProviderSelect(null)}>
                        <i className="fas fa-arrow-circle-left"></i> Back to Search
                        </div>
                </div>
                {
                    <div className="OPD-Conf-Row">
                        <div className="OPD-Conf-Status">
                            <div className="OPD-Conf-Status-Icon">
                                <i className={this.state.scheduleIcon}></i>
                            </div>
                            <div className="OPD-Conf-Status-Loading">

                            </div>
                            <div className="OPD-Conf-Status-Msg">
                                {this.state.scheduleMsg}
                            </div>
                        </div>
                    </div>
                }
                <div className="OPD-Conf-Row">
                    Appointments
                </div>
            </div>
        )
    }

    render() {
        console.log(this.state, this.props)
        return (
            <div className="OPD-Container">
                <div className="OPD-Top-Header-Container">
                    <div className="OPD-Header-Item OPD-Header-Item-Left">FloridaBlue.com</div>
                    <div className="OPD-Header-Item OPD-Header-Item-Right">Support</div>
                    <div className="OPD-Header-Item OPD-Header-Item-Right">Glossery of Terms</div>
                    <div className="OPD-Header-Item OPD-Header-Item-Right"><i className="fab fa-accessible-icon"></i> Accessibility <i className="fas fa-caret-down"></i></div>
                    <div className="OPD-Header-Item OPD-Header-Item-Right">Espanol</div>
                </div>
                <div className="OPD-Header-Container">
                    <div className="OPD-Header-Item OPD-Header-Item-Left"><img className="OPD-Header-Img" src={FB} alt="Florida Blue" /></div>
                    <div className="OPD-Header-Item OPD-Header-Item-Right"><div className="OPD-Account-Container"><i className="fas fa-user" style={{ marginRight: "5px" }}></i> My Account</div></div>
                    <div className="OPD-Header-Item OPD-Header-Item-Right"><div className="OPD-Message-Container"><i className="fas fa-envelope" style={{ marginRight: "5px" }}></i> Message Center</div></div>
                    <div className="OPD-Header-Item OPD-Header-Item-Right"><div className="OPD-Member-ID-Container">Health: H00000000</div></div>
                    <div style={{ marginTop: "5px" }} className="OPD-Header-Item OPD-Header-Item-Right">Currently Viewing Your:</div>
                </div>
                <div className="OPD-Menu-Header-Container">
                    <div className="OPD-Header-Item OPD-Header-Item-Left">Claims &amp; Expenses <i className="fas fa-caret-down"></i></div>
                    <div className="OPD-Header-Item OPD-Header-Item-Left">My Plan <i className="fas fa-caret-down"></i></div>
                    <div className="OPD-Header-Item OPD-Header-Item-Left">Tools <i className="fas fa-caret-down"></i></div>
                    <div className="OPD-Header-Item OPD-Header-Item-Left">Health & Wellness <i className="fas fa-caret-down"></i></div>
                </div>
                <div className="OPD-Subject-Header-Container">
                    <div className="OPD-Header-Item OPD-Header-Item-Left">Find a Doctor &amp; More</div>
                    <div className="OPD-Header-Item OPD-Header-Item-Right"><span className="OPD-Menu-Need-Help">Need Help <i className="fas fa-question-circle" style={{ marginLeft: '10px' }}></i></span></div>
                </div>
                {!this.state.selectedProvider && this.getSearchCriteria()}
                {!this.state.selectedProvider && this.getSearchButtons()}
                {!this.state.selectedProvider && this.getSearchForm()}
                {!this.state.selectedProvider && this.getNewSearchForm()}
                {!this.state.selectedProvider && this.getSearchResults()}
                {!this.state.selectedSlot && this.state.selectedProvider && !this.state.isBooking && this.getSelectedProvider()}
                {!this.state.selectedSlot && this.state.selectedProvider && this.state.isBooking && this.getBookingPage()}
                {this.state.selectedSlot && this.getConfirmationPage()}
            </div>
        )
    }
}

export default Athena