import React, { Component } from 'react'
import { API } from 'aws-amplify'
import { Glyphicon } from 'react-bootstrap'
import uuid from 'uuid'
import qs from 'query-string'
import '../components/appt.css'
import ReactHtmlParser from 'react-html-parser'

const url = "https://test.healow.com/apps/api/v1/fhir/AGAEBD/oauth/authorize"
const client_id = '8d5e957f297893487bd98fa830fa6413'
const redirect_uri = "https://gwpp-fhir.com/myecw"
const response_type = "code"

class MyECW extends Component { 
    constructor(props) {
        super(props)

        this.state = {
            code: null,
            token: null,
            state: null,
            patient: null,
        }
    }

    async componentWillMount() {
        if (!this.props.user || !this.props.user[0] || this.props.user[0].restrict) this.props.history.push('/')
        console.log('here 1')
        if (!this.props.ecw.code && qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code) {
            const code = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code
            console.log('here 2', code)
            this.props.setECW({ ...this.props.ecw, code })
            const resp = await API.get('ecw', `/ecwtoken?code=${code}`)
            if (resp.status === 200) {
                console.log('here 3, resp:    ', resp)
                if (resp.data) {
                    const patient = resp.data.patient
                    const token = resp.data.access_token
                    this.props.setECW({ ...this.props.ecw, token, patient })
                    this.getData()
                }
            } else {
                console.log('here 4', resp.status)
                const state = uuid.v1()
                this.setState({ state })
                window.location = `${url}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&state=${state}`
            }
        } else if (this.props.ecw.patient && this.props.ecw.token) {
            console.log('hear 6')
            this.getData()
        } else {
            console.log('here 5')
            const state = uuid.v1()
            this.setState({ state })
            window.location = `${url}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&state=${state}`
        }
    }

    getData() {
        const patient = this.props.ecw.patient
            const token = this.props.ecw.token
            if (!this.props.ecw.demographics) {
                API.post('ecw', `/ecwData/${patient}?element=Patient`, {
                    body: { token }
                }).then(demographics => {
                    this.props.setECW({ ...this.props.ecw, demographics })
                })
            }
            if (!this.props.ecw.medicationStatement) {
                API.post('ecw', `/ecwData/${patient}?element=MedicationStatement`, {
                    body: { token }
                }).then(medicationStatement => {
                    this.props.setECW({ ...this.props.ecw, medicationStatement })
                })
            }
            if (!this.props.ecw.medicationRequest) {
                API.post('ecw', `/ecwData/${patient}?element=MedicationRequest`, {
                    body: { token }
                }).then(medicationRequest => {
                    this.props.setECW({ ...this.props.ecw, medicationRequest })
                })
            }
            if (!this.props.ecw.problems) {
                API.post('ecw', `/ecwData/${patient}?element=Condition&category=problem`, {
                    body: { token }
                }).then(problems => {
                    this.props.setECW({ ...this.props.ecw, problems })
                })
            }
            if (!this.props.ecw.labs) {
                API.post('ecw', `/ecwData/${patient}?element=DiagnosticReport&category=LAB`, {
                    body: { token }
                }).then(labs => {
                    this.props.setECW({ ...this.props.ecw, labs })
                })
            }
            if (!this.props.ecw.labResults) {
                API.post('ecw', `/ecwData/${patient}?element=Observation&category=laboratory`, {
                    body: { token }
                }).then(labResults => {
                    this.props.setECW({ ...this.props.ecw, labResults })
                })
            }
            if (!this.props.ecw.vitals) {
                API.post('ecw', `/ecwData/${patient}?element=Observation&category=vital-signs`, {
                    body: { token }
                }).then(vitals => {
                    this.props.setECW({ ...this.props.ecw, vitals })
                })
            }
            if (!this.props.ecw.procedures) {
                API.post('ecw', `/ecwData/${patient}?element=Procedure`, {
                    body: { token }
                }).then(procedures => {
                    this.props.setECW({ ...this.props.ecw, procedures })
                })
            }
            if (!this.props.ecw.careTeam) {
                API.post('ecw', `/ecwData/${patient}?element=CareTeam`, {
                    body: { token }
                }).then(careTeam => {
                    this.props.setECW({ ...this.props.ecw, careTeam })
                })
            }
            if (!this.props.ecw.immunization) {
                API.post('ecw', `/ecwData/${patient}?element=Immunization`, {
                    body: { token }
                }).then(immunization => {
                    this.props.setECW({ ...this.props.ecw, immunization })
                })
            }
            if (!this.props.ecw.device) {
                API.post('ecw', `/ecwData/${patient}?element=Device`, {
                    body: { token }
                }).then(device => {
                    this.props.setECW({ ...this.props.ecw, device })
                })
            }
            if (!this.props.ecw.carePlan) {
                API.post('ecw', `/ecwData/${patient}?element=CarePlan&category=assess-plan`, {
                    body: { token }
                }).then(carePlan => {
                    this.props.setECW({ ...this.props.ecw, carePlan })
                })
            }
            if (!this.props.ecw.goal) {
                API.post('ecw', `/ecwData/${patient}?element=Goal`, {
                    body: { token }
                }).then(goal => {
                    this.props.setECW({ ...this.props.ecw, goal })
                })
            }
            if (!this.props.ecw.concerns) {
                API.post('ecw', `/ecwData/${patient}?element=Condition&category=health-concern`, {
                    body: { token }
                }).then(concerns => {
                    this.props.setECW({ ...this.props.ecw, concerns })
                })
            }
            if (!this.props.ecw.dataRequest) {
                API.post('ecw', `/ecwData/${patient}?element=DocumentReference&type=34133-9`, {
                    body: { token }
                }).then(dataRequest => {
                    this.props.setECW({ ...this.props.ecw, dataRequest })
                })
            }
    }

    handleGetECW() {
        const state = uuid.v1()
        this.setState({ state })
        window.location = `${url}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&state=${state}`
    }

    getEcwSection(section, display) {
        if (this.props.ecw[section] && this.props.ecw[section].status && this.props.ecw[section].status === 200) {
            if (section === 'demographics') {
                return (
                    <div className="ECW-Item-Container">
                        <h2>{display}</h2>
                        {ReactHtmlParser(this.props.ecw[section].data.text.div)}
                    </div>
                )
            } else if (section === 'dataRequest') {
                return (
                    <div className="ECW-Item-Container">
                        <h2>{display}</h2>
                        {this.props.ecw[section].data.entry.map((d) => {
                            return (
                                d.resource.content.map((c, cidx) => {
                                    return <div key={`cdr-${cidx}`}><img src={`data:image/jpeg;base64,${c.attachment.data}`} alt="attachment" /></div>
                                }))
                        })
                        }
                    </div>
                )
            } else if (section === 'labs') {
                return (
                    <div className="ECW-Item-Container">
                        <h2>{display}</h2>
                        {this.props.ecw[section].data.entry && this.props.ecw[section].data.entry.map((l, lidx) => {
                            return (
                                <div key={`l-${lidx}`}>
                                    {
                                        ReactHtmlParser(l.resource.text.div)
                                    }
                                    {
                                        l.resource.result.map((r, ridx) => {
                                            return (
                                                <div key={`r-${ridx}`}>
                                                    {
                                                        ReactHtmlParser(r.display)
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            )
                        })}
                    </div>
                )
            } else {
                return (
                    <div className="ECW-Item-Container">
                        <h2>{display}</h2>
                        {this.props.ecw[section].data.entry && this.props.ecw[section].data.entry.map((x, xidx) => {
                            return (
                                <div key={`x-${xidx}`}>{ReactHtmlParser(x.resource.text.div)}</div>
                            )
                        })}
                    </div>
                )
            }
        } else {
            return  (
                <div className="ECW-Item-Container">
                    <div className="ECW-Item-Loading"><Glyphicon glyph="refresh" className="ECW-Item-Working" />Loading {display}...</div>
                </div>
            )
        }
    }

    render() {
        console.log('my ecw    ', this.props, this.state)
        return (
            <div style={{ width: '100%' }}>
                <div className="BlueButton-Container">
                    <div className="BlueButton" onClick={() => this.handleGetECW()}>
                        eClinical Works
                    </div>
                </div>
                {this.getEcwSection('demographics', 'Demographics')}
                {this.getEcwSection('problems', 'Problems')}
                {this.getEcwSection('medicationStatement', 'Medication Statement')}
                {this.getEcwSection('medicationRequest', 'Medication Requests')}
                {this.getEcwSection('allergies', 'Allergies')}
                {this.getEcwSection('procedures', 'Procedures')}
                {this.getEcwSection('careTeam', 'Care Team')}
                {this.getEcwSection('carePlan', 'Care Plan')}
                {this.getEcwSection('concerns', 'Concerns')}
                {this.getEcwSection('goal', 'Goal')}
                {this.getEcwSection('device', 'Device')}
                {this.getEcwSection('immunization', 'Immunization')}
                {this.getEcwSection('labs', 'Labs')}
                {this.getEcwSection('labResults', 'Lab Results')}
                {this.getEcwSection('vitals', 'Vital Statistics')}
            </div>
        )
    }
}

export default MyECW
