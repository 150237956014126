import React, { Component } from 'react'
import moment from 'moment'
import Checkmark from '../../images/checkmark.svg'
import PrimaryCare from '../../images/primarycare.svg'
import SpecialtyCare from '../../images/specialtycare.svg'
import Diagnostic from '../../images/diagnostic.svg'
import Laboratory from '../../images/laboratory.svg'
import './appt.css'

class Confirm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            css: '',
            rev: false
        }
    }

    componentWillMount() {
        this.setState({ css: this.state.rev ? 'Schedule-Form-Left' : 'Schedule-Form-Right' })
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ css: '' })
        }, 250)
    }

    render() {
        console.log(this.props)
        return (
            <div className={`Schedule-Form-Container ${this.state.css}`}>
                <div className="Schedule-Form-Header">
                    Appointment Confirmed!
                </div>
                <div className="Schedule-Form-Content">
                    <div className="Confirm-Container">
                        <div className="Confirm-Item">
                            <div className="CareType-Option-Container">
                                {this.props.careType ?
                                    (this.props.careType === 'Laboratory' ? <img src={Laboratory} alt="Primary Care" className="CareType-Option-Img" /> :
                                        (this.props.careType === 'Diagnostic Care' ? <img src={Diagnostic} alt="Diagnostic Care" className="CareType-Option-Img" /> :
                                            (this.props.careType === "Specialty Care" ? <img src={SpecialtyCare} alt="Specialty Care" className="CareType-Option-Img" /> :
                                                <img src={PrimaryCare} alt="Primary Care" className="CareType-Option-Img" />))) : null}
                                <div className="CareType-Option-Label">
                                    {this.props.careType || null}
                                </div>
                            </div>
                        </div>
                        <div className="Confirm-Item Confirm-Item-Body">
                            <div className="Confirm-Item-Title">Practitioner</div>
                            <div className="Confirm-Item-Value">{this.props.provider && this.props.provider.provider && this.props.provider.provider.name}</div>
                            <div className="Confirm-Item-Value-Sub">{this.props.provider && this.props.provider.provider && this.props.provider.provider.phone}</div>
                            <div className="Confirm-Item-Title">Location</div>
                            <div className="Confirm-Item-Value">{this.props.provider && this.props.provider.address}</div>
                            <div className="Confirm-Item-Value-Sub">{this.props.provider && this.props.provider.distance && this.props.provider.distance.text}</div>
                            <div className="Confirm-Item-Title">Date/Time</div>
                            <div className="Confirm-Item-Value">{this.props.slot && this.props.slot.start ? moment(this.props.slot.start).format('LLL') : null}</div>
                        </div>
                    </div>
                    <div className="Confirmed-Button-Container">
                        <div className="Confirmed-Button">
                            <img src={Checkmark} alt="Confirmed!" />
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default Confirm