import React, { Component } from 'react'
import moment from 'moment'
import { HelpBlock, FormGroup, FormControl, ControlLabel } from 'react-bootstrap'
import './fields.css'

class PrettyTB extends Component {

    componentWillMount() {
        if(!this.props.field.answer) {
            if(this.props.field.default) {
                switch(this.props.field.default) {
                    case 'today':
                        this.props.onValueChange(this.props.field, moment(new Date()).format("MMDDYYYY"))
                        break
                    default: 
                        break
                }
            }
        }
    }

    getValidation() {
        switch (this.props.field.validation) {
            case 'dob':
                return this.state.ln.length === 10 ? 'success' : 'error'
            case 'email':
                return this.props.field.answer && this.props.field.answer.length > 0 ? (this.props.field.answer.indexOf('.') < 0 || this.props.field.answer.indexOf('@') < 0 ? 'warning' : 'success') : 'error'
            case 'pw':
                return this.props.field.answer && this.props.field.answer.length > 0 ? (this.props.field.answer.length >= 8 ? 'success' : 'warning') : 'error'
            case 'name':
                return this.props.field.answer && this.props.field.answer.length > 0 ? 'success' : 'error'
            default:
                return 'warning'
        }
    }

    handleChange(val) {
        if(this.props.idx) {
            this.props.onValueChange(this.props.field, {"text": val}, this.props.idx)
        } else {
            this.props.onValueChange(this.props.field, val)
        }
    }

    displayDateFormat(date) {
        let newDate = date.substring(0,2) 
        if(date.length > 2) newDate += "/" + date.substring(2,4)
        if(date.length > 4) newDate += "/" + date.substring(4)
        return newDate
    }

    displayPhoneFormat(phone) {
        let newPhone = "(" + phone.substring(0,3) 
        if(phone.length > 3) newPhone += ") " + phone.substring(3,6)
        if(phone.length > 6) newPhone += "-" + phone.substring(6)
        return newPhone
    }

    displaySSNFormat(ssn) {
        let newSSN = ssn.substring(0,3) 
        if(ssn.length > 3) newSSN += "-" + ssn.substring(3,5)
        if(ssn.length > 5) newSSN += "-" + ssn.substring(5)
        return newSSN
    }

    displayZipFormat(zip) {
        let newZip = zip.substring(0,5) 
        if(zip.length > 5) newZip += "-" + zip.substring(5)
        return newZip
    }

    getTB() {
        switch(this.props.field.subtype || "text") {
            case "date": 
                return <FormControl
                    placeholder={ this.props.field.placeholder ? this.props.field.placeholder : null }
                    type="text"
                    maxLength={ this.props.field.max ? this.props.field.max : null }
                    value={this.props.field.answer ? (this.props.idx ? this.displayDateFormat(this.props.field.answer.text) : this.displayDateFormat(this.props.field.answer)) : ""}
                    onChange={event => this.handleChange(event.target.value.replace(/\D/g, "")) } />
            case "phone": 
                return <FormControl
                    placeholder={ this.props.field.placeholder ? this.props.field.placeholder : null }
                    type="text"
                    maxLength={ this.props.field.max ? this.props.field.max : null }
                    value={this.props.field.answer ? (this.props.idx ? this.displayPhoneFormat(this.props.field.answer.text) : this.displayPhoneFormat(this.props.field.answer)) : ""}
                    onChange={event => this.handleChange(event.target.value.replace(/\D/g, "")) } />
            case "ssn": 
                return <FormControl
                    placeholder={ this.props.field.placeholder ? this.props.field.placeholder : null }
                    type="text"
                    maxLength={ this.props.field.max ? this.props.field.max : null }
                    value={this.props.field.answer ? (this.props.idx ? this.displaySSNFormat(this.props.field.answer.text) : this.displaySSNFormat(this.props.field.answer)) : ""}
                    onChange={event => this.handleChange(event.target.value.replace(/\D/g, "")) } />
            case "zip": 
                return <FormControl
                    placeholder={ this.props.field.placeholder ? this.props.field.placeholder : null }
                    type="text"
                    maxLength={ this.props.field.max ? this.props.field.max : null }
                    value={this.props.field.answer ? (this.props.idx ? this.displayZipFormat(this.props.field.answer.text) : this.displayZipFormat(this.props.field.answer)) : ""}
                    onChange={event => this.handleChange(event.target.value.replace(/\D/g, "")) } />
            default:
                return <FormControl
                    placeholder={ this.props.field.placeholder ? this.props.field.placeholder : null }
                    type="text"
                    maxLength={ this.props.field.max ? this.props.field.max : null }
                    value={ this.props.field.answer ? (this.props.idx ? this.props.field.answer.text : this.props.field.answer) : "" }
                    onChange={event => this.handleChange(event.target.value) } />
        }
    }

    render() {
        const style = this.props.field.max ? {width: `${(this.props.field.max * 11)+50}px`} : {width: "auto"}
        return (
            <FormGroup style={style} controlId={`tb-${this.props.field.id}`} bsSize="large" validationState={ this.props.field.validation ? this.getValidation() : null } >
                <ControlLabel>{this.props.field.label}</ControlLabel>
                { this.getTB() }
                { this.props.field.help && this.getValidation() !== 'success' ? 
                    <HelpBlock>{ this.props.field.help }</HelpBlock> : null
                }
            </FormGroup>
        )
    }
}

export default PrettyTB