import React, { Component } from 'react'
import DatePicker from 'react-datepicker';
import moment from 'moment'
import { API } from 'aws-amplify'
import './appt.css'
import 'react-datepicker/dist/react-datepicker.css';

class Providers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            css: '',
            rev: false,
            apptDate: null,
            evalDate: moment().format('L'),
            zip: null,
            addr: null,
            coord: null,
            providers: []
        }
        this.handleDateChange = this.handleDateChange.bind(this)
    }

    componentWillMount() {
        this.setState({ css: this.state.rev ? 'Schedule-Form-Left' : 'Schedule-Form-Right', providers: this.props.providers })
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ css: '' })
        }, 250)
        this.handleGetLoc()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.providers) {
            this.setState({ providers: nextProps.providers })
            let pattern = /\d{5}/
            if (pattern.test(this.state.zip) && this.state.providers.filter(p => !p.distance).length > 0) this.getProviderDist()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.zip !== this.state.zip || prevState.addr !== this.state.addr) {
            let pattern = /\d{5}/
            if (pattern.test(this.state.zip)) this.getProviderDist()
        }
    }

    handleGetLoc() {
        let gl = navigator.geolocation
        gl ? gl.getCurrentPosition(position => {
            this.setState({ zip: null, coord: { lat: position.coords.latitude.toFixed(7), lng: position.coords.longitude.toFixed(7) } })
            this.handleLocationChange({ lat: position.coords.latitude.toFixed(7), lng: position.coords.longitude.toFixed(7) })
        }, err => {
            console.log(err)
        }) : console.log('geo failed')
    }

    async handleLocationChange(loc) {
        try {
            const addr = await API.get('gwhpp', `/geo?coord=${loc.lat},${loc.lng}`)
            if (addr && addr.data && addr.data.results[0]) {
                let zip = addr.data.results[0].formatted_address.split(', ')
                this.handleZipChange(zip[zip.length - 2].substr(3), addr.data.results[0].formatted_address)
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    handleZipChange(zip, addr) {
        this.setState({ zip, addr: addr || zip })
    }

    handleSelection(slot, provider) {
        console.log('selected    ', slot, provider)
        if (slot.id) {
            this.setState({ css: this.state.rev ? 'Schedule-Form-Right' : 'Schedule-Form-Left' })
            this.props.onSlotSelected(slot, provider)
        }
    }

    handleDateChange(date) {
        this.setState({ apptDate: date, evalDate: moment(date).format('L') })
        this.props.onDateChanged(moment(date).format('YYYY-MM-DD'))
    }

    getProviderDist() {
        console.log('get distance!!!!')
        let providers = JSON.parse(JSON.stringify(this.state.providers))
        providers.forEach(provider => {
            API.get('gwhpp', `/geo?origin=${this.state.addr || this.state.zip}&destination=${provider.address}`)
                .then(dist => {
                    if (dist.data && dist.data.rows[0] && dist.data.rows[0].elements[0] && dist.data.rows[0].elements[0].distance) provider.distance = dist.data.rows[0].elements[0].distance
                    this.setState({ providers })
                })
        })
    }

    getProviders() {
        return (
            this.state.providers
                .sort((a, b) => {
                    let x = a.distance ? a.distance.value : 0
                    let y = b.distance ? b.distance.value : 0
                    if (x < y) return -1
                    if (y > x) return 1
                    return 0
                })
                .map((p, pidx) => {
                    //console.log('provider is    ', p)
                    return (
                        <div key={`provider-${pidx}`}>
                            {
                                pidx === 0 || p.address !== this.state.providers[pidx - 1].address ?
                                    <div className="Providers-List-Row">
                                        <div className="Providers-List-Location">
                                            {p.address}
                                        </div>
                                    </div> : null
                            }
                            <div className={`Providers-List-Row ${p.network ? '' : 'Providers-OON'}`}>
                                <div className="Providers-List-Name">
                                    {p.provider.name}
                                    {
                                        p.departmentName ? <div className="Providers-List-Dept">{p.departmentName}</div> : null
                                    }
                                    <div className="Providers-List-Phone">{p.provider.phone}</div>
                                </div>
                                <div className="Providers-List-Item Providers-List-Dist">{p.distance && p.distance.text ? p.distance.text : '?'}</div>
                                <div className="Providers-List-Item Providers-Network">{p.network ? 'In Network' : 'Out of Network'}</div>
                                <div className="Providers-List-Item Providers-Slots">
                                    {
                                    p.slots && p.slots.filter(s => moment(s.start).format('L') === this.state.evalDate).length > 0 ?
                                        p.slots.filter(s => moment(s.start).format('L') === this.state.evalDate).map((s, sidx) => {
                                            return <div key={`p-${pidx}-s-${sidx}`} className="Providers-List-Item Providers-List-Slot" onClick={() => this.handleSelection(s, p)}>{moment(s.start).format('h:mm A')}</div>
                                        }) : <div className="Providers-List-None">No Availability for {moment(this.state.evalDate).format('ll')}</div>
                                }
                                </div>                                
                            </div>
                        </div>
                    )
                })
        )
    }

    render() {
        console.log(this.state, this.props)
        return (
            <div className={`Schedule-Form-Container Providers-Form-Fix ${this.state.css}`}>
                <div className="Schedule-Form-Header">
                    What location and time works best for you?
                </div>
                <div className="Providers-Container">
                    <div className="Providers-Row-Container">
                        <div className="Providers-Row">
                            <div className="Providers-Button">
                                <span className="Providers-Button-Text" onClick={() => this.handleNextAvailable()}>First Available</span>
                            </div>
                            <div className="Providers-Button">
                                <DatePicker selected={this.state.apptDate || new Date()} onChange={this.handleDateChange} placeholderText="Select a date" todayButton={`Today (${moment().format('ll')})`} minDate={new Date()} />
                            </div>
                            <label htmlFor="tb-loc">
                                ZIP Code
                            </label>
                            <input type="text" name="tb-loc" value={this.state.zip || ''} className="Providers-Location-Input" onChange={event => this.handleZipChange(event.target.value)} />
                            <div className="Providers-Button" onClick={() => this.handleGetLoc()}>
                                <span className="Providers-Button-Text"><i className="fas fa-map-marker-alt"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="Providers-List-Row">
                        <div className="Providers-List-Name">
                            Provider
                        </div>
                        <div className="Providers-List-Item Providers-List-Title-Dist">
                            Distance
                        </div>
                        <div className="Providers-List-Item Providers-List-Name Providers-Network">
                            Network
                        </div>
                        <div className="Providers-List-Item Providers-List-Name">
                            Times Available
                        </div>
                    </div>
                    {this.getProviders()}
                </div>
            </div>
        )
    }
}

export default Providers


/*

[
                {
                    "facility": "Family Medicine",
                    "provider": {
                        "id": "01",
                        "npi": "1245484666",
                        "name": "Dr. Alden E Sanborn",
                        "phone": "407.843.2550"
                    },
                    "address": "100 W Gore St Suite 605, Orlando, FL - 32806",
                    "network": true,
                    "slots": [
                        "12/30/2019 11:00",
                        "12/31/2019 09:30",
                        "01/06/2020 13:00"
                    ]
                },
                {
                    "facility": "Family Medicine",
                    "provider": {
                        "id": "02",
                        "npi": "1144260605",
                        "name": "Dr. Nicanor Christopher Arca",
                        "phone": "407.233.1844"
                    },
                    "address": "300 E Church St, Orlando, FL - 32801",
                    "network": true,
                    "slots": [
                        "12/31/2019 09:00",
                        "01/02/2020 10:15",
                        "01/07/2020 14:00"
                    ]
                },
                {
                    "facility": "Cardiologist",
                    "provider": {
                        "id": "03",
                        "npi": "1346247970",
                        "name": "Dr. Jose H Arias",
                        "phone": "407.894.4474"
                    },
                    "address": "1613 N Mills Ave, Orlando, FL - 32803",
                    "network": true,
                    "slots": [
                        "12/30/2019 12:00",
                        "12/31/2019 11:30",
                        "01/13/2020 09:30"
                    ]
                },
                {
                    "facility": "Cardiologist",
                    "provider": {
                        "id": "04",
                        "npi": "1821182247",
                        "name": "Dr. Robert B Boswell",
                        "phone": "407.896.0054"
                    },
                    "address": "2320 N Orange Ave, Orlando, FL - 32804",
                    "network": true,
                    "slots": [
                        "01/06/2020 09:30",
                        "01/07/2020 11:00",
                        "01/09/2020 15:00"
                    ]
                },
                {
                    "facility": "Family Medicine",
                    "provider": {
                        "id": "05",
                        "npi": "1790941268",
                        "name": "Dr. Javier E Sandoval",
                        "phone": "407.207.5000"
                    },
                    "address": "3151 N Alafaya Trl Suite 101, Orlando, FL - 32826",
                    "network": true,
                    "slots": [
                        "01/02/2020 10:00",
                        "01/08/2020 13:30",
                        "01/15/2020 09:00"
                    ]
                },
                {
                    "facility": "Family Medicine",
                    "provider": {
                        "id": "06",
                        "npi": "1750393179",
                        "name": "Dr. Larry D Brown",
                        "phone": "407.397.7032"
                    },
                    "address": "7848 W Irlo Bronson Memorial HWY, Kissimmee, FL - 34747",
                    "network": true,
                    "slots": [
                        "12/30/2019 11:00",
                        "01/07/2020 12:00",
                        "01/13/2020 14:30"
                    ]
                },
                {
                    "facility": "Family Medicine",
                    "provider": {
                        "id": "07",
                        "npi": "1336150432",
                        "name": "Dr. Saeed Ahmad",
                        "phone": "407.518.7700"
                    },
                    "address": "1520 N John Young PKWY, Kissimmee, FL - 34741",
                    "network": true,
                    "slots": [
                        "12/30/2019 14:00",
                        "01/03/2020 11:00",
                        "01/06/2020 15:30"
                    ]
                }
            ]

*/