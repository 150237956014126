import React, { Component } from "react"
import Slider from '../components/slider'
import "./home.css"

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      "slides": [
        {
          "img": "/images/content1.jpg",
          "title": !this.props.user || !this.props.user[0] || this.props.user[0].restrict ? 'Welcome to the Payer Experience Portal' : 'Welcome to GuideWell Patient Portal',
          "body": "If you are sick, we make you better. If it is broken, we will fix it.",
          "target": "#"
        },
        {
          "img": "/images/content2.png",
          "title": "All the care you need. All in one place.",
          "body": "Family Care. Urgent Care. Labs. X-rays. Count on us to put your care first.",
          "target": "#"
        },
        {
          "img": "/images/content3.jpg",
          "title": "Online Appointments",
          "body": "Now you can book your Medical Appointment Online.  It's fast and easy.",
          "target": "#"
        }
      ]
    }
  }
  render() {
    return (
      <div className="Home">
        <div className="lander">
          <h1 style={{color: '#fff'}}>{!this.props.user || !this.props.user[0] || this.props.user[0].restrict ? 'Payer Experience Portal' : 'Demo Patient Experience'}</h1>
          <p>Interoperablility POC Playground</p>
        </div>
        <div className="Slider-Container">
          <Slider slideshow={ this.state.slides } />
        </div>
      </div>
    )
  }
}

export default Home