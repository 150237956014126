import React, { Component } from 'react'
import { DropdownButton, MenuItem, InputGroup } from 'react-bootstrap'
import './fields.css'

class PrettyCBGRB extends Component {
    constructor(props) {
        super(props)

        this.state = {
            width: 3
        }//*11)+50
    }

    handleChange(val) {
        if(typeof(this.props.idx) === "undefined") {
            this.props.onValueChange(this.props.field, this.props.field.answer && this.props.field.answer.val === val.val ? {} : val)
        } else {
            this.props.onValueChange(this.props.field, this.props.field.answer && this.props.field.answer.val === val.val ? {} : val, this.props.idx)
        }
    }

    getOptions() {
        return (
            this.props.field.options.map((o, idx) => {
                if(o.label.length > this.state.width) this.setState({width: o.label.length})
                return (
                    <MenuItem key={`ddi-${idx}`} eventKey={ o }>
                        { o.label }
                    </MenuItem>
                )
            })
        )
    }

    render() {
        return (
            <InputGroup>
                <div style={{ fontWeight: "bold", color: this.props.field.required && !this.props.field.answer ?  "#a94442" : (this.props.field.required && this.props.field.answer ? "#3c763d" : null) }}>{ this.props.field.label }</div>
                <DropdownButton style={{ fontSize: 'inherit', padding: '12px', marginTop: '3px', minWidth: '100px', width: `${(12*this.state.width)+50}px` }}
                    id={`ddb-${this.props.field.id}`} 
                    title={  this.props.field.answer ? this.props.field.answer.label : "Select..." } 
                    onSelect={event => this.handleChange(event)}>
                    { this.getOptions() }
                </DropdownButton>
            </InputGroup>
        )
    }
}

export default PrettyCBGRB