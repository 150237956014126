import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import Routes from './routes'
import { Auth, API } from 'aws-amplify'
import Logo from './images/gw-no-bg.png'
import LogoWhite from './images/GW-white-no-bg.png'
import Navs from './components/navs'
import Alert from './components/alert'
import "./App.css"

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: null,
      loadingCss: 'App-Loading-Bar-Init',
      loadedCss: 'App-Faded',
      interactions: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      cms: {},
      ecw: {},
      alerts: false,
      filter: [],
      tid: null,
      alertsData: null,
      jid: null,
      links: [],
      mbrId: null,
      touchstone: false,
      lara: null
    }
    this.handleClearAlert = this.handleClearAlert.bind(this)
    this.toggleAlerts = this.toggleAlerts.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleUserClick = this.handleUserClick.bind(this)
    this.toggleTouchStone = this.toggleTouchStone.bind(this)
  }

  async componentDidMount() {
    try {
      await Auth.currentSession()
      let currUser = await Auth.currentAuthenticatedUser()
      let user = await API.get('member', `/member/${currUser.username}`)
      this.setUser(user)
      this.userHasAuthenticated(true)
      this.setState({ loadingCss: 'App-Loading-Bar-Final' })
      if (this.state.alerts) {
        const tid = setInterval(() => {
          API.get('gwhpp', `/resource?res=alerts&action=fetch&touchstone=${this.state.touchstone}`).then(alertsData => {
            if (alertsData && alertsData.data) {
              this.setState({ alertsData: alertsData.data.entry || null })
            }
          })
        }, 5000)
        this.setState({ tid })
      }
    } catch (e) {
      if (e.message !== 'No Current User') console.log('error    ', e)
    }

    setTimeout(() => this.setState({ loadingCss: 'App-Loading-Bar-Post' }), 230)
    setTimeout(() => this.setState({ isAuthenticating: false, loadedCss: 'App-Showing' }), 500)
  }

  componentWillUnmount() {
    clearInterval(this.state.tid)
    this.setState({ tid: null })
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated })
  }

  setUser = user => { this.setState({ user }) }
  setInteractions = interactions => { this.setState({ interactions }) }
  setCMS = cms => { this.setState({ cms }) }
  setECW = ecw => { this.setState({ ecw }) }
  setAlerts = alertsData => { this.setState({ alertsData }) }
  setJID = jid => { this.setState({ jid }) }
  setMbrID = mbrId => { this.setState({ mbrId }) }

  async handleLogout() {
    try {
      await Auth.signOut({ global: true })
    } catch (e) {
      console.log(e.message)
    }
    this.userHasAuthenticated(false)
    this.props.history.push('/login')
  }

  handleClearAlert(id) {
    API.get('gwhpp', `/resource?res=alerts&action=process&id=${id}&touchstone=${this.state.touchstone}`)
  }

  toggleTouchStone() {
    this.setState({ touchstone: !this.state.touchstone })
  }

  toggleAlerts() {
    let tid = null
    if (!this.state.alerts) {
      tid = setInterval(() => {
        API.get('gwhpp', `/resource?res=alerts&action=fetch&touchstone=${this.state.touchstone}`).then(alertsData => {
          if (alertsData && alertsData.data) {
            this.setState({ alertsData: alertsData.data.entry || null })
          }
        })
      }, 5000)
    } else {
      clearInterval(this.state.tid)
    }
    this.setState({ alerts: !this.state.alerts, tid: tid })
  }

  handleUserClick() {
    this.props.history.push('/')
  }

  getGreeting() {
    if (this.state.isAuthenticated) {
      if (this.props.location.pathname === '/davinci') return 'Welcome to da Vinci'
      if (this.props.location.pathname === '/searchmrp' || this.props.location.pathname === '/edi' || this.props.location.pathname === '/members' || this.props.location.pathname === '/auth') {
        return this.state.user && this.state.user[0] && `Welcome Back, ${this.state.user[0].userName || 'User'}`
      } else {
        return this.state.user && this.state.user[0] && `Welcome Back, ${this.state.user[0].firstName || 'Member'}`
      }
    } else {
      return null
    }
  }

  getNavColor() {
    if (this.props.location.pathname === '/searchmrp' || this.props.location.pathname === '/edi' || this.props.location.pathname === '/members' || this.props.location.pathname === '/auth') {
      return '#530053'
    } else {
      return null
    }
  }

  getLogo() {
    if (this.props.location.pathname === '/searchmrp' || this.props.location.pathname === '/edi' || this.props.location.pathname === '/members' || this.props.location.pathname === '/auth') {
      return LogoWhite
    } else {
      return Logo
    }
  }

  showAlerts() {
    if (this.props.location.pathname === '/searchmrp' || this.props.location.pathname === '/edi' || this.props.location.pathname === '/members' || this.props.location.pathname === '/auth') return true
    return false
  }

  getLinks() {
    if (this.state.isAuthenticated) {
      switch (this.props.location.pathname) {
        case "/":
          return [
            { addr: "/appointment", text: "Schedule Appointment" },
            { addr: "/appointments", text: "Appointments" },
            { addr: "/aboutme", text: "About Me" },
            { addr: "/crd", text: "My Coverage" },
            { addr: "/mycms", text: "My CMS" },
            { addr: "/rx", text: "My Rx" }
          ]
        case "/searchmrp":
          return [
            { addr: "/searchmrp", text: "MRP Search" },
            { addr: "/edi", text: "EDI to FHIR" },
            { addr: "/members", text: "Member Care" },
            { addr: "/auth", text: "Search Auths" }
          ]
        case "/edi":
          return [
            { addr: "/searchmrp", text: "MRP Search" },
            { addr: "/edi", text: "EDI to FHIR" },
            { addr: "/members", text: "Member Care" },
            { addr: "/auth", text: "Search Auths" }
          ]
        case "/auth":
          return [
            { addr: "/searchmrp", text: "MRP Search" },
            { addr: "/edi", text: "EDI to FHIR" },
            { addr: "/members", text: "Member Care" },
            { addr: "/auth", text: "Search Auths" }
          ]
        case "/appointment":
          return [
            { addr: "/appointment", text: "Schedule Appointment" },
            { addr: "/appointments", text: "Appointments" },
            { addr: "/aboutme", text: "About Me" },
            { addr: "/crd", text: "My Coverage" },
            { addr: "/mycms", text: "My CMS" },
            { addr: "/rx", text: "My Rx" }
          ]
        case "/appointments":
          return [
            { addr: "/appointment", text: "Schedule Appointment" },
            { addr: "/appointments", text: "Appointments" },
            { addr: "/aboutme", text: "About Me" },
            { addr: "/crd", text: "My Coverage" },
            { addr: "/mycms", text: "My CMS" },
            { addr: "/rx", text: "My Rx" }
          ]
        case "/aboutme":
          return [
            { addr: "/appointment", text: "Schedule Appointment" },
            { addr: "/appointments", text: "Appointments" },
            { addr: "/aboutme", text: "About Me" },
            { addr: "/crd", text: "My Coverage" },
            { addr: "/mycms", text: "My CMS" },
            { addr: "/rx", text: "My Rx" }
          ]
        case "/crd":
          return [
            { addr: "/appointment", text: "Schedule Appointment" },
            { addr: "/appointments", text: "Appointments" },
            { addr: "/aboutme", text: "About Me" },
            { addr: "/crd", text: "My Coverage" },
            { addr: "/mycms", text: "My CMS" },
            { addr: "/rx", text: "My Rx" }
          ]
        case "/mycms":
          return [
            { addr: "/appointment", text: "Schedule Appointment" },
            { addr: "/appointments", text: "Appointments" },
            { addr: "/aboutme", text: "About Me" },
            { addr: "/crd", text: "My Coverage" },
            { addr: "/mycms", text: "My CMS" },
            { addr: "/rx", text: "My Rx" }
          ]
        case "/signup":
          return [
            { addr: "/appointment", text: "Schedule Appointment" },
            { addr: "/appointments", text: "Appointments" },
            { addr: "/aboutme", text: "About Me" },
            { addr: "/crd", text: "My Coverage" },
            { addr: "/mycms", text: "My CMS" },
            { addr: "/rx", text: "My Rx" }
          ]
        case "/login":
          return [
            { addr: "/appointment", text: "Schedule Appointment" },
            { addr: "/appointments", text: "Appointments" },
            { addr: "/aboutme", text: "About Me" },
            { addr: "/crd", text: "My Coverage" },
            { addr: "/mycms", text: "My CMS" },
            { addr: "/rx", text: "My Rx" }
          ]
        case "/rx":
          return [
            { addr: "/appointment", text: "Schedule Appointment" },
            { addr: "/appointments", text: "Appointments" },
            { addr: "/aboutme", text: "About Me" },
            { addr: "/crd", text: "My Coverage" },
            { addr: "/mycms", text: "My CMS" },
            { addr: "/rx", text: "My Rx" }
          ]
        case "/members":
          return [
            { addr: "/searchmrp", text: "MRP Search" },
            { addr: "/edi", text: "EDI to FHIR" },
            { addr: "/members", text: "Member Care" },
            { addr: "/auth", text: "Search Auths" }
          ]
        case "/davinci":
          return []
        case "/opd":
          return [

          ]
        default:
          return [
            { addr: "/signup", text: "Sign Up" },
            { addr: "/login", text: "Log In" }
          ]
      }
    } else {
      return [
        { addr: "/signup", text: "Sign Up" },
        { addr: "/login", text: "Log In" }
      ]
    }
  }

  render() {
    console.log('app    ', this.state, this.props)
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setUser: this.setUser,
      user: this.state.user,
      interactions: this.state.interactions,
      setInteractions: this.setInteractions,
      cms: this.state.cms,
      setCMS: this.setCMS,
      ecw: this.state.ecw,
      setECW: this.setECW,
      alerts: this.state.alertsData,
      setAlerts: this.setAlerts,
      jid: this.state.jid,
      setJID: this.setJID,
      setMbrID: this.setMbrID,
      mbrId: this.state.mbrId,
      touchstone: this.state.touchstone
    }

    return (
      !this.state.isAuthenticating ?
        <div className={`App ${this.state.loadedCss}`} style={{ backgroundColor: `url(${this.props.location.pathname === '/searchmrp' || this.props.location.pathname === '/edi' ? 'rgb(238, 238, 238)' : '#fff'})` }}>
          <Navs logo={this.getLogo()}
            text={this.getGreeting()}
            links={this.getLinks()}
            bgColor={this.getNavColor()}
            showAlerts={this.showAlerts()}
            alerts={this.state.alerts}
            alertsActive={true}
            onAlertClick={this.toggleAlerts}
            onLogOut={this.handleLogout}
            onUserClick={this.handleUserClick}
            isAuth={this.state.isAuthenticated}
            touchstone={this.state.touchstone}
            onTouchstone={this.toggleTouchStone} />
          <Routes childProps={childProps} />
          {this.state.alerts && this.state.alertsData && this.state.alertsData[0] ? <Alert data={this.state.alertsData} onClearAlert={this.handleClearAlert} /> : null}
        </div> :
        <div className="App-Loading">
          <div className={`App-Loading-Bar ${this.state.loadingCss}`}></div>
        </div>
    )
  }
}

export default withRouter(App)