import React, { Component } from "react"
import { API } from 'aws-amplify'
import { ArcherContainer, ArcherElement } from 'react-archer'
import { Glyphicon } from 'react-bootstrap'
import "./start.css"

class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            locs: [],
            jrny: [],
            infoOpen: false,
            fhirOpen: false,
            infoTitle: null,
            infoBody: null,
            infoUCs: [],
            tasks: [],
            currJrny: null,
            bundle: {},
            isLoading: false,
            refreshText: "Refresh"
        }
    }

    async componentDidMount() {
        try {
            const jrny = await API.get('gwhpp', `/journey?tbl=lara-journey`)
            if (window.innerWidth < 1610) jrny.forEach(j => j.archerElement.relations.forEach(r => { if (r.label && r.label.text) r.label.text = "" }))
            this.setState({ jrny })
        } catch (e) {
            alert('Journey Data Error: ' + e.message)
        }
        try {
            const bundle = await API.get('gwhpp', `/resource?res=everything&id=${this.props.mbrId || '214'}`)
            this.setState({ bundle })
        } catch (e) {
            alert('FHIR Data Error: ' + e.message)
        }    
    }

    handleInfoOpen() {
        this.setState({ infoOpen: !this.state.infoOpen, fhirOpen: false })
    }

    handleFhirOpen() {
        this.setState({ fhirOpen: !this.state.fhirOpen, infoOpen: false })
    }

    handleInfo(info) {
        info ? this.setState({
            infoTitle: info.subject,
            infoBody: info.desc,
            infoUCs: info.usecases,
            currJrny: info.jid
        }) : this.setState({
            infoTitle: null,
            infoBody: null,
            infoUCs: [],
            currJrny: null
        })
    }

    handleShowTasks(jid, party) {
        let tasks = JSON.parse(JSON.stringify(this.state.tasks))
        if (tasks.some(t => t.jid === jid && t.party === party)) {
            tasks.splice(tasks.findIndex(t => t.jid === jid && t.party === party), 1)
        } else {
            tasks.push({ jid, party })
        }
        this.setState({ tasks })
    }

    handleUsecaseClick(uc) {
        if (uc.active) {
            this.props.setJID(this.state.currJrny)
            return this.props.history.push(uc.path)
        }
    }

    async handleRefreshLara() {
        this.setState({ isLoading: true, refreshText: "Retrieving..." })
        const bundle = await API.get('gwhpp', `/resource?res=everything&id=${this.props.mbrId || '214'}`)
        this.setState({ bundle, isLoading: false, refreshText: "Refresh" })
    }

    getJourney() {
        let sJrny = JSON.parse(JSON.stringify(this.state.jrny)).sort((a, b) => {
            let x = a.jid
            let y = b.jid
            if (x < y) return -1
            if (x > y) return 1
            return 0
        }).sort((a, b) => {
            let x = a.row
            let y = b.row
            if (x < y) return -1
            if (x > y) return 1
            return 0
        })
        let rows = []
        let scenes = []
        sJrny.forEach(sj => { if (rows.indexOf(sj.row) === -1) rows.push(sj.row) })
        rows.forEach((r => {
            scenes.push(
                <div key={`row-${r}`} className='Start-Scenes-Row'>
                    {
                        sJrny.filter(sj => sj.row === r).map((s, sidx) => {
                            s.archerElement.relations.forEach(r => r.label = <div style={r.label.style}>{r.label.text}</div>)
                            return (
                                <ArcherElement key={`ae-${s.archerElement.id}`} id={s.archerElement.id}
                                    relations={s.archerElement.relations}>
                                    <div className={`Start-Scene-Container ${this.state.currJrny === s.jid ? 'Start-Scene-Container-Selected' : ''}`}>
                                        <div className='Start-Scene-Title' onClick={() => this.handleInfo(s)} onMouseOver={() => this.handleInfo(s)}>{s.subject}</div>
                                        <div className='Start-Scene-Actors'>
                                            {
                                                s.roles.map((role, ridx) => {
                                                    return (
                                                        <div key={`role-${ridx}`} className='Start-Scene-Actor' onClick={() => this.handleShowTasks(s.jid, role.party)}>
                                                            <div className='Start-Scene-Actor-Icon'>
                                                                <i className={role.icon}></i>
                                                            </div>
                                                            <div className='Start-Scene-Actor-Title'>{role.party}</div>
                                                            {
                                                                this.state.tasks.some(t => t.jid === s.jid && t.party === role.party) ?
                                                                    <div className='Start-Actor-Tasks' onClick={() => this.handleShowTasks(s.jid, role.party)}>
                                                                        {
                                                                            role.tasks.map((task, tid) => {
                                                                                return (
                                                                                    <div key={`task-${tid}`} className='Start-Actor-Task'>{task}</div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </ArcherElement>
                            )
                        })
                    }
                </div>
            )
        }))
        return (
            <ArcherContainer strokeColor='blue' strokeWidth='3' arrowThickness='5' >
                {
                    scenes.map((s, sidx) => {
                        return s
                    })
                }
            </ArcherContainer>
        )
    }

    render() {
        return (
            <div className="">
                <div className={`Start-Info-Container ${this.state.infoOpen ? 'Start-Info-Container-Open' : 'Start-Info-Container-Close'}`}>
                    <div className='Start-Scene-Title'>{this.state.infoTitle}</div>
                    <div className='Start-Scene-Body'>{this.state.infoBody}</div>
                    <div className='Start-Scene-Usecases'>
                        {
                            this.state.infoUCs.map((uc, ucidx) => {
                                return (
                                    <div key={`usecase-${ucidx}`} style={uc.style && uc.active ? uc.style : null} className={`Start-Scene-Usecase ${uc.active ? 'Start-Scene-Usecase-Active' : 'Start-Scene-Usecase-Inactive'}`} onClick={() => this.handleUsecaseClick(uc)}>
                                        <div className='Start-Scene-Actor-Icon'>
                                            <i className={uc.icon}></i>
                                        </div>
                                        <div className='Start-Scene-Actor-Title'>{uc.usecase}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='Start-Info-Tab' onClick={() => this.handleInfoOpen()}>
                        <i className='fas fa-chevron-circle-right'></i>
                    </div>
                </div>
                <div className={`Start-Scenes-Container ${this.state.infoOpen ? 'Start-Scenes-Container-Right' : (this.state.fhirOpen ? 'Start-Scenes-Container-Left' : 'Start-Scenes-Container-Center')}`}>
                    {this.getJourney()}
                </div>
                <div className={`Start-FHIR-Container ${this.state.fhirOpen ? 'Start-FHIR-Container-Open' : 'Start-FHIR-Container-Close'}`}>
                    <div className='Start-Scene-Title'>
                        FHIR Bundle
                        <div className="Start-FHIR-Refresh" onClick={() => this.handleRefreshLara()}>
                            <div className="Start-FHIR-Refresh-Text">{this.state.refreshText}</div>
                            {
                                this.state.isLoading && <Glyphicon glyph="refresh" className="Start-FHIR-Refresh-Spinning" />
                            }
                        </div>
                    </div>
                    <div className='Start-FHIR-Body'><pre>{JSON.stringify(this.state.bundle, undefined, 2)}</pre></div>
                    <div className='Start-FHIR-Tab' onClick={() => this.handleFhirOpen()}>
                        <i className='fas fa-chevron-circle-left'></i>
                    </div>
                </div>
            </div>

        )
    }
}

export default Home