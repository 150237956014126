import React, { Component } from 'react'
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap'
import LoaderButton from '../components/loaderButton'
import { Auth, API } from 'aws-amplify'
import './login.css'

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            pw: '',
            isLoading: false
        }
    }

    formIsValid() {
        return this.state.email.length > 0 && this.state.pw.length > 0
    }

    handleSubmit = async event => {
        event.preventDefault()
        this.setState({ isLoading: true })
        try {
            await Auth.signIn(this.state.email, this.state.pw)
            let currUser = await Auth.currentAuthenticatedUser()
            let user = await API.get('member', `/member/${currUser.username}`)
            this.props.setUser(user)
            this.props.userHasAuthenticated(true)
            console.log('Success - go to main page')
            this.props.history.push('/')
        } catch (e) {
            alert(e.message)
            console.log(e)
            this.setState({ isLoading: false })
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    getValidation(field) {
        return this.state[field].length > 0 ? 'success' : 'error'
    }

    render() {
        return (
            <div className="Login">
                <form onSubmit={ this.handleSubmit }>
                    <FormGroup controlId="email" bsSize="large" validationState={ this.getValidation('email') }>
                        <ControlLabel>Email</ControlLabel>
                        <FormControl
                            autoFocus
                            placeholder="Enter your email address"
                            type="email"
                            value={ this.state.email }
                            onChange={ this.handleChange }
                        />
                    </FormGroup>
                    <FormGroup controlId="pw" bsSize="large" validationState={ this.getValidation('pw') }>
                        <ControlLabel>Password</ControlLabel>
                        <FormControl
                            placeholder="Enter your password"
                            value={ this.state.pw }
                            onChange={ this.handleChange }
                            type="password"
                        />
                    </FormGroup>
                    <LoaderButton
                        block
                        bsSize="large"
                        disabled={ !this.formIsValid() }
                        type="submit"
                        isLoading={ this.state.isLoading }
                        text="Login"
                        loadingText="Logging in..."
                    />
                </form>
            </div>
        )
    }
}

export default Login 