export default {
    apiGateway: {
        REGION: "us-east-2",
        URL: "https://irep9f1dsg.execute-api.us-east-2.amazonaws.com/prod"
    },
    apiGateway2: {
        REGION: "us-east-2",
        URL: "https://fz8t3c17l7.execute-api.us-east-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: 'us-east-2',
        USER_POOL_ID: 'us-east-2_HdvHaPHkU',
        APP_CLIENT_ID: '1fv4qfohita9snqhr9r2lhmg2g',
        IDENTITY_POOL_ID: 'us-east-2:59fa5a4f-8400-4a1d-b350-616892807b78'
    }
}
