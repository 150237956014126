import React, { Component } from 'react'
import PrimaryCare from '../../images/primarycare.svg'
import SpecialtyCare from '../../images/specialtycare.svg'
import Diagnostic from '../../images/diagnostic.svg'
import Laboratory from '../../images/laboratory.svg'
import './appt.css'

class CareType extends Component {
    constructor(props) {
        super(props)

        this.state = {
            css: '',
            rev: false
        }
    }

    componentWillMount() {
        this.setState({ css: this.state.rev ? 'Schedule-Form-Left' : 'Schedule-Form-Right' })
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ css: '' })
        }, 250)
    }


    handleSelection(val) {
        this.setState({ css: this.state.rev ? 'Schedule-Form-Right' : 'Schedule-Form-Left' })
        this.props.onTypeSelected(val)
    }

    render() {
        console.log(this.state)
        return (
            <div className={`Schedule-Form-Container ${this.state.css}`}>
                <div className="Schedule-Form-Header">
                    What type of care are you looking for?
                </div>
                <div className="Schedule-Form-Content">
                    <div className="CareType-Option-Container" onClick={() => this.handleSelection('Primary Care')}>
                        <img src={PrimaryCare} alt="Primary Care" className="CareType-Option-Img" />
                        <div className="CareType-Option-Label">
                            Primary Care
                        </div>
                    </div>
                    <div className="CareType-Option-Container" onClick={() => this.handleSelection('Specialty Care')}>
                        <img src={SpecialtyCare} alt="Specialty Care" className="CareType-Option-Img" />
                        <div className="CareType-Option-Label">
                            Specialty Care
                        </div>
                    </div>
                    <div className="CareType-Option-Container" onClick={() => this.handleSelection('Diagnostic Care')}>
                        <img src={Diagnostic} alt="Diagnostic Care" className="CareType-Option-Img" />
                        <div className="CareType-Option-Label">
                            Diagnostic Care
                        </div>
                    </div>
                    <div className="CareType-Option-Container" onClick={() => this.handleSelection('Laboratory')}>
                        <img src={Laboratory} alt="Laboratory Care" className="CareType-Option-Img" />
                        <div className="CareType-Option-Label">
                            Laboratory
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CareType
