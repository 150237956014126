import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom"
import { Nav, Navbar, NavItem } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import './alert.css'

class Navs extends Component {

    handleOnAlertClick() {
        this.props.onAlertClick()
    }

    handleLogOutClick() {
        this.props.onLogOut()
    }

    handleUserClick() {
        this.props.onUserClick()
    }

    handleTouchstoneClick() {
        this.props.onTouchstone()
    }

    render() {
        return (
            <Navbar fluid collapseOnSelect style={{zIndex: 2, backgroundColor: this.props.bgColor}}>
                <Navbar.Header>
                    <Navbar.Brand style={{ padding: "0", color: "#fff" }}>
                        <Link to="/davinci"><img src={this.props.logo} style={{ height: '50px', marginTop: '-13px' }} alt='GuideWell' /></Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav pullRight>
                        <Fragment>
                            <Navbar.Text onClick={() => this.handleUserClick()}><span style={{ color: '#009fae', cursor: 'pointer' }}>{this.props.text}</span></Navbar.Text>
                            { <Navbar.Text>{this.props.touchstone ? <i className={`fas fa-toggle-on ${this.props.touchstone ? 'Alerts-Icon-Red' : 'Alerts-Icon'}`} onClick={() => this.handleTouchstoneClick()}></i> : <i className="fas fa-toggle-off" style={{ color: '#ccc', cursor: 'pointer' }} onClick={() => this.handleTouchstoneClick()}></i>}</Navbar.Text> }
                            { this.props.showAlerts ? <Navbar.Text>{this.props.alerts ? <i className={`far fa-bell ${this.props.alertsActive ? 'Alerts-Icon-Red' : 'Alerts-Icon'}`} onClick={() => this.handleOnAlertClick()}></i> : <i className="far fa-bell-slash" style={{ color: '#ccc', cursor: 'pointer' }} onClick={() => this.handleOnAlertClick()}></i>}</Navbar.Text> : null }
                            {
                                this.props.links.map((l, lidx) => {
                                    return (
                                    <LinkContainer key={`link-${lidx}`} to={l.addr}><NavItem style={{padding: '10px 5px'}}>{l.text}</NavItem></LinkContainer>
                                    )
                                })
                            }
                            {
                                this.props.isAuth ? <NavItem onClick={() => this.handleLogOutClick()}>Logout</NavItem> : null
                            }
                        </Fragment>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Navs
