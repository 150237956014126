import React, { Component } from 'react'
import { API } from 'aws-amplify'
import './mrp.css'


class EDI extends Component {
    constructor(props) {
        super(props)

        this.state = {
            members: [
                {
                    name: 'LARA MIRREN',
                    id: 'GREATHEALTH01253',
                    files: [
                        {
                            label: 'EDI 835 (X12)',
                            val: '835.txt',
                            loaded: false
                        },
                        {
                            label: 'EDI 837 (X12)',
                            val: '837.txt',
                            loaded: false
                        }
                    ]
                },
                {
                    name: 'AMANDA ADAMS',
                    id: 'GREATHEALTH01253',
                    files: [
                        {
                            label: 'EDI 835 (X12)',
                            val: '835.txt',
                            loaded: false
                        },
                        {
                            label: 'EDI 837 (X12)',
                            val: '837.txt',
                            loaded: false
                        }
                    ]
                },
                {
                    name: 'ANNA WEST',
                    id: 'GREATHEALTH02527',
                    files: [
                        {
                            label: 'EDI 835 (X12)',
                            val: '835.txt',
                            loaded: false
                        },
                        {
                            label: 'EDI 837 (X12)',
                            val: '837.txt',
                            loaded: false
                        }
                    ]
                },
                {
                    name: 'DARA KIM',
                    id: 'GREATHEALTH03322',
                    files: [
                        {
                            label: 'EDI 835 (X12)',
                            val: '835.txt',
                            loaded: false
                        },
                        {
                            label: 'EDI 837 (X12)',
                            val: '837.txt',
                            loaded: false
                        }
                    ]
                },
                {
                    name: 'WILLIAM PAISLEY',
                    id: 'GREATHEALTH04992',
                    files: [
                        {
                            label: 'EDI 835 (X12)',
                            val: '835.txt',
                            loaded: false
                        },
                        {
                            label: 'EDI 837 (X12)',
                            val: '837.txt',
                            loaded: false
                        }
                    ]
                },
                {
                    name: 'SUSAN COLLINS',
                    id: 'GREATHEALTH05861',
                    files: [
                        {
                            label: 'EDI 835 (X12)',
                            val: '835.txt',
                            loaded: false
                        },
                        {
                            label: 'EDI 837 (X12)',
                            val: '837.txt',
                            loaded: false
                        }
                    ]
                },
                {
                    name: 'CHARLES MOORE',
                    id: 'GREATHEALTH06671',
                    files: [
                        {
                            label: 'EDI 835 (X12)',
                            val: '835.txt',
                            loaded: false
                        },
                        {
                            label: 'EDI 837 (X12)',
                            val: '837.txt',
                            loaded: false
                        }
                    ]
                }
            ],
            isLoading: false,
            isLoadingFHIR: false,
            displaySelections: false,
            searchSelection: null,
            search: null,
            mbrMatches: [],
            mbr: null,
            file: null,
            fileDisplay: null,
            fileIdx: null,
            tid: null
        }
    }

    handleOnClick(mbr) {
        this.setState({ mbrMatches: [], search: mbr.name, mbr, file: null, fileDisplay: null })
    }

    handleSelector(sel) {
        this.setState({ displaySelections: sel ? sel : !this.state.displaySelections, file: null, fileDisplay: null })
    }

    handleSelection(sel) {
        this.setState({
            displaySelections: false,
            searchSelection: sel,
            mbrMatches: this.state.members.filter(m => {
                if (this.state.searchSelection) {
                    return (
                        this.state.searchSelection === 'Member Name' ? this.state.search && m.name.indexOf(this.state.search) >= 0 : this.state.search && m.id.indexOf(this.state.search.toString()) >= 0
                    )
                } else {
                    return m.name.indexOf(this.state.search) >= 0 || m.id.indexOf(this.state.search) >= 0
                }
            })
        })
    }

    handleSearchTextChange(event) {
        const search = event.target.value
        clearTimeout(this.state.tid)
        this.setState({
            tid: null,
            displaySelections: false,
            mbr: null,
            file: null,
            fileDisplay: null,
            search: search.length > 0 ? search.toUpperCase() : null
        })

        let tid = setTimeout(() => {
            this.setState({
                mbrMatches: search.length ? this.state.members.filter(m => {
                    if (this.state.searchSelection) {
                        return (
                            this.state.searchSelection === 'Member Name' ? search && m.name.indexOf(search.toUpperCase()) >= 0 : search && m.id.indexOf(search.toString().toUpperCase()) >= 0
                        )
                    } else {
                        return m.name.indexOf(search.toUpperCase()) >= 0 || m.id.indexOf(search.toUpperCase()) >= 0
                    }
                }) : []
            })
        }, 1000)

        this.setState({ tid })
    }

    getMembers() {
        return (
            <div className="EDI-Members-Container">
                {this.state.mbrMatches.map((mbr, midx) => {
                    return (
                        <div key={`mbr-${midx}`} className="EDI-Member-Container" style={{ cursor: 'pointer' }} onClick={() => this.handleOnClick(mbr)}>
                            <div className="EDI-Member-Section">
                                <div className="EDI-Member-Title">Member</div>
                                <div className="EDI-Member-Value">{mbr.name}</div>
                            </div>
                            <div className="EDI-Member-Section">
                                <div className="EDI-Member-Title">Subscriber ID</div>
                                <div className="EDI-Member-Value">{mbr.id}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    getFiles() {
        return (
            this.state.mbr ?
                <div className="MRP-Search-Container">
                    <div className="MRP-Row">
                        <div className="EDI-Member-Container" >
                            <div className="EDI-Member-Section">
                                <div className="EDI-Member-Title">Member</div>
                                <div className="EDI-Member-Value">{this.state.mbr.name}</div>
                            </div>
                            <div className="EDI-Member-Section">
                                <div className="EDI-Member-Title">Subscriber ID</div>
                                <div className="EDI-Member-Value">{this.state.mbr.id}</div>
                            </div>
                        </div>
                    </div>
                    <div className="MRP-Row">
                        {
                            this.state.mbr.files.map((file, fidx) => {
                                return (
                                    <div className="EDI-File-Container" key={`file-${fidx}`} onClick={() => this.handleShowFile(file.val, fidx)}>
                                        <div className="EDI-File-Icon"><i className="fas fa-file-medical"></i></div>
                                        <div className="EDI-File-Text">{file.label}</div>
                                        <div className="EDI-File-Type"><i className={file.val === '835.txt' ? 'fas fa-notes-medical' : 'far fa-hospital'}></i></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div> : null
        )
    }

    handleShowFile(file, fidx) {
        this.setState({ isLoading: true })
        try {
            fetch(`/${file}`)
                .then(f => f.text())
                .then(text => {
                    let newText = text.replace('AMANDA*ADAMS', this.state.mbr.name.replace(' ', '*'))
                    newText = newText.replace('Adams*Amanda', this.state.mbr.name.replace(' ', '*'))
                    console.log(newText)
                    this.setState({ file: text, fileDisplay: newText, fileIdx: fidx, isLoading: false })
                })
        } catch (e) {
            alert(e.message)
            this.setState({ isLoading: false })
        }
    }

    async handlePush() {
        if (this.state.file && !this.state.mbr.files[this.state.fileIdx].loaded) {
            this.setState({ isLoadingFHIR: true })
            try {
                const resp = await API.post('gwpp', '/edi', { body: { edi: this.state.file } })
                if (resp.status === 200) {
                    let tmpMbrs = JSON.parse(JSON.stringify(this.state.members))
                    let tmpMbr = JSON.parse(JSON.stringify(this.state.mbr))
                    tmpMbr.files[this.state.fileIdx].loaded = true
                    tmpMbrs.forEach((m, idx) => { if (m.id === tmpMbr.id) tmpMbrs[idx] = tmpMbr })
                    this.setState({ members: tmpMbrs, mbr: tmpMbr, isLoadingFHIR: false })
                } else {
                    console.log('ERROR    ', resp)
                    this.setState({ isLoadingFHIR: false })
                    //alert(resp.status)
                }
            } catch (e) {
                console.log(e)
                //alert(e.message)
                this.setState({ isLoadingFHIR: false })
            }
        }
    }

    render() {
        console.log(this.state)
        return (
            <div className="MRP">
                <div className="MRP-Row">
                    <div className="MRP-Search-Container">
                        <div className="MRP-Row MRP-Search-Label">Member Smart Search</div>
                        <div className="MRP-Row MRP-Search-Outter">
                            <div className="EDI-Search-Selector" onClick={() => this.handleSelector()}>
                                {this.state.searchSelection ? this.state.searchSelection : 'Member (Name/ID)'}
                                <div className="MRP-Search-Selector-Arrow"></div>
                            </div>
                            <input type="text" value={this.state.search || ''} onChange={event => this.handleSearchTextChange(event)} className="EDI-Search-Bar" placeholder={`Search by ${this.state.searchSelection ? this.state.searchSelection : "Patient (Name/ID)"}`}></input>
                            <div className="MRP-Search-Button"><i className="fas fa-search"></i></div>
                        </div>
                        {
                            this.state.displaySelections &&
                            <div className="MRP-Row MRP-Search-Selections">
                                <div className="MRP-Search-Selections-Row" onClick={() => this.handleSelection()}>Member (Name/ID)</div>
                                <div className="MRP-Search-Selections-Row" onClick={() => this.handleSelection('Member Name')}>Member Name</div>
                                <div className="MRP-Search-Selections-Row" onClick={() => this.handleSelection('Member ID')}>Member ID</div>
                            </div>
                        }
                    </div>
                </div>
                <div className="MRP-Search-Container">
                    <div className="MRP-Row">
                        {this.getMembers()}
                    </div>
                    <div className="MRP-Row">
                        {this.getFiles()}
                    </div>
                    {
                        this.state.file ?
                            <div className="MRP-Row">
                                <textarea className="EDI-Textarea" readOnly rows="10" cols="120" value={this.state.fileDisplay}></textarea>
                                {this.state.mbr && typeof (this.state.fileIdx) === 'number' && !this.state.mbr.files[this.state.fileIdx].loaded ? <div className="EDI-Button" onClick={() => this.handlePush()}>Load to FHIR Server{this.state.isLoadingFHIR && <i className="fas fa-spinner"></i>}</div> : null}
                            </div> : null
                    }
                </div>
            </div>
        )
    }
}

export default EDI 