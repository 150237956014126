import React, { Component } from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'
import { Glyphicon } from 'react-bootstrap'
import './appts.css'

class Appointments extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cPatient: null,
            appts: [],
            fetching: false
        }
    }

    componentWillMount() {
        this.setState({ fetching: true })
        API.get('gwhpp', `/resource?res=schedule&action=appointments&senderOrg=healow&id=${this.props.mbrId || '214'}&touchstone=${this.props.touchstone}`)
            .then(appts => {
                if (appts && appts.data && appts.data && appts.data[0]) {
                    this.setState({
                        appts: appts.data.map((a, aidx) => {
                            return {
                                id: aidx + 1,
                                description: 'Primary Care Visit',
                                start: a.slot.start || null,
                                duration: `${a.slot.duration} min` || null,
                                stop: a.slot.end || null,
                                practitioner: a.provider && a.provider.provider && a.provider.provider.name ? a.provider.provider.name : null,
                                patient: 'Mirren, Lara',
                                status: 'booked'
                            }
                        })
                    })
                }
            })
            .catch(e => {
                console.log(e.message)
            })
        API.get('gwhpp', `/resource?res=schedule&senderOrg=cerner&action=patient&id=${this.props.user && this.props.user[0] && this.props.user[0].mbrId ? this.props.user[0].mbrId : '7328007'}&touchstone=${this.props.touchstone}`)
            .then(patient => {
                if (patient && patient.data && patient.data.entry && patient.data.entry[0] && patient.data.entry.length === 1 && patient.data.entry[0].resource) {
                    this.setState({ cPatient: patient.data.entry[0].resource })
                }
                this.setState({ fetching: false })
            })
            .catch(e => {
                console.log(e.message)
                this.setState({ fetching: false })
            })
    }

    componentDidMount() {
        this.props.user && this.props.user[0] && this.props.user[0].memberNumber && API.post('gwhpp', '/athena?action=getPatientId', { body: { hccid: this.props.user[0].memberNumber } })
            .then(patient => {
                if (patient && patient[0] && patient[0].patientid) {
                    API.post('gwhpp', '/athena?action=getAppointments', { body: { patientId: patient[0].patientid } })
                        .then(appts => {
                            if (appts && appts.appointments) {
                                this.setState({
                                    appts: [...this.state.appts, ...appts.appointments.map((a, aidx) => {
                                        return {
                                            id: a.appointmentid,
                                            description: a.appointmenttypeid,
                                            start: moment(`${a.date} ${a.starttime}`,'MM/DD/YYYY hh:mm').format('lll') || null,
                                            duration: `${a.duration} min` || null,
                                            stop: moment(`${a.date} ${a.starttime}`).add(a.duration, 'minutes').format('lll') || null,
                                            practitioner: a.providerid,
                                            patient: `${this.props.user[0].firstName} ${this.props.user[0].lastName}`,
                                            status: 'booked'
                                        }
                                    })]
                                })
                            }
                        })
                        .catch(e => {
                            console.log(e.message)
                        })
                }
                this.setState({ fetching: false })
            })
            .catch(e => {
                console.log(e.message)
                this.setState({ fetching: false })
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.cPatient && this.state.cPatient) {
            API.get('gwhpp', `/resource?res=schedule&action=appointments&senderOrg=cerner&id=${this.state.cPatient.id}&touchstone=${this.props.touchstone}`)
                .then(appts => {
                    if (appts && appts.data && appts.data.entry && appts.data.entry[0]) {
                        this.setState({
                            appts: [...this.state.appts, ...appts.data.entry.filter(appt => appt.resource && appt.resource.resourceType && appt.resource.resourceType === 'Appointment').map(a => {
                                return {
                                    id: a.resource.id || null,
                                    description: a.resource.description || null,
                                    start: a.resource.start || null,
                                    duration: a.resource.minutesDuration || null,
                                    stop: a.resource.end || null,
                                    practitioner: a.resource.participant && a.resource.participant[0] && a.resource.participant.findIndex(p => p.actor && p.actor.reference && p.actor.display && p.actor.reference.indexOf('Practitioner/') === 0) >= 0 ? a.resource.participant[a.resource.participant.findIndex(p => p.actor.reference.indexOf('Practitioner/') === 0)].actor.display : null,
                                    patient: a.resource.participant && a.resource.participant[0] && a.resource.participant.findIndex(p => p.actor && p.actor.reference && p.actor.display && p.actor.reference.indexOf('Patient/') === 0) >= 0 ? a.resource.participant[a.resource.participant.findIndex(p => p.actor.reference.indexOf('Patient/') === 0)].actor.display : null,
                                    status: a.resource.status || null
                                }
                            })]
                        })
                        this.setState({ fetching: false })
                    }
                })
                .catch(e => {
                    console.log(e.message)
                    this.setState({ fetching: false })
                })

        }
    }

    getAppointment(appt) {
        console.log('appt     ', appt)
        return (
            <div className="Appointment-Item-Container">
                <div className="Appointment-Title-Container">
                    <div className="Appointment-Item-Row">
                        <div className="Appointment-Title-Desc">{appt.description || null}</div>
                    </div>
                    <div className="Appointment-Item-Row">
                        <div className="Appointment-Item-Left">
                            <div className="Appointment-Item-Label">Status</div>
                            <div className="Appointment-Item-Value">{appt.status || null}</div>
                        </div>
                        <div className="Appointment-Item-Right">
                            <div className="Appointment-Item-Label">ID</div>
                            <div className="Appointment-Item-Value">{appt.id || null}</div>
                        </div>
                    </div>
                </div>
                <div className="Appointment-Item-Row">
                    <div className="Appointment-Item-Left">
                        <div className="Appointment-Item-Label">Patient</div>
                        <div className="Appointment-Item-Value">{appt.patient || null}</div>
                    </div>
                    <div className="Appointment-Item-Right">
                        <div className="Appointment-Item-Label">Practitioner</div>
                        <div className="Appointment-Item-Value">{appt.practitioner || null}</div>
                    </div>
                </div>
                <div className="Appointment-Item-Row">
                    <div className="Appointment-Item-Left">
                        <div className="Appointment-Item-Label">Start</div>
                        <div className="Appointment-Item-Value">{appt.start ? moment(appt.start).format('lll') : null}</div>
                    </div>
                    <div className="Appointment-Item-Right">
                        <div className="Appointment-Item-Label">End</div>
                        <div className="Appointment-Item-Value">{appt.stop ? moment(appt.stop).format('lll') : null}</div>
                    </div>
                </div>
                <div className="Appointment-Item-Row">
                    <div className="Appointment-Item-Only">
                        <div className="Appointment-Item-Label">Duration</div>
                        <div className="Appointment-Item-Value">{appt.duration || null}</div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        console.log(this.state)
        return (
            <div className="Appointments-Container">
                <div className="Appointments-Title">
                    {this.state.fetching && <Glyphicon glyph="refresh" className="Confirm-Loading" />}
                    {`${!this.state.fetching ? this.state.appts.length : ''} Upcoming Appointment${this.state.appts.length !== 1 ? 's' : ''}`}
                </div>
                {
                    this.state.appts.sort((a, b) => {
                        let x = a.start ? moment(a.start) : 0
                        let y = b.start ? moment(b.start) : 0
                        if (x > y) return 1
                        if (x < y) return -1
                        return 0
                    }).map((appt, aidx) => {
                        return (
                            <div key={`appt-${aidx}`}>{this.getAppointment(appt)}</div>
                        )
                    })
                }
            </div>
        )
    }
}

export default Appointments