import React, { Component } from 'react'
import CareType from '../../components/appt/careType'
import Providers from '../../components/appt/providers'
import Confirm from '../../components/appt/confirm'
import Confirmation from '../../components/appt/confirmation'
import { API } from 'aws-amplify'
import { Glyphicon } from 'react-bootstrap'
import './createAppt.css'
import moment from 'moment'

class CreateAppt extends Component {
    constructor(props) {
        super(props)

        this.state = {
            step: 0,
            steps: [
                "type",
                "providers",
                "confirm",
                "confirmation"
            ],
            ct: null,
            careType: null,
            slot: null,
            providers: [],
            patient: null,
            isLoading: false,
            isConfirming: false
        }
        this.handleCareTypeSelected = this.handleCareTypeSelected.bind(this)
        this.handleSlotSelected = this.handleSlotSelected.bind(this)
        this.handleConfirm = this.handleConfirm.bind(this)
        this.getHealowSlots = this.getHealowSlots.bind(this)
    }

    async componentDidMount() {
        try {
            this.setState({ isLoading: true })
            let providers = await API.get('gwhpp', `/providers`)
            providers.forEach(p => p.slots = p.slots.map(s => { return { start: s } }))
            this.setState({ providers })
            const addr = providers.map(a => a.address)
            /* API.get('gwhpp', `/resource?res=schedule&senderOrg=cerner&action=practitioners&touchstone=${this.props.touchstone}`)
                .then(resp => {
                    this.translateCerner(resp.data, addr)
                })
                .catch(e => {
                    console.log(e.message)
                    this.setState({ isLoading: false })
                })
             */
            /* API.post('gwhpp', `/athena?action=getLocations`, { body: { limit: 15, offset: 1 } })
                .then(resp => {
                    this.translateAthena(resp.departments ? resp.departments : [])
                })
                .catch(e => {
                    console.log(e.message)
                    this.setState({ isLoading: false })
                }) */
                API.get('gwhpp', `/resource?res=schedule&senderOrg=healow&action=practitioners&touchstone=${this.props.touchstone}`)
                .then(resp => {
                    this.translateHealow(resp.data, addr)
                })
                .catch(e => {
                    console.log(e.message)
                    this.setState({ isLoading: false })
                })
        } catch (e) {
            console.log(e.message)
            this.setState({ isLoading: false })
        }
    }

    translateAthena(locs) {
        API.post('gwhpp', '/athena?action=getProviders')
            .then(pvdrs => {
                locs = locs.filter(dept => dept.latitude && dept.longitude && dept.providerlist && dept.providerlist.length > 0)
                let departmentId = []
                let providerId = []
                let providers = []
                locs.forEach((l, lidx) => {
                    departmentId.push(parseInt(l.departmentid))
                    API.get('gwhpp', `/geo?coord=${l.latitude},${l.longitude}`)
                        .then(addr => {
                            l.providerlist.slice(0, 4).forEach((p, pidx) => {
                                providerId.push(parseInt(p))
                                let pvdrsidx = pvdrs.providers.findIndex(px => px.providerid === parseInt(p))
                                if (pvdrsidx > -1) providers.push({ id: parseInt(p), provider: { name: pvdrs.providers[pvdrsidx].displayname || `Dr. ${pvdrs.providers[pvdrsidx].lastname}` || 'Unknown', phone: l.phone || null, npi: pvdrs.providers[pvdrsidx].npi || null }, address: addr && addr.data && addr.data.results[0] ? addr.data.results[0].formatted_address : 'Unavailable', facility: 'All', network: true, slots: [], source: 'athena', departmentid: parseInt(l.departmentid), departmentName: l.patientdepartmentname })
                                if (lidx === locs.length - 1 && pidx === l.providerlist.length - 1) {
                                    console.log('Time to get slots...department IDs and ProviderIds -->   ', departmentId, providerId)
                                    API.post('gwhpp', '/athena?action=getSlots', { body: { departmentId, providerId, reasonId: [1281] } })
                                        .then(resp => {
                                            resp.appointments.forEach(appt => {
                                                let tmpPidx = providers.findIndex(p => p.departmentid === appt.departmentid && p.id === appt.providerid)
                                                if (tmpPidx > -1) {
                                                    providers[tmpPidx].slots.push({
                                                        id: appt.appointmentid,
                                                        start: `${appt.date} ${appt.starttime}`,
                                                        org: 'athena'
                                                    })
                                                }
                                            })
                                            this.setState({ providers: [...this.state.providers, ...providers], isLoading: false })
                                        })
                                        .catch(e => {
                                            console.log(e)
                                        })
                                }
                            })
                        })
                        .catch(e => {
                            console.log(e)
                        })
                })
            })
    }

    translateHealow(fhir, addr) {
        if (fhir && fhir[0]) {
            let providers = fhir.map(p => {
                return (
                    {
                        id: p.actor || null,
                        provider: p.provider || null,
                        practice: p.code || null,
                        address: addr[this.getRan(0, addr.length - 1)],
                        facility: 'All',
                        network: true,
                        slots: [],
                        source: 'healow',
                        location: p.location
                    }
                )
            })
            this.setState({ providers: [...this.state.providers, ...providers] })
            this.getHealowSlots(moment().format('YYYY-MM-DD'))
        }
    }

    translateCerner(fhir, addr) {
        if (fhir && fhir.entry && fhir.entry[0]) {
            let providers = fhir.entry.filter(e => e.resource && e.resource.resourceType && e.resource.resourceType === "Practitioner").map(p => {
                return (
                    {
                        id: p.resource.id || null,
                        provider: {
                            name: p.resource.name && this.getNameFromResource(p.resource.name),
                            phone: p.resource.telecom && this.getCommFromResource(p.resource.telecom),
                            npi: p.resource.identifier && p.resource.identifier[0] && p.resource.identifier.findIndex(i => i.type && i.type.coding && i.type.coding.findIndex(c => c.code && c.code === "NPI") >= 0) >= 0 ? p.resource.identifier[p.resource.identifier.findIndex(i => i.type && i.type.coding && i.type.coding.findIndex(c => c.code && c.code === "NPI") >= 0)].value : null
                        },
                        address: addr[this.getRan(0, addr.length - 1)],
                        facility: 'All',
                        network: true,
                        slots: [],
                        source: 'cerner'
                    }
                )
            })
            providers.forEach(async (p, pidx) => {
                try {
                    const slots = await API.get('gwhpp', `/resource?res=schedule&senderOrg=cerner&action=slots&id=${p.id}`)
                    p.slots = slots.data.entry && slots.data.entry[0] ? slots.data.entry.filter(e => e.resource && e.resource.resourceType && e.resource.resourceType === 'Slot' && e.resource.freeBusyType && e.resource.freeBusyType === 'free').map(s => {
                        return ({
                            id: s.resource.id || null,
                            start: s.resource.start || null,
                            org: 'cerner'
                        })
                    }) : []
                    if (pidx === providers.length - 1) {
                        this.setState({ providers: [...this.state.providers, ...providers], isLoading: false })
                    }
                } catch (e) {
                    console.log(e.message)
                    this.setState({ isLoading: false })
                }
            })
        }
    }

    getHealowSlots(date) {
        try {
            this.setState({ isLoading: true })
            let providers = [...this.state.providers].filter(p => p.source && p.source === 'healow')
            providers.forEach((p, pidx) => {
                API.get('gwhpp', `/resource?res=schedule&senderOrg=healow&action=schedule&date=${date}&id=${p.id}&identifier=${p.location}`)
                    .then(sched => {
                        this.setState({ isLoading: true })
                        if (sched.data && sched.data.entry && sched.data.entry[0] && sched.data.entry[0].resource && sched.data.entry[0].resource.id) {
                            API.get('gwhpp', `/resource?res=schedule&senderOrg=healow&action=slots&date=${date}&id=${p.id}&identifier=${sched.data.entry[0].resource.id}`)
                                .then(slots => {
                                    p.slots = slots.data.entry && slots.data.entry[0] ? slots.data.entry.filter(e => e.resource && e.resource.resourceType && e.resource.resourceType === 'Slot' && e.resource.freeBusyType && e.resource.freeBusyType === 'free').map(s => {
                                        return ({
                                            id: s.resource.id || null,
                                            start: s.resource.start || null,
                                            end: s.resource.end || null,
                                            duration: moment(s.resource.end).diff(moment(s.resource.start), 'minutes'),
                                            org: 'healow'
                                        })
                                    }) : []
                                    let newProviders = [...this.state.providers]
                                    newProviders[newProviders.findIndex(pvdr => p.id === pvdr.id)].slots = p.slots
                                    this.setState({ providers: newProviders })
                                    if (pidx === providers.length - 1) this.setState({ isLoading: false })
                                })
                                .catch(e => {
                                    console.log(e.message)
                                })
                        }
                    })
                    .catch(err => {
                        console.log(err.message)
                        this.setState({ isLoading: false })
                    })
            })
        } catch (e) {
            console.log(e.message)
            this.setState({ isLoading: false })
        }
    }

    getNameFromResource(name) {
        return name ? (name.text ? name.text : `${name.prefix && name.prefix.toString().replace(',', ', ')}  ${name.given && name.given.toString().replace(',', ' ')} ${name.family || ''}`) : 'Undefined'
    }

    getCommFromResource(comm) {
        return (
            comm && comm[0] ? comm.map((t, tid) => {
                return `${t.use + ' ' || ''}${t.system + ' ' || ''}${t.value || 'Not Listed'}`
            }).toString().replace(/,/g, ' ⚬ ') : 'Not Listed')
    }

    getRan(min, max) {
        return Math.round((Math.random() * (max - min))) + min
    }

    handleStepChange(step) {
        if (step < this.state.step) this.setState({ step })
    }

    async handleCareTypeSelected(careType) {
        let ct = 'All'
        switch (careType) {
            case 'Primary Care':
                ct = 'Family Medicine'
                break
            case 'Specialty Care':
                ct = 'Cardiologist'
                break
            default:
                break
        }
        this.setState({ careType: ct, ct: careType })
        setTimeout(() => {
            this.setState({ step: this.state.step + 1 })
        }, 250)
    }

    async handleSlotSelected(slot, provider) {
        this.setState({ slot, provider })
        try {
            setTimeout(() => {
                this.setState({ step: this.state.step + 1 })
            }, 250)
            const patient = await API.get('gwhpp', `/resource?res=schedule&senderOrg=${slot.org}&action=patient&id=${this.props.user && this.props.user[0] && this.props.user[0].mbrId ? this.props.user[0].mbrId : '7328007'}&touchstone=${this.props.touchstone}`)
            if (patient && patient.data && patient.data.entry && patient.data.entry[0] && patient.data.entry.length === 1 && patient.data.entry[0].resource) {
                this.setState({ patient: patient.data.entry[0].resource })
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    async handleConfirm() {
        if (this.state.slot) {
            this.setState({ isConfirming: true })
            switch (this.state.slot.org) {
                case "cerner":
                    try {
                        const resp = await API.get('gwhpp', `/resource?res=schedule&senderOrg=${this.state.slot.org}&action=book&identifier=${this.state.slot.id}&id=${this.state.patient.id}&touchstone=${this.props.touchstone}`)
                        if (resp.data && resp.data === 'success') {
                            setTimeout(() => {
                                this.setState({ step: this.state.step + 1 })
                            }, 250)
                        } else {
                            console.log('Something went wrong:    ', resp)
                        }
                    } catch (e) {
                        console.log(e.message)
                    }
                    break
                case "healow":
                    try {
                        const resp = await API.get('gwhpp', `/resource?res=schedule&senderOrg=${this.state.slot.org}&action=book&identifier=${this.state.slot.id}&id=${this.state.provider.id}&touchstone=${this.props.touchstone}`)
                        if (resp.status && resp.status === 'success') {
                            API.post('gwhpp', `/appt?touchstone=${this.props.touchstone}`, { body: { mbrId: this.state.mbrId || '214', slot: this.state.slot, provider: { ...this.state.provider, slots: [] } } })
                            setTimeout(() => {
                                this.setState({ step: this.state.step + 1 })
                            }, 250)
                        } else {
                            console.log('Something went wrong:    ', resp)
                        }
                    } catch (e) {
                        console.log(e.message)
                    }
                    break
                default:
                    break
            }
            //this.setState({ slot: null, careType: null, ct: null, patient: null, provider: null })
        }
    }

    getStep() {
        switch (this.state.step) {
            case 0:
                return <CareType onTypeSelected={this.handleCareTypeSelected} />
            case 1:
                return <Providers onSlotSelected={this.handleSlotSelected} providers={this.state.providers.filter(p => p.facility === 'All' || p.facility === this.state.careType)} onDateChanged={this.getHealowSlots} />
            case 2:
                return <Confirm slot={this.state.slot} provider={this.state.provider} careType={this.state.ct} onConfirm={this.handleConfirm} confirming={this.state.isConfirming} />
            case 3:
                return <Confirmation slot={this.state.slot} provider={this.state.provider} careType={this.state.ct} />
            default:
                return <CareType onTypeSelected={this.handleCareTypeSelected} />

        }
    }

    getStepIndicator() {
        return (
            <div className="Appointment-Indicator-Container">
                {
                    this.state.steps.slice(0, this.state.steps.length - 1).map((step, sidx) => {
                        return (
                            <div key={`step-${sidx}`} className={`Appointment-Indicator${this.state.step === sidx ? ' Appointment-Indicator-Current' : ''}`} onClick={() => this.handleStepChange(sidx)}></div>
                        )
                    })
                }
                {
                    this.state.isLoading && <Glyphicon glyph="refresh" className="Providers-Loading" />
                }
            </div>
        )
    }

    render() {
        console.log(this.state, this.props)
        return (
            <div className='Appointment-Container'>
                {this.getStep()}
                {this.state.step < this.state.steps.length - 1 ? this.getStepIndicator() : null}
            </div>
        )
    }
}

export default CreateAppt