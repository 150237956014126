import React, { Component } from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'
import './care.css'
import '../mrp.css'

class Auth extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            search: null,
            auth: null
        }
    }

    handleSearchTextChange(text) {
        this.setState({
            search: text.length > 0 ? text : null,
            auth: null,
            isLoading: false
        })
    }

    handleSearch() {
        if (this.state.search) {
            this.setState({ isLoading: true })
            API.get('gwhpp', `/resource?res=bundle&identifier=${this.state.search}`)
                .then(resp => {
                    if (resp && resp.data) this.setState({ auth: resp.data && resp.data.entry && resp.data.entry[0] ? resp.data.entry : null })
                    this.setState({ isLoading: false })
                })
                .catch(err => {
                    console.log(err.message)
                    this.setState({ isLoading: false })
                })
        }
    }


    getNamefromResource(name) {
        return name && name[0] ? (name[0].text ? name[0].text : `${name[0].given.toString().replace(',', ' ')} ${name[0].family}`) : 'Undefined'
    }

    getSearchResults() {
        return (
            this.state.auth && this.state.auth[0] ?
                this.state.auth.map((a, aidx) => {
                    return (
                        <div key={`auth-${aidx}`} className="MRP-Result">
                            <div className="MRP-Result-Title">
                                {a.resource && a.resource.identifier && a.resource.identifier.value ? `Authorization ${a.resource.identifier.value}` : null}
                                <div className="MRP-Result-Date">
                                    {a.resource && a.resource.meta && a.resource.meta.lastUpdated ? moment(a.resource.meta.lastUpdated).format('lll') : null}
                                </div>
                            </div>
                            <div className="MRP-Result-Row">
                                {
                                    a.resource && a.resource.entry && a.resource.entry[0] ?
                                        a.resource.entry.map((res, ridx) => {
                                            return this.getFormattedResource(res.resource, aidx, ridx)
                                        }) : null
                                }
                            </div>
                        </div>
                    )
                }) : null
        )
    }

    getFormattedResource(res, pidx, rid) {
        if (res) {
            console.log(res)
            switch (res.resourceType) {
                case 'Claim':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-notes-medical"></i> Claim</div>
                            <div className="MRP-Result-Section-Label">ID</div>
                            <div className="MRP-Result-Section-Value">{res.id || 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status ? res.status.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Purpose</div>
                            <div className="MRP-Result-Section-Value">{res.use || 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Created</div>
                            <div className="MRP-Result-Section-Value">{res.created ? moment(res.created).format('lll') : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Type</div>
                            <div className="MRP-Result-Section-Value">{res.type && res.type.coding ? res.type.coding.map(code => code.display || code.code || []).toString().replace('/,/g, ', '') : 'Undefined'}</div>
                            </div>
                    )
                case 'Encounter':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-ambulance"></i> Encounter</div>
                            <div className="MRP-Result-Section-Label">ID</div>
                            <div className="MRP-Result-Section-Value">{res.id || 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status ? res.status.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Code</div>
                            <div className="MRP-Result-Section-Value">{res.class && res.class.display ? res.class.display.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Type</div>
                            <div className="MRP-Result-Section-Value">{res.type && res.type[0] && res.type[0].text ? res.type[0].text : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Patient</div>
                            <div className="MRP-Result-Section-Value">{res.subject && res.subject.display ? res.subject.display : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Date</div>
                            <div className="MRP-Result-Section-Value">{res.period && res.period.start ? moment(res.period.start).format('LLL') : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Reason</div>
                            <div className="MRP-Result-Section-Value">{res.reasonCode && res.reasonCode[0] && res.reasonCode[0].text ? res.reasonCode[0].text : 'Not Listed'}</div>
                            <div className="MRP-Result-Section-Label">Diagnosis</div>
                            <div className="MRP-Result-Section-Value">{res.diagnosis && res.diagnosis[0] && res.diagnosis[0].condition && res.diagnosis[0].condition.display ? res.diagnosis[0].condition.display : 'Not Listed'}</div>
                        </div>
                    )
                case 'Location':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-map-marker-alt"></i> Location</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status && res.status.toLowerCase() === 'active' ? <i className="far fa-check-circle"></i> : <i className="fas fa-exclamation-circle"></i>} {res.status ? res.status.toUpperCase() : 'Unavailable'}</div>
                            <div className="MRP-Result-Section-Label">Name</div>
                            <div className="MRP-Result-Section-Value">{res.name ? res.name : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Description</div>
                            <div className="MRP-Result-Section-Value">{res.description ? res.description : 'Undefined'}</div>
                            {
                                res.telecom && res.telecom.length > 0 && res.telecom.map((t, tid) => {
                                    return (
                                        <div key={`t-${rid}-${pidx}-${tid}`}>
                                            <div className="MRP-Result-Section-Label">{t.use && t.use === 'home' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {`${t.use ? t.use.toUpperCase() : 'Phone'}/${t.system ? t.system.toUpperCase() : 'Phone'}`}</div>
                                            <div className="MRP-Result-Section-Value">{t.value ? t.value : 'Undefined'}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className="MRP-Result-Section-Label"><i className="fas fa-map-marker-alt"></i> Address</div>
                            {
                                res.address && res.address && res.address.text ? res.address.text.split(", ").map((addr, aidx) => {
                                    return <div key={`patAddr-${rid}-${pidx}-${aidx}`} className="MRP-Result-Section-Value">{addr}</div>
                                }) : <div className="MRP-Result-Section-Value">Unlisted</div>
                            }
                        </div>
                    )
                case 'MeasureReport':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-clipboard-check"></i> Report</div>
                            <div className="MRP-Result-Section-Label">Type</div>
                            <div className="MRP-Result-Section-Value">{res.type ? res.type.toUpperCase() : 'Unavailable'}</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status && res.status.toLowerCase() === 'complete' ? <i className="far fa-check-circle"></i> : <i className="fas fa-exclamation-circle"></i>} {res.status ? res.status.toUpperCase() : 'Unavailable'}</div>
                        </div>
                    )
                case 'Organization':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="far fa-hosptial"></i> Organization</div>
                            <div className="MRP-Result-Section-Label">{res.identifier && res.identifier[0] && res.identifier[0].type && res.identifier[0].type.text ? res.identifier[0].type.text : 'Identifier'}</div>
                            <div className="MRP-Result-Section-Value">{res.identifier && res.identifier[0] && res.identifier[0].value ? res.identifier[0].value : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Active</div>
                            <div className="MRP-Result-Section-Value">{res.active ? 'Yes' : 'No'}</div>
                            <div className="MRP-Result-Section-Label">Name</div>
                            <div className="MRP-Result-Section-Value">{res.name ? res.name : 'Undefined'}</div>
                            {
                                res.telecom && res.telecom.length > 0 && res.telecom.map((t, tid) => {
                                    return (
                                        <div key={`t-${rid}-${pidx}-${tid}`}>
                                            <div className="MRP-Result-Section-Label">{t.use && t.use === 'home' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {`${t.use ? t.use.toUpperCase() : 'Phone'}/${t.system ? t.system.toUpperCase() : 'Phone'}`}</div>
                                            <div className="MRP-Result-Section-Value">{t.value ? t.value : 'Undefined'}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className="MRP-Result-Section-Label"><i className="fas fa-map-marker-alt"></i> Address</div>
                            {
                                res.address && res.address[0] && res.address[0].text ? res.address[0].text.split(", ").map((addr, aidx) => {
                                    return <div key={`patAddr-${rid}-${pidx}-${aidx}`} className="MRP-Result-Section-Value">{addr}</div>
                                }) : <div className="MRP-Result-Section-Value">Unlisted</div>
                            }
                        </div>
                    )
                case 'Patient':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-procedures"></i> Patient</div>
                            <div className="MRP-Result-Section-Label">ID</div>
                            <div className="MRP-Result-Section-Value">{res.id || 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.active ? 'ACTIVE' : 'INACTIVE'}</div>
                            <div className="MRP-Result-Section-Label">Name</div>
                            <div className="MRP-Result-Section-Value">{this.getNamefromResource(res.name)}</div>
                            {
                                res.telecom && res.telecom.length > 0 && res.telecom.map((t, tid) => {
                                    return (
                                        <div key={`t-${rid}-${pidx}-${tid}`}>
                                            <div className="MRP-Result-Section-Label">{t.use && t.use === 'home' ? <i className="fas fa-mobile-alt"></i> : <i className="fas fa-phone"></i>} {`${t.use ? t.use.toUpperCase() : 'Phone'}/${t.system ? t.system.toUpperCase() : 'Phone'}`}</div>
                                            <div className="MRP-Result-Section-Value">{t.value ? t.value : 'Undefined'}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className="MRP-Result-Section-Label"><i className="fas fa-female"></i><i className="fas fa-male"></i> Gender</div>
                            <div className="MRP-Result-Section-Value">{res.gender ? res.gender.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label"><i className="fas fa-birthday-cake"></i> D.O.B.</div>
                            <div className="MRP-Result-Section-Value">{res.birthDate || 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Deceased</div>
                            <div className="MRP-Result-Section-Value">{res.deceasedBoolean ? 'Yes' : 'No'}</div>
                            <div className="MRP-Result-Section-Label"><i className="fas fa-map-marker-alt"></i> Address</div>
                            {
                                res.address && res.address[0] && res.address[0].text ? res.address[0].text.split(", ").map((addr, aidx) => {
                                    return <div key={`patAddr-${rid}-${pidx}-${aidx}`} className="MRP-Result-Section-Value">{addr}</div>
                                }) : <div className="MRP-Result-Section-Value">Unlisted</div>
                            }
                            <div className="MRP-Result-Section-Label"><i className="fas fa-user-md"></i> Practitioner</div>
                            <div className="MRP-Result-Section-Value">{res.generalPractitioner && res.generalPractitioner[0] && res.generalPractitioner[0].display ? res.generalPractitioner[0].display : 'Undefined'}</div>
                        </div>

                    )
                case 'Practitioner':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-user-md"></i> Provider</div>
                            <div className="MRP-Result-Section-Label">NPI</div>
                            <div className="MRP-Result-Section-Value">{res.identifier && res.identifier[0] && res.identifier[0].value ? res.identifier[0].value : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Name</div>
                            <div className="MRP-Result-Section-Value">{res.name && res.name[0] && res.name[0].text ? res.name[0].text : 'Undefined'}</div>
                        </div>
                    )
                case 'QuestionnaireResponse':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-vial"></i> Questionnaire Responses</div>
                            <div className="MRP-Result-Section-Label">Responses</div>
                            {
                                res.item && res.item[0] ?
                                    res.item.map((i, iidx) => {
                                        return (
                                            <div key={`q-${pidx}-${rid}-${iidx}`} className="MRP-Result-Section-Value">{i.text || null}</div>
                                        )
                                    }) : null
                            }
                        </div>
                    )
                case 'ServiceRequest':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-user-md"></i> Request</div>
                            <div className="MRP-Result-Section-Label">ID</div>
                            <div className="MRP-Result-Section-Value">{res.id || 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status || 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Intent</div>
                            <div className="MRP-Result-Section-Value">{res.intent || 'Undefined'}</div>
                        </div>
                    )
                case 'Task':
                    return (
                        <div key={`res-${rid}-${pidx}`} className="MRP-Result-Section">
                            <div className="MRP-Result-Section-Title"><i className="fas fa-clipboard-list"></i> Task</div>
                            <div className="MRP-Result-Section-Label">Status</div>
                            <div className="MRP-Result-Section-Value">{res.status && res.status.toLowerCase() === 'accepted' ? <i className="far fa-check-circle"></i> : <i className="fas fa-exclamation-circle"></i>} {res.status ? res.status.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Intent</div>
                            <div className="MRP-Result-Section-Value">{res.intent ? res.intent.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Priority</div>
                            <div className="MRP-Result-Section-Value">{res.priority ? res.priority.toUpperCase() : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">CPT Reason</div>
                            <div className="MRP-Result-Section-Value">{res.code && res.code.text ? res.code.text : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Authored On</div>
                            <div className="MRP-Result-Section-Value">{res.authoredOn ? moment(res.authoredOn).format('LLL') : 'Undefined'}</div>
                            <div className="MRP-Result-Section-Label">Start-End</div>
                            <div className="MRP-Result-Section-Value">{`${res.executionPeriod && res.executionPeriod.start ? moment(res.executionPeriod.start).format('L') : 'Undefined'}-${res.executionPeriod && res.executionPeriod.end ? moment(res.executionPeriod.end).format('L') : 'Undefined'}`}</div>
                        </div>
                    )
                default:
                    return false
            }
        }
        return null
    }

    render() {
        console.log(this.state)
        return (
            <div>
                <div className="Care-Row">
                    <div className="Care-Search-Container">
                        <div className="Care-Row Care-Search-Label">Authorization Search{this.state.isLoading && <i className="fas fa-spinner"></i>}</div>
                        <div className="Care-Row Care-Search-Outter">
                            <input type="text" value={this.state.search || ''} onChange={event => this.handleSearchTextChange(event.target.value)} className="Care-Search-Bar" placeholder={`Search by Authorization Number`}></input>
                            <div className="Care-Search-Button" onClick={() => this.handleSearch()}><i className="fas fa-search"></i></div>
                        </div>
                    </div>
                </div>
                {this.getSearchResults()}
            </div>
        )
    }
}

export default Auth