import React, { Component } from 'react'
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap'
import LoaderButton from '../components/loaderButton'
import { API } from 'aws-amplify'
import './mrp.css'

class CRD extends Component {
    constructor(props) {
        super(props)

        this.state = {
            crd: "",
            scrd: "",
            isLoading: false,
            results: null,
            lookup: [
                { text: "Oxygen concentrator", code: "E0424" },
                { text: "Portable gaseous oxygen system", code: "E0431" },
                { text: "Portable liquid oxygen system", code: "E0433" },
                { text: "Stationary compressed gaseous oxygen system", code: "E0439" },
                { text: "Stationary liquid oxygen system", code: "E0441" },
                { text: "Stationary oxygen contents", code: "E1390" }
            ],
            showSearch: false
        }
    }

    componentWillMount() {
        if (!this.props.user || !this.props.user[0] || this.props.user[0].restrict) this.props.history.push('/')
    }

    handleSubmit = async event => {
        event.preventDefault()
        this.setState({ isLoading: true, results: null })
        if(this.state.crd.length > 0) {
            try {
                let results = await API.get('crd', `/crd/${this.state.crd.toUpperCase()}`)
                console.log('results is    ', results)
                if(results.value && results.value === 'Not Found') results = null
                this.setState({ results, isLoading: false })
            } catch (e) {
                alert(e)
                this.setState({ isLoading: false })
            }
        } else {
            this.setState({ isLoading: false })
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }

    handleSearchSelect(crd) {
        this.setState({ crd, scrd: "", showSearch: false })
    }

    handleShowSearch() {
        this.setState({ showSearch: !this.state.showSearch })
    }

    getSearch() {
        return (
            <div className="Search-Container">
                <div className="CRD-Title">Search for Code</div>
                <FormControl
                    id="scrd"
                    placeholder="Enter search criteria"
                    value={this.state.scrd}
                    onChange={this.handleChange}
                    type="text"
                />
                <div className="CRD-Results-Container">
                    {
                        this.state.scrd.length > 0 ? 
                        this.state.lookup.filter(text => {
                            let arr = text.text.toUpperCase().split(" ")
                            let found = false
                            arr.forEach(a => {if(a.startsWith(this.state.scrd.toUpperCase().split(" ")[0]) && text.text.toUpperCase().indexOf(this.state.scrd.toUpperCase()) >= 0) found = true})
                            return found
                        }).map((res, idx) => {
                            return (
                                <div key={`res-${idx}`} className="Search-Row" onClick={() => this.handleSearchSelect(res.code)}>
                                    {res.text} - {res.code}
                                </div>
                            )
                        }) : <div className="Search-Row">No Results</div>
                    }
                </div>
                <div className="Search-Button-Container">
                    <div className="Search-Button" onClick={() => this.handleShowSearch() }>
                        Cancel
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            this.props.user && this.props.user[0] ?
                <div className="MRP-Container">
                    { this.state.showSearch && this.getSearch() }
                    <div className="CRD-Title">
                        Medical Coverage Guidelines
                        <i className="fas fa-search" onClick={() => this.handleShowSearch() }></i>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <FormGroup controlId="crd" bsSize="large">
                            <ControlLabel>Condition Code</ControlLabel>
                            <FormControl
                                placeholder="Enter a Condition Code to Search"
                                value={this.state.crd}
                                onChange={this.handleChange}
                                type="text"
                            />
                        </FormGroup>
                        <LoaderButton
                            block
                            bsSize="large"
                            disabled={this.state.isLoading}
                            type="submit"
                            isLoading={this.state.isLoading}
                            text="Search"
                            loadingText="Searching..."
                        />
                    </form>
                    { this.state.results ? 
                        <div className="CRD-Results-Container">
                            <div className="CRD-Row">
                                { this.state.results.crd }
                            </div>
                            <div className="CRD-Row">
                                <div className="CRD-Key">
                                    Description
                                </div>
                                <div className="CRD-Value">
                                    { this.state.results.description }
                                </div>
                            </div>
                            <div className="CRD-Row">
                                <div className="CRD-Key">
                                    Requirements
                                </div>
                                <div className="CRD-Value">
                                    <ul>
                                        { this.state.results.requirements.map((req, idx) => {
                                            return (
                                                <li key={`req-${idx}`}>{ req }</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="CRD-Row">
                                <div className="CRD-Key">
                                    Costs
                                </div>
                                <div className="CRD-Value">
                                    {
                                        this.state.results.costs.map((c, idx) => {
                                            return (
                                               <div key={`cost-${idx}`} className="CRD-Row"> 
                                                    <div className="CRD-Key">
                                                        { c.cost }
                                                    </div>
                                                    <div className="CRD-Value">
                                                        { c.amount } { c.timing }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="CRD-Row">
                                <a target="_blank" rel="noopener noreferrer" href={ this.state.results.url }>View MCG Document for { this.state.results.crd }</a>
                            </div>
                        </div> : 
                    null }
                </div> : null
        )
    }
}

export default CRD
