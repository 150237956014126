import React, { Component } from 'react'
import { API } from 'aws-amplify'
import uuid from 'uuid'
import qs from 'query-string'
import '../components/appt.css'
import Axios from 'axios';

const url = "https://sandbox.bluebutton.cms.gov/v1/o/authorize/"
const client_id = '2GbGvMCdIsNRzBkFcdsnLKnC73nLpvn0FzusiIBh'
const redirect_uri = "http://fbpp-app-client.s3-website.us-east-2.amazonaws.com/mycms"
const response_type = "code"
const https = require('https')


class MyCMS extends Component {
    constructor(props) {
        super(props)

        this.state = {
            code: null,
            token: null,
            state: null,
            patient: null,
        }
    }

    async componentWillMount() {
        if (!this.props.user || !this.props.user[0] || this.props.user[0].restrict) this.props.history.push('/')
        if (!this.props.cms || !this.props.cms.token) {
            if (qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code) {
                const code = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code
                this.props.setCMS({ cms: { code } })
                const resp = await API.get('ecw', `/cmstoken?code=${code}`)
                if (resp.status === 200) {
                    if (resp.data) {
                        const patient = resp.data.patient
                        const token = resp.data.access_token
                        this.props.setCMS({ ...this.props.cms, token, patient })
                        const eob = await Axios.get(`https://sandbox.bluebutton.cms.gov/v1/fhir/ExplanationOfBenefit/?patient=${patient}`, {
                            headers: {
                                'Authorization': "Bearer " + token,
                                'Content-Type': 'application/json+fhir'
                            },
                            httpsAgent: new https.Agent(
                                {
                                    rejectUnauthorized: false
                                }
                            )
                        })
                        this.props.setCMS({ ...this.props.cms, eob })
                    }
                } else {
                    const state = uuid.v1()
                    this.setState({ state })
                    window.location = `${url}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&state=${state}`
                }
            } else {
                const state = uuid.v1()
                this.setState({ state })
                window.location = `${url}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&state=${state}`
            }
        }
    }

    handleGetCMS() {
        const state = uuid.v1()
        this.setState({ state })
        window.location = `${url}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&state=${state}`
    }

    getEOBs() {
        const cellStyle = { padding: "5px", textAlign: "center" }
        return (
            this.props.cms && this.props.cms.eob ?
                this.props.cms.eob.data.entry.map((eob, idx) => {
                    return (
                        <tr key={`eob-${idx}`}>
                            <td style={cellStyle}>{ eob.resource.identifier[0].value }</td>
                            <td style={cellStyle}>{ eob.resource.careTeam[0].role.coding[0].display }</td>
                            <td>{ eob.resource.billablePeriod ? `${eob.resource.billablePeriod.start} - ${eob.resource.billablePeriod.end}` :  eob.resource.item[0].servicedDate }</td>
                            <td style={cellStyle}>{ eob.resource.item[0].adjudication.filter(x => x.amount && parseInt(x.amount.value) !== 0).map((a, aidx) => {
                                return (
                                    <div style={{ textAlign: "left", borderBottom: "1px solid #000"}} key={`amount-${aidx}`}>{ `$${a.amount.value}: ${a.category.coding[0].display}` }</div>
                                )}) }
                            </td>
                            <td style={cellStyle}>{ eob.resource.item[0].adjudication.filter(z => z.reason).map((r, ridx) => {
                                return (
                                    <div key={`reason-${ridx}`}>{ r.reason.coding[0].display }</div>
                                )}) }
                            </td>
                        </tr>
                    )
                }) :
                null
        )
    }

    render() {
        console.log('my cms    ', this.props, this.state)
        const cellStyle = { padding: "5px", textAlign: "center" }
        return (
            <div style={{ width: '100%' }}>
                <div className="BlueButton-Container">
                    <div className="BlueButton" onClick={() => this.handleGetCMS()}>
                        Blue Button
                    </div>
                </div>
                <div className="Confirm-Appt-Container" style={{ maxHeight: "none"}}>
                    <div className="CMS-Table-Container">
                        <table border="1">
                            <thead>
                                <tr>
                                    <td style={cellStyle}>Claim #</td>
                                    <td style={cellStyle}>Provider</td>
                                    <td style={cellStyle}>Date of Service</td>
                                    <td style={cellStyle}>Charges</td>
                                    <td style={cellStyle}>Explanation</td>
                                </tr>
                            </thead>
                            <tbody>
                                { this.getEOBs() }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        )
    }
}

export default MyCMS
