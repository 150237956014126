import React from "react"
import { Route, Switch } from "react-router-dom"
import AppliedRoute from './components/appliedRoute'
import Home from "./containers/home"
import NotFound from './containers/notFound'
import Login from './containers/login'
import Signup from './containers/signup'
import CreateAppt from './containers/davinci/createAppt'
//import Appointments from './containers/appointments'
import Appointments from './containers/davinci/appts'
//import AboutMe from './containers/aboutMe'
import Athena from './containers/athena/athena'
import AboutMe from './containers/davinci/me'
import SearchMRP from './containers/mrp'
import CRD from './containers/crd'
import MyCMS from './containers/myCMS'
import MyECW from './containers/myEcw'
import EDI from './containers/edi'
import Davinci from './containers/davinci/start'
import Care from './containers/davinci/care'
import Rx from './containers/davinci/rx'
import Auth from './containers/davinci/auth'

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={ Home } props={ childProps } />
    <AppliedRoute path="/login" exact component={ Login } props={ childProps } />
    <AppliedRoute path="/signup" exact component={ Signup } props={ childProps } />
    <AppliedRoute path="/appointments" exact component={ Appointments } props={ childProps } />
    <AppliedRoute path="/appointment" exact component={ CreateAppt } props={ childProps } />
    <AppliedRoute path="/aboutme" exact component={ AboutMe } props={ childProps } />
    <AppliedRoute path="/crd" exact component={ CRD } props={ childProps } />
    <AppliedRoute path="/searchmrp" exact component={ SearchMRP } />
    <AppliedRoute path="/mycms" exact component={ MyCMS } props={ childProps } />
    <AppliedRoute path="/myecw" exact component={ MyECW } props={ childProps } />
    <AppliedRoute path="/edi" exact component={ EDI } props={ childProps } />
    <AppliedRoute path="/davinci" exact component={ Davinci } props={ childProps } />
    <AppliedRoute path="/members" exact component={ Care } props={ childProps } />
    <AppliedRoute path="/rx" exact component={ Rx } props={ childProps } />
    <AppliedRoute path="/auth" exact component={ Auth } props={ childProps } />
    <AppliedRoute path="/opd" exact component={ Athena } props={ childProps } />
    <Route component={ NotFound } />
  </Switch>